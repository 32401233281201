<template>
  <div>
    <ModalSubmissionForm
      ref="adsAddThreshold"
      elementType="ADS Threshold"
      :fields1="formFields"
      :createFunc="createAdsThreshold"
      :formDataChangeFunc="formDataChanged"
      :data="defaultOptions()"
      :isCreate="true"
      :helpCopy="helpCopy"
      :submitDisabled="!isCreator"
    />
    <ModalSubmissionForm
      ref="adsEditThreshold"
      elementType="ADS Threshold"
      :fields1="formFields"
      :updateFunc="updateAdsThreshold"
      :formDataChangeFunc="formDataChanged"
      :data="thresholdData"
      :isCreate="false"
      :helpCopy="helpCopy"
      :closer="closeEditor"
      :deleteFunc="deleteAdsThreshold"
      :submitDisabled="!isEditor"
    />
    <ModalADSAlertInfo ref="adsShowAlert" :closer="closeAlertViewer" :alert="alertData"  /> 
    <ModalADSAlertThreshInfo
      ref="adsShowAlertTresholds"
      :ids="thresholdIds"
      :showThresholdFunc="showEditorFromID"
    />
    <DataTable
      class="dt-recent-thresholds"
      :title="tableTitle"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :noDataMessage="'No ADS ' + capSubject + ' Found'"
      actionIcon="plus"
      :actionFunc="isCreator ? showCreator : undefined"
      itemType="ADS Threshold"
      :initialSort="3"
    >
      <template v-slot:filter-pillbox>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center me-3" v-if="activePill === 'alerts'">
            <input type="checkbox" id="showOrHideResolved" class="general-input me-1" @change="showResolvedCheckboxClicked" checked />
            <label class="general-input-label font-reduce-1" for="showOrHideResolved">Show Resolved Alerts</label>
          </div>
          <Pillbox :pills="pills" class="me-2" :defaultPill="activePill" :activePillChanged="activePillChanged" />
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import auth from '@/app/auth'
import BaseComponent from '@/components/BaseComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import ModalSubmissionForm from '@/components/forms/ModalSubmissionForm.vue'
import ModalADSAlertInfo from '@/components/notifications/ModalADSAlertInfo.vue'
import ModalADSAlertThreshInfo from '@/components/notifications/ModalADSAlertThreshInfo.vue'
import Pillbox from '../controls/Pillbox.vue'
import { NodeRoles } from '@/app/data/noderoles.ts'
import { DevicePositions } from '@/app/data/positions.ts'
import { MetricsGroundTruthOptions } from '@/app/data/metricsGroundTruth.ts'
import { HelpADSThreshold } from '@/components/notifications/helpCopy.ts'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'ads-main-list',
  extends: BaseComponent,
  data () {
    return {
      thresholds: [],
      alerts: [],
      formData: this.defaultOptions(),
      activeSection: '',
      thresholdData: null,
      alertData: null,
      thresholdId: '',
      thresholdIds: '',
      alertId: '',
      activePill: this.getDefaultPill(),
      showResolved: true
    }
  },
  watch: {
    thresholds () {
      if (!this.thresholdId || !this.thresholds || this.thresholds.length === 0) {
        return
      }
      for (const thresh of this.thresholds) {
        if (thresh.id === this.thresholdId) {
          this.showEditor(thresh)
          break
        }
      }
    },
    thresholdId () {
      if (!this.thresholdId || !this.thresholds || this.thresholds.length === 0) {
        return
      }
      for (const thresh of this.thresholds) {
        if (thresh.id === this.thresholdId) {
          this.showEditor(thresh)
          break
        }
      }
    },
    alerts () {
      if (!this.alertId || !this.alerts || this.alerts.length === 0) {
        return
      }
      for (const alert of this.alerts) {
        if (alert.id === this.alertId) {
          this.showADSAlert(alert)
          break
        }
      }
    },
    alertId () {
      if (!this.alertId || !this.alerts || this.alerts.length === 0) {
        return
      }
      for (const alert of this.alerts) {
        if (alert.id === this.alerts) {
          this.showADSAlert(alert)
          break
        }
      }
    }
  },
  computed: {
    isEditor () {
      return auth.userHasPrivilege('notifications', 'update', '*') ||
      auth.userHasPrivilege('notifications', 'edit', '*') 
    },
    isCreator () {
      return auth.userHasPrivilege('notifications', 'create', '*') ||
      auth.userHasPrivilege('notifications', 'edit', '*') 
    },
    tableTitle () {
      if (this.activeSection === 'thresholds') {
        if (!this.rows || this.rows.length === 0) {
          return 'ADS Thresholds'
        }
        return `ADS Thresholds (${this.rows.length})`
      }
      if (!this.rows || this.rows.length === 0) {
        return 'ADS Alerts'
      }
      return  `ADS Alerts (${this.rows.length})`
    },
    capSubject () {
      if (this.activeSection === 'alerts') {
        return 'Alerts'
      }
      return 'Thresholds'
    },
    cols () { 
      if (this.activeSection === 'alerts') {
        return [
          { text: 'Alert ID', sort: 0 },
          { text: 'Device', sort: 1 },
          { text: 'Threshold ID', sort: 2 },
          { text: 'Status', sort: 3 },
          { icon: 'clock', text: 'Start', sort: 4 },
          { icon: 'clock', text: 'End', sort: 5 }
        ]
      }
      return [
        { text: 'Threshold ID', sort: 0 },
        { text: 'Source', sort: 1 },
        { text: 'Metric', sort: 2 },
        { text: 'Type', sort: 3 },
        { text: 'Val/Var', sort: 4 },
        { text: 'NodeRole', sort: 7 },
        { text: 'DevType', sort: 5 },
        { text: 'DevModel', sort: 6 },
        { text: 'Position', sort: 8 },
      ]
    },
    rows () {
      const out = []
      if (this.activeSection === 'alerts') {
        for (const alert of this.alerts) {
          if (!this.showResolved && alert.status === "resolved") {
            continue
          }
          out.push([
            {
              value: this.getLastWord(alert.id),
              click: this.showADSAlert, i: alert,
              class: { inactive: alert.status === 'resolved' }
            },
            {
              value: alert.deviceEui,
              route: 'device-details',
              class: { inactive: alert.status === 'resolved' },
              params: { id: alert.deviceEui }
            },
            this.getAlertTableThreshCell(alert),
            { 
              value: alert.status,
              class: { inactive: alert.status === 'resolved', warning: alert.status === 'active' }            },
            { value: this.getSinceSecs(alert.startTime), class: { inactive: alert.status === 'resolved' } },
            { value: this.getSinceSecs(alert.endTime), class: { inactive: alert.status === 'resolved' } }
          ])
        }
        return out
      } else if (this.thresholds && this.thresholds.length > 0) {
        for (const threshold of this.thresholds) {
          out.push([
            { value: this.getLastWord(threshold.id), click: this.showEditor, i: threshold },
            { value: threshold.source },
            { value: threshold.metric },
            { value: threshold.referenceType },
            { value: threshold.referenceType === 'trend' ? threshold.variancePercent : threshold.refValue },
            { value: threshold.nodeRoleFilter },
            { value: threshold.deviceTypeFilter },
            { value: threshold.deviceModelFilter },
            { value: threshold.devicePositionFilter }
          ])
        }
      }
      return out
    },
    pills () {
      return [
        {label: 'Alerts'},
        {label: 'Thresholds'}
      ]
    },
    helpCopy () {
      return HelpADSThreshold
    },
    groundTruthSelectOptions () {
      const out = [
        {
          id: "none",
          displayName: "-- Select Metric",
          value: ""
        }
      ]
    },
    nodeRoleOptions () {
      const out = []
      for (const k in NodeRoles) {
        out.push({
          id: NodeRoles[k],
          value: NodeRoles[k],
          displayName: k
        })
      }
      return out
    },
    positionOptions () {
      const out = []
      for (const k in DevicePositions) {
        out.push({
          id: k,
          value: k,
          displayName: k
        })
      }
      return out
    },
    formFields () {
      const notValue = this.formData.referenceType !== 'value'
      const isValue = this.formData.referenceType === 'value'
      const notTrend = this.formData.referenceType !== 'trend'
      const isTrend = this.formData.referenceType === 'trend'
      return [
        {
          field: 'source',
          label: 'Data Source',
          controlType: 'selectInput',
          required: true,
          labelClass: 'width-180',
          inputClass: 'width-175',
          options: [
            {
              id: 'ground-truth',
              value: 'ground-truth',
              displayName: 'Ground Truth'
            }
          ]
        },
        {
          field: 'metric',
          label: 'Metric',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          required: true,
          options: MetricsGroundTruthOptions(),
          noSelectionLabel: "-- Select Metric --"
        },
        {
          field: 'referenceType',
          label: 'Reference Type',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          required: true,
          options: [
            {
              id: 'value',
              value: 'value',
              displayName: 'Constant Value'
            },
            {
              id: 'trend',
              value: 'trend',
              displayName: 'Measurement Trend'
            }
          ]
        },
        {
          label: "Reference Sample Settings",
          controlType: 'sectionHeader',
          hidden: notValue
        },
        {
          field: 'refValue',
          label: 'Reference Value',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'textInput',
          required: isValue,
          dataType: 'decimal',
          hidden: notValue
        },
        {
          label: "Measurement Trend Settings",
          controlType: 'sectionHeader',
          hidden: notTrend
        },
        {
          field: 'refTrendDurationDays',
          label: 'Reference Trend # Days',
          labelClass: 'width-180 font-reduce-2',
          inputClass: 'width-175',
          controlType: 'textInput',
          required: isTrend,
          dataType: 'integer',
          hidden: notTrend,
          placeholder: 'in # Days'
        },
        {
          field: 'refTrendAggFunc',
          label: 'Ref. Trend Aggregation',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          required: isTrend,
          options: [
            {
              id: 'avg',
              value: 'avg',
              displayName: 'Average'
            }
          ],
          hidden: notTrend
        },
        {
          field: 'testTrendDurationHours',
          label: 'Test Trend # Hours',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'textInput',
          required: true,
          dataType: 'integer',
          placeholder: 'in # Hours'
        },
        {
          field: 'testTrendAggFunc',
          label: 'Test Trend Aggregation',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          required: true,
          options: [
            {
              id: 'avg',
              value: 'avg',
              displayName: 'Average'
            }
          ]
        },
        {
          field: 'testCondition',
          label: 'Alarm if',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          required: isValue,
          options: [
            {
              id: 'aboveReferenceValue',
              value: 'aboveReferenceValue',
              displayName: 'Above Ref. Value'
            },
            {
              id: 'belowReferenceValue',
              value: 'belowReferenceValue',
              displayName: 'Below Ref. Value'
            },
            {
              id: 'overVariancePercent',
              value: 'overVariancePercent',
              displayName: 'Over Variance %'
            }
          ],
          hidden: notValue,
        },
        {
          field: 'variancePercent',
          label: 'Max % Variance',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'textInput',
          required: !(isValue && this.formData.testCondition !== 'overVariancePercent'),
          dataType: 'decimal',
          hidden: isValue && this.formData.testCondition !== 'overVariancePercent'
        },
        {
          label: "Filters",
          controlType: 'sectionHeader'
        },
        {
          field: 'deviceTypeFilter',
          label: 'Device Type Filter',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          noSelectionLabel: 'None',
          options: [
            {
              id: 'gv',
              value: 'gv',
              displayName: 'Agrology V1/V2'
            },
            {
              id: 'co2',
              value: 'co2',
              displayName: 'Any CO2 Sensor'
            }
          ]
        },
        {
          field: 'deviceModelFilter',
          label: 'Device Model Filter',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'textInput',
          dataType: 'version',
          placeholder: 'lp8p, v2, etc.'
        },
        {
          field: 'nodeRoleFilter',
          label: 'Node Role Filter',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          noSelectionLabel: 'None',
          options: this.nodeRoleOptions
        },
        {
          field: 'devicePositionFilter',
          label: 'Position Filter',
          labelClass: 'width-180',
          inputClass: 'width-175',
          controlType: 'selectInput',
          noSelectionLabel: 'None',
          options: this.positionOptions
        },
      ]
    }
  },
  methods: {
    setCustomerNameFromIndex (data) {
      const searchIndex = FleetDataService.getManifest()
      if (data && data.customerId) {
        const custData = searchIndex['customer.' + data.customerId]
        if (custData) {
          data.customerName = custData.title
        }
      }
    },
    setSiteNameFromIndex (data) {
      const searchIndex = FleetDataService.getManifest()
      if (data && data.siteId) {
        const siteData = searchIndex['site.' + data.siteId]
        if (siteData) {
          data.siteName = siteData.title
        }
      }
    },
    getAlertTableThreshCell (alert) {
      let value = this.getLastWord(alert.thresholdIds)
      if (alert.thresholdIds && alert.thresholdIds.includes(',')) {
        value = "multiple"
      }
      return { value: value, click: this.showEditorFromID, i: alert.thresholdIds, class: { inactive: alert.status === 'resolved' } }
    },
    showResolvedCheckboxClicked (e) {
      if (e && e.target && e.target.checked === true) {
        this.showResolved = true
      } else {
        this.showResolved = false
      }
    },
    getDefaultPill () {
      if (this.$route.name && this.$route.name.endsWith('thresholds')) {
        return 'thresholds'
      }
      return 'alerts'
    },
    activePillChanged (value) {
      if (value) {
        this.activePill = value
        this.$router.push({ name: 'ads-list-'+value })
      }
    },
    defaultOptions () {
      return {
        source: 'ground-truth',
        referenceType:'trend',
        refTrendAggFunc: 'avg',
        testTrendAggFunc: 'avg'
      }
    },
    prepareThresholdPayload (data) {
      if ('refValue' in data){
        data.refValue = parseFloat(data.refValue)
      }
      if ('variancePercent' in data){
        data.variancePercent = parseFloat(data.variancePercent)
      }
      if ('refTrendDurationDays' in data){
        data.refTrendDurationDays = parseInt(data.refTrendDurationDays)
      }
      if ('testTrendDurationHours' in data){
        data.testTrendDurationHours = parseInt(data.testTrendDurationHours)
      }
    },
    formDataChanged (formData) {
      this.formData = formData
    },
    async deleteAdsThreshold (id) {
      await NotificationsDataService.deleteAdsThreshold(id)
    },
    async createAdsThreshold (data) {
      this.prepareThresholdPayload(data)
      await NotificationsDataService.createAdsThreshold(data)
    },
    async updateAdsThreshold (data) {
      this.prepareThresholdPayload(data)
      await NotificationsDataService.updateAdsThreshold(data.id, data)
    },
    showThreshSelector (ids) {
      this.thresholdIds = ids
      this.$nextTick(() => {
        this.$refs.adsShowAlertTresholds.show()
      })
    },
    showCreator () {
      this.formData = this.defaultOptions()
      this.$refs.adsAddThreshold.show()
    },
    showEditorFromID (id) {
      if (!id) {
        return
      }
      if (id.includes(',')) {
        this.showThreshSelector(id)
      } else {
        this.thresholdId = id
      }
    },
    showAlertFromID (id) {
      if (!id) {
        return
      }
      this.alertId = id
    },
    showEditor (threshold) {
      this.thresholdData = threshold
      this.formData = { ...threshold }
      let path = this.$route.href
      const i = path.indexOf('/ads/')
      if (i >= 0) {
        path = path.substring(0, i+5) + `thresholds/${threshold.id}`
        history.pushState(
          {},
          null,
          path
        )
      }
      this.$nextTick(() => {
        this.$refs.adsEditThreshold.show()
      })
    },
    closeEditor() {
      let path = this.$route.href
      const i = path.indexOf('/ads/')
      this.thresholdId = ''
      if (i >= 0) {
        path = path.substring(0, i+5) + this.activePill
        history.pushState(
          {},
          null,
          path
        )
      }
    },
    showADSAlert (alert) {
      const a = { ...alert }
      this.setSiteNameFromIndex(a)
      this.setCustomerNameFromIndex(a)
      this.alertData = a
      let path = this.$route.href
      const i = path.indexOf('/ads/')
      if (i >= 0) {
        path = path.substring(0, i+5) + `alerts/${a.id}`
        history.pushState(
          {},
          null,
          path
        )
      }
      this.$nextTick(() => {
        this.$refs.adsShowAlert.show()
      })
    },
    closeAlertViewer() {
      let path = this.$route.href
      const i = path.indexOf('/ads/')
      if (i >= 0) {
        path = path.substring(0, i+5) + this.activePill
        history.pushState(
          {},
          null,
          path
        )
      }
    },
    enabledIcon (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return 'check'
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return 'lock'
      }
      return ''
    },
    enabledClass (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return 'status-ok text-center'
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return 'lock-icon text-center'
      }
      return 'text-center'
    },
    enabledText (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return ''
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return ''
      }
      return '--'
    },
    refreshList () {
      this.isLoading = true
      let isThreshLoading = true
      let isAlertsLoading = true
      NotificationsDataService.getAllADSThresholds()
        .then(response => {
          this.thresholds = response.data
        })
        .catch(e => {
          let message = 'Unable to Load ADS Threshold List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          isThreshLoading = false
          this.isLoading = isAlertsLoading && isThreshLoading
        })
      NotificationsDataService.getAllADSAlerts()
        .then(response => {
          this.alerts = response.data
        })
        .catch(e => {
          let message = 'Unable to Load ADS Alert List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          isAlertsLoading = false
          this.isLoading = isAlertsLoading && isThreshLoading
        })
    }
  },
  mounted () {
    this.activeSection = this.$route.name && this.$route.name.endsWith("-thresholds") ? 'thresholds' : 'alerts'
    if (this.$route.params.thresholdId) {
      this.showEditorFromID(this.$route.params.thresholdId)
    }
    if (this.$route.params.alertId) {
      this.showAlertFromID(this.$route.params.alertId)
    }
    this.listFilter = localStorage.getItem('recentThresholdsTab')
    if (!this.listFilter) {
      this.listFilter = 'recent'
    }
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel2('Notifications', 'Anomaly Detection', '#/notifications', '')
    Reloadables.registerReloadable('ads-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('ads-list')
  },
  components: { DataTable, ModalSubmissionForm, Pillbox, ModalADSAlertInfo, ModalADSAlertThreshInfo }
}
</script>
