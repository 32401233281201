<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" :sz="modalSize">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <form ref="zoneForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-bottom:-12px">

          <div class="d-flex">
            <div>
              <TextInputRow label="Description"
                v-model="formData.description"
                placeholder="Short Description of the Zone"
                maxlength="36"
                dataType="description"
                labelClass="width-95"
                inputStyle="width:180px;"
              />

              <SelectRow label="Category" v-model="formData.category" labelClass="width-95" selectStyle="width:180px" >
                <template v-slot:options>
                  <option value="">-- Select Category --</option>
                  <NotificationCategories />
                </template>
              </SelectRow>

              <div class="mb-1" style="margin-left:30px;">Paste in GeoJSON FeatureCollection:</div>
              <TextAreaRow
                v-model="geojson"
                inputStyle="margin-left: 20px; width:320px; height:317px"
              />

              <div v-if="coordinates" class="ms-4 ps-2 notable mb-3">
                <fa icon="check" class="flash-icon-svg me-2" />
                {{coordinatesType}} Coordinates are Valid
              </div>
              <div v-else-if="geojson && !coordinates" class="ms-4 ps-2 warning mb-3">
                <fa icon="xmark" class="flash-icon-svg warning me-2" />
                Invalid GeoJSON Document
              </div>
            </div>

            <div style="width: 480px; margin-left: 15px;" v-if="polygons.length > 0">
              Map View
              <MapControl
                :polygons="polygons"
                :hideStyles="true"
                :useBounds="true"
                height="402px"
                v-if="polygons.length > 0"
              />
            </div>

          </div>

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateZone()" v-if="!isCreate" :disabled="isLoading">Submit</button>
          <button class="btn btn-red ms-3" @click="deleteZone()" v-if="!isCreate" :disabled="isLoading">Delete</button>
          <button class="btn ms-3" @click="createZone()" :disabled="isLoading || !formData.category || !formData.description|| polygons.length == 0" v-else>Submit</button>
          <a class="btn btn-blue ms-3" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/modals/Modal.vue'
import NotificationCategories from '@/components/selectOptions/NotificationCategories.vue'
import MapControl from '@/components/map/MapControl.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'

import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'

export default {
  name: 'zone-add-update',
  props: ['zone'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      isLoading: false,
      mode: '',
      geojson: ''
    }
  },
  watch: {
    polygonName: {
      handler () {
        if (this.polygonName) {
          this.formData.description = this.polygonName
        }
      }
    }
  },
  computed: {
    modalSize () {
      return this.polygons.length > 0 ? 'lg' : 'sm'
    },
    polygons () {
      let coordinates = ''
      if (this.coordinates) {
        coordinates = this.coordinates
      } else if (this.zone && this.zone.polygon) {
        coordinates = this.zone.polygon
      }
      if (coordinates !== '') {
        const polygons = coordinates.split('|')
        const paths = []
        for (const i in polygons) {
          const parts = polygons[i].split(';')
          const path = []
          for (const i in parts) {
            const subParts = parts[i].split(',')
            if (subParts.length === 2) {
              subParts[0] = parseFloat(subParts[0])
              subParts[1] = parseFloat(subParts[1])
              path.push(subParts)
            }
          }
          paths.push(path)
        }
        return paths
      }
      return []
    },
    coordinatesType () {
      if (!this.coordinates) {
        return ''
      }
      if (this.coordinates.includes('|')) {
        return 'MultiPolygon'
      }
      return 'Polygon'
    },
    polygonName () {
      let name = ''
      if (this.zone) {
        name = this.zone.description
      }
      let obj = {}
      if (this.geojson) {
        try {
          obj = JSON.parse(this.geojson)
        } catch (e) {
          return ''
        }
        if (obj.features.length > 0 && obj.features[0].properties &&
          obj.features[0].properties.name) {
          return obj.features[0].properties.name
        }
      }
      return name
    },
    coordinates () {
      if (!this.geojson) {
        return ''
      }
      let isValid = false
      let obj = {}
      try {
        obj = JSON.parse(this.geojson)
        isValid = true
      } catch (e) {
        return ''
      }
      // For Polygon and MultiPolygon (not inside GeometryCollection)
      if (obj.type && obj.type === 'FeatureCollection' && obj.features &&
        obj.features.length > 0 && obj.features[0].geometry &&
        obj.features[0].geometry.type && obj.features[0].geometry.coordinates &&
        obj.features[0].geometry.coordinates.length > 0) {
        isValid = true
        const geometry = obj.features[0].geometry
        if (geometry.type === 'Polygon') {
          const list = []
          for (const i in geometry.coordinates[0]) {
            if (geometry.coordinates[0][i].length !== 2) {
              continue
            }
            list.push(`${geometry.coordinates[0][i][0]},${geometry.coordinates[0][i][1]}`)
          }
          return list.join(';')
        } else if (geometry.type === 'MultiPolygon') {
          const list = []
          for (const i in geometry.coordinates) {
            const lsub = []
            for (const j in geometry.coordinates[i][0]) {
              if (geometry.coordinates[i][0][j].length !== 2) {
                continue
              }
              lsub.push(`${geometry.coordinates[i][0][j][0]},${geometry.coordinates[i][0][j][1]}`)
            }
            list.push(lsub.join(';'))
          }
          return list.join('|')
        }
      }
      // For GeometryCollection
      if (obj.type && obj.type === 'FeatureCollection' && obj.features &&
        obj.features.length > 0 && obj.features[0].geometry &&
        obj.features[0].geometry.type && obj.features[0].geometry.geometries &&
        obj.features[0].geometry.geometries.length > 0) {
        const geometry = obj.features[0].geometry.geometries[0]
        if (geometry.type === 'Polygon') {
          const list = []
          for (const i in geometry.coordinates[0]) {
            if (geometry.coordinates[0][i].length !== 2) {
              continue
            }
            list.push(`${geometry.coordinates[0][i][0]},${geometry.coordinates[0][i][1]}`)
          }
          return list.join(';')
        } else if (geometry.type === 'MultiPolygon') {
          const list = []
          for (const i in geometry.coordinates) {
            const lsub = []
            for (const j in geometry.coordinates[i][0]) {
              if (geometry.coordinates[i][0][j].length !== 2) {
                continue
              }
              lsub.push(`${geometry.coordinates[i][0][j][0]},${geometry.coordinates[i][0][j][1]}`)
            }
            list.push(lsub.join(';'))
          }
          return list.join('|')
        }
      }
      return ''
    },
    headerText () {
      if (this.zone) {
        return 'Edit GPS Exclusion Zone'
      }
      return 'Add New GPS Exclusion Zone'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      this.geojson = ''
      if (this.zone) {
        this.formData = { ...this.zone }
        this.isCreate = false
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    deleteZone () {
      this.isLoading = true
      NotificationsDataService.deleteExclusionZone(this.zone.id)
        .then(() => {
          HelpContent.setTimedFlashMessage('GPS Exclusion Zone Deleted')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'GPS Exclusion Zone Deletion Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateZone () {
      this.isLoading = true
      const postData = { ...this.formData }
      delete postData.id
      NotificationsDataService.updateExclusionZone(this.zone.id, postData)
        .then(() => {
          HelpContent.setTimedFlashMessage('GPS Exclusion Zone Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'GPS Exclusion Zone Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createZone () {
      this.isLoading = true
      this.formData.polygon = this.coordinates
      NotificationsDataService.createExclusionZone(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('GPS Exclusion Zone Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'GPS Exclusion Zone Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': GPS Exclusion Zone Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, MapControl, TextInputRow, TextAreaRow, SelectRow, NotificationCategories }
}
</script>
