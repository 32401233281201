<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <InfoPane
    class="dt-info-pane-sm"
    title="Template Info"
    v-else-if="agtemplate"
    :actionFunc="showEditor"
    actionIcon="pen-to-square"
    itemType="Template"
  >
    <template v-slot:info>
      <Modal ref="addEditModal" :tmpl="agtemplate" />
      <ul class="info-pane-list">
        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.description">
          <div class="col">
            <span class="list-heading-label node-notes">
              {{ agtemplate.description }}
            </span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.id">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label me-1">ID:</span><span class="font-reduce-1">{{ agtemplate.id }}</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.notes">
          <div class="col node-notes node-general-notes">
            {{ agtemplate.notes }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Medium:</span> {{ agtemplate.medium }}<span v-if="!agtemplate.medium">--</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col-auto contact-row-item node-notes" style="width: 347px;" >
            <span class="attribute-label me-1">{{ labels.subj }}:</span>
            <span v-if="agtemplate.subject">{{ agtemplate.subject }}</span>
            <span v-else>--</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.body">
          <div class="col contact-row-item" >
            <span class="attribute-label">{{ labels.body }}:</span>
            <div class="node-notes">{{ agtemplate.body }}</div>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.medium === 'email' && agtemplate.growerSelfSvc">
          <div class="col contact-row-item">
            <span class="attribute-label"></span>
            <span>
              <fa icon="check" class="flash-icon-svg me-2" />
            </span>
            <span>Available for Grower Self-Service</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.medium === 'email'">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Attach:</span> {{ attachmentName }}<span v-if="!attachmentName">--</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="agtemplate.lastModified">
          <span class="subtle-info status-last-modified me-1">
            {{ getSinceSecs(agtemplate.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="agtemplate.lastModifiedBy"> by {{ agtemplate.lastModifiedBy }}</span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>

<script>
import BaseTemplateComponent from '@/components/notifications/BaseTemplateComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import Modal from '@/components/notifications/ModalAddUpdateTemplate.vue'

export default {
  name: 'agtemplate',
  extends: BaseTemplateComponent,
  data () {
    return {
      templateBeforeEdit: {}
    }
  },
  computed: {
    attachmentName () {
      if (!this.agtemplate || !this.agtemplate.attachments || this.agtemplate.attachments.length === 0) {
        return ''
      }
      if (this.agtemplate.attachments[0] in this.attachmentOptions) {
        return this.attachmentOptions[this.agtemplate.attachments[0]]
      }
      return this.agtemplate.attachments[0]
    },
    labels () {
      var d = {
        subj: '',
        body: '',
        help: '',
        subjPlaceholder: '',
        subjRequired: false
      }
      if (!this.agtemplate) {
        return d
      }
      if (this.agtemplate.medium === 'slack') {
        d.subj = 'Chan ID'
        d.body = 'Message'
        d.help = 'Find the Channel ID in a Slack Channel\'s Settings. This field does not accept #channel-names. Leave blank to use #data-alerts.'
        d.subjPlaceholder = 'C02C9GNH7GD'
        d.subjRequired = false
      } else if (this.agtemplate.medium === 'email') {
        d.subj = 'Subject'
        d.body = 'Body'
        d.help = ''
        d.subjRequired = true
        d.subjPlaceholder = ''
      } else if (this.agtemplate.medium === 'push') {
        d.subj = 'Subject'
        d.body = 'Message'
        d.help = ''
        d.subjRequired = false
        d.subjPlaceholder = ''
      }
      return d
    }
  },
  methods: {
    showEditor () {
      this.$refs.addEditModal.show()
    },
    getTemplate (id) {
      this.isLoading = true
      NotificationsDataService.getTemplate(id)
        .then((response) => {
          this.agtemplate = response.data
          this.templateBeforeEdit = Object.assign({}, this.agtemplate)
          this.$state.creationName = this.agtemplate.id
          this.setNavTemplate(this.agtemplate.description)
          if (this.agtemplate.attachments) {
            this.agtemplate.attachment = this.agtemplate.attachments.join(',')
          } else {
            this.agtemplate.attachment = ''
          }
        })
        .catch(e => {
          let message = 'Failed to retrieve Template info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshInfo () {
      this.getTemplate(this.$route.params.id)
    }
  },
  mounted () {
    this.refreshInfo()
    this.setNavTemplate(this.$route.params.id)
    Reloadables.registerReloadable('template-info', this.refreshInfo)
  },
  unmounted () {
    Reloadables.unregisterReloadable('template-info')
  },
  components: { InfoPane, Modal }
}
</script>

<style>
  .btn-blue {
    margin-left: 20px;
  }
</style>
