<template>
  <div>
    <Modal ref="fwadd" :make="make.id" :model="model" @refreshList="refreshList" v-if="make" />
    <Modal ref="fwedit" :version="version" @refreshList="refreshList" />
    <div class="d-flex align-items-center mb-1">
      <h4 class="my-1 pb-1 me-4">Manage Firmware</h4>
      <label class="list-heading-label me-2">Make</label>
      <select id="selectMake" v-model="make" class="form-select me-3" style="width:180px" @change="model=''">
        <option :value="null">-- Select Make --</option>
        <option v-for="(m, i) in manufacturers" :key="i" :value="m">{{ m.description }}</option>
      </select>

      <label class="list-heading-label me-2" v-if="make">Model</label>
      <select id="selectModel" v-model="model" v-if="make" class="form-select" style="width:180px" @change="refreshList">
        <option value="">-- Select Model --</option>
        <option v-for="m in models" :key="m" :value="m">{{ m }}</option>
      </select>

    </div>

    <DataTable
      class="dt-firmware"
      :title="`Firmware for ${make.id} / ${model}`"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :actionFunc="showCreateModal"
      actionIcon="plus"
      itemType="Version"
      v-if="make && model"
    >
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/fleet/ModalAddUpdateFirmwareVersion.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'firmware-list',
  data () {
    return {
      cols: [
        { text: 'Version' },
        { text: 'Default' },
        { text: 'Description' },
        { text: 'Checksum' }
      ],
      firmware: null,
      version: null,
      make: null,
      model: '',
      isLoading: false,
      manufacturers: null
    }
  },
  computed: {
    models () {
      if (!this.make) {
        return null
      }
      return this.make.models.split(',')
    },
    rows () {
      const out = []
      if (this.firmware && this.firmware.length > 0) {
        for (const firmware of this.firmware) {
          out.push([
            { value: firmware.version, click: this.showEditModal, i: firmware, class: { inactive: firmware.status === 'inactive' } },
            { leftIcon: this.isDefaultIcon(firmware.isDefault) },
            { value: firmware.description },
            { value: firmware.fileChecksum }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showCreateModal () {
      this.$refs.fwadd.show()
    },
    showEditModal (i) {
      this.version = { ...i }
      this.$nextTick(() => {
        this.$refs.fwedit.show()
      })
    },
    isDefaultIcon (input) {
      if (input) {
        return 'check'
      }
      return 'minus'
    },
    firmwareLinkColor (firmware, baseClass) {
      let classes = baseClass
      if (firmware.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.isLoading = true
      if (!this.make || !this.model) {
        FleetDataService.getAllManufacturers()
          .then(response => {
            this.manufacturers = response.data
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.isLoading = false
          })
        return
      }
      this.isLoading = true
      FleetDataService.listFirmwareVersionsByMakeModel(this.make.id, this.model)
        .then(response => {
          this.firmware = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('firmware-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('firmware-list')
  },
  components: { DataTable, Modal }
}
</script>
