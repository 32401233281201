<template>
  <div>
    <Modal ref="createModal" :script="script" />
    <Modal ref="editModal" :script="script" :version="activeVersion" />
    <DataTable
      class="dt-modeling-versions"
      title="Script Versions"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="showCreateModal"
      actionIcon="plus"
      itemType="Version"
      noDataMessage="No Versions for this Script yet"
      :initialSort="0"
    >
      <template v-slot:filter-pillbox>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/modeling/ModalAddUpdateVersion.vue'

export default {
  components: { DataTable, Modal },
  props: ['script', 'versions', 'loading', 'versionID'],
  data () {
    return {
      activeVersion: null,
      cols: [
        { text: 'Version', sort: 0 },
        { text: 'Valid', sort: 1, style: 'text-align:center;' },
        { text: 'Published', sort: 2, style: 'text-align:center;' },
        { text: 'Active', sort: 3, style: 'text-align:center;' }
      ]
    }
  },
  name: 'modeling-script-versions-list',
  watch: {
    versions: {
      handler () {
        this.checkDeepLink()
      }
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.versions && this.versions.length > 0) {
        for (const i in this.versions) {
          out.push([
            { value: this.versions[i].version, click: this.showEditModal, i: i },
            { value: this.versions[i].validated, checkbox: true, style: 'text-align:center;' },
            { value: this.versions[i].published, checkbox: true, style: 'text-align:center;' },
            { value: this.script && this.script.activeVersion === this.versions[i].version, checkbox: true, style: 'text-align:center;' }
          ])
        }
      }
      return out
    }
  },
  methods: {
    checkDeepLink () {
      if (this.versionID && this.versions && this.versions.length > 0) {
        for (const i in this.versions) {
          if (this.versions[i].version === this.versionID) {
            this.showEditModal(i)
          }
        }
      }
    },
    showEditModal (i) {
      this.activeVersion = this.versions[i]
      this.$nextTick(() => {
        this.$refs.editModal.show()
      })
    },
    showCreateModal () {
      this.$refs.createModal.show()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkDeepLink()
    })
  }
}
</script>
