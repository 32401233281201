export interface Theme {
  name: string
  value: string
}

const defaultTheme = 'darkMode'
const themeStorageKey = 'prefs.theme'

const supportedThemes = [
  { name: 'Light Mode', value: 'lightMode' },
  { name: 'Dark Mode', value: defaultTheme },
  { name: 'High Contrast Mode', value: 'highContrastMode' }
] as Theme[]

export function loadTheme(): string {
  var theme = localStorage.getItem(themeStorageKey)
  if (!theme) {
    theme = defaultTheme
    localStorage.setItem(themeStorageKey, defaultTheme)
    return theme
  }
  for (const st of supportedThemes) {
    if (st.value === theme) {
      return theme
    }
  }
  localStorage.setItem(themeStorageKey, defaultTheme)
  return defaultTheme
}