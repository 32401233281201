<template>
  <div>
    <Modal ref="addEditModal" />
    <DataTable
      class="dt-recent-templates"
      title="Templates"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No Templates Listed"
      actionIcon="plus"
      :actionFunc="showEditor"
      itemType="Template"
      :initialSort="0"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/notifications/ModalAddUpdateTemplate.vue'

export default {
  name: 'templates-recent-list',
  extends: BaseComponent,
  data () {
    return {
      cols: [
        { text: 'Description', sort: 0 },
        { text: 'Medium', sort: 1 },
        { text: 'Subject / Channel', sort: 2 },
        { text: 'Grower SS', sort: 3 },
        { text: 'Notes', sort: 4 }
      ],
      templates: []
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.templates && this.templates.length > 0) {
        for (const template of this.templates) {
          out.push([
            { value: template.description, route: 'template-details', params: { id: template.id } },
            { value: template.medium },
            { value: template.subject ? template.subject : '--' },
            { leftIcon: this.isGrowerSelfSvc(template), class: 'status-ok text-center', sortValue: template.growerSelfSvc },
            { value: template.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showEditor () {
      this.$refs.addEditModal.show()
    },
    isGrowerSelfSvc (template) {
      if (template.medium === 'email' && template.growerSelfSvc) {
        return 'check'
      }
      return ''
    },
    refreshList () {
      this.isLoading = true
      NotificationsDataService.getAllTemplates()
        .then(response => {
          this.templates = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Template List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.listFilter = localStorage.getItem('recentTemplatesTab')
    if (!this.listFilter) {
      this.listFilter = 'recent'
    }
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel2('Notifications', 'templates', '#/notifications', '')
    Reloadables.registerReloadable('templates-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('templates-list')
  },
  components: { DataTable, Modal }
}
</script>
