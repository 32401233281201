<template>
  <TabBar activeTabName="exclusionZones" />
  <ExclusionZones />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import TabBar from '@/components/notifications/TabBar.vue'
import ExclusionZones from '@/components/notifications/ExclusionZones.vue'

@Options({
  components: {
    ExclusionZones,
    TabBar
  }
})
export default class NotificationsHome extends Vue {
}
</script>
