<template>
  <TabBar activeTabName="ads" />
  <ADSMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ADSMainList from '@/components/notifications/ADSMainList.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ADSMainList,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
</script>
