<template>
  <div>
    <Modal ref="zoneadd" @reloadZone="reloadZone" />
    <Modal ref="zoneedit" :zone="zoneData" @reloadZone="reloadZone" />
    <DataTable
      class="dt-recent-zones"
      title="GPS Exclusion Zones"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No GPS Exclusion Zones Listed"
      :actionFunc="showAddPane"
      itemType="Exclusion Zone"
      :initialSort="1"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/notifications/ModalAddUpdateExclusionZone.vue'

export default {
  name: 'zones-recent-list',
  extends: BaseComponent,
  data () {
    return {
      cols: [
        { text: 'ID', sort: 0 },
        { text: 'Description', sort: 1 },
        { text: 'Category', sort: 2 }
      ],
      zones: [],
      zoneData: null
    }
  },
  computed: {
    rows () {
      const out = []
      const zones = this.zones
      if (zones && zones.length > 0) {
        for (const zone of zones) {
          out.push([
            { value: this.getLastWord(zone.id), click: this.showEditPane, i: zone },
            { value: zone.description },
            { value: zone.category }
          ])
        }
      }
      return out
    }
  },
  methods: {
    reloadZone () {
      this.$emit('reloadZone')
    },
    refreshList () {
      this.isLoading = true
      NotificationsDataService.getExclusionZones()
        .then(response => {
          this.zones = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Exclusion Zone List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showAddPane () {
      this.$refs.zoneadd.show()
    },
    showEditPane (zone) {
      this.zoneData = zone
      this.$nextTick(() => {
        this.$refs.zoneedit.show()
      })
    }
  },
  mounted () {
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel2('Notifications', 'GPS Exclusion Zones', '#/notifications', '')
    Reloadables.registerReloadable('exclusion-zones', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('exclusion-zones')
  },
  components: { DataTable, Modal }
}
</script>
