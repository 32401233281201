import { reactive } from 'vue'

class Reloadables {
  reloader = this.emptyFunc
  isReloading = false

  manifest = reactive({
  })

  state = reactive({
    showReloader: false
  })

  emptyFunc () {
    // intentionally empty
  }

  registerReloader (f) {
    this.reloader = f
  }

  unregisterReloader () {
    this.reloader = this.emptyFunc
  }

  registerReloadable (name, f) {
    this.manifest[name] = f
    this.state.showReloader = true
  }

  unregisterReloadable (name) {
    delete this.manifest[name]
    this.state.showReloader = Object.keys(this.manifest).length > 0
  }

  getReloadables () {
    return this.manifest
  }

  requestReload () {
    if (this.reloader) {
      this.reloader()
    }
  }
}

export default new Reloadables()
