<template>
  <TabBar activeTabName="misc" />
  <div class="row justify-content-center px-0 mx-0">
    <FirmwareMainList />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import FirmwareMainList from '@/components/fleet/FirmwareMainList.vue'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    FirmwareMainList,
    TabBar
  }
})
export default class FirmwareList extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Firmware', '#/fleet', '')
  }
}
</script>
