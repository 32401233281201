<template>

  <div class="row justify-content-center mx-0 px-0 pt-3">

    <InfoPane paneClass="fleet-misc-info-pane" class="fleet-misc-info-pane mb-4">
      <template v-slot:info>
        <ul class="info-pane-list">
          <li class="info-pane-list-item">
              <h5 class="mt-2 ps-2">Additional Notification Items</h5>
          </li>
          <li class="info-pane-list-item height-50 d-flex">
            <router-link
              :to="{  name: 'exclusion-zones' }" class="info-pane-item-link hoverable">Manage GPS Exclusion Zones
            </router-link>
          </li>
        </ul>
      </template>
    </InfoPane>

  </div>
</template>
<script lang="ts">
import InfoPane from '@/components/tables/InfoPane.vue'
export default {
  name: 'notifications-misc-main',
  components: { InfoPane },
  data () {
    return {
    }
  },
  methods: {
  }
}

</script>
