<template>
      <span class="help-icon">
        <a @click="clickEvent">
          <fa icon="circle-question" class="button-icon" v-bind:class="{ 'active-link': isActive}" />
        </a>
      </span>
</template>

<script>
export default {
  props: ['defaultActive', 'clickFunc'],
  data () {
    return {
      isActive: this.defaultActive === true ? true : false
    }
  },
  methods: {
    clickEvent () {
      this.isActive = !this.isActive
      if (this.clickFunc) {
        this.clickFunc(this.isActive)
      }
    }
  }

}
</script>
