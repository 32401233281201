<template>

  <div>

    <h4>
      <fa icon="shield-alt" class="map-icon me-2" />
      Manage Staff Access
    </h4>

    <hr class="mt-3 mb-1" />

    <div class="row mb-2 mx-0 px-0">

      <div class="col mt-3 px-0 mx-0">
        <TextSubmitRow label="Email"
            v-model="emailSearch"
            inputStyle="text-transform: lowercase; width: 245px;"
            labelClass="width-45"
            placeholder="user@example.com"
            maxlength="48"
            dataType="email"
            :buttonAction="loadUserAccess"
            buttonText="Go"
            :enterInvokesAction="true"
            :selectAllOnAction="true"
            :focusOnAction="true"
            autocomplete="off"
            id="searchStaffAccess"
          />
      </div>

    </div>
    <hr class="mb-3" />

    <div v-if="emailUser" class="pt-1 ms-md-3">
      <h4 class="mb-2 ms-1" v-if="emailUser">{{ emailUser }}</h4>
      <div class="d-flex flex-wrap">
        <InfoPane
        class="dt-info-pane-sm ms-0 me-3 mt-3 mb-auto"
        title="Roles Assigned to this User"
        >
          <template v-slot:info>
            <ul class="role-list" v-if="allUserRoles.length > 0">
              <li v-for="(role) in allUserRoles" :key="role.id" class="ps-3 pb-3">
                <StaffRoleItem :role="role" :action="removeStaffRoleAccess" :actionParam1="emailUser" :actionParam2="role.id" :actionParam3="role.label" icon="trash-alt" />
              </li>
            </ul>
            <div class="p-3" v-else>
              No roles assigned to this user.
            </div>
          </template>
        </InfoPane>

        <InfoPane
        class="dt-info-pane-sm ms-0 me-3 mt-3 mb-auto"
        title="Assign New Role to this User"
        >
          <template v-slot:info>
              <ul class="role-list" v-if="nonUserRoles.length > 0">
                <li v-for="(role) in nonUserRoles" :key="role.id" class="ps-3 pb-3">
                  <StaffRoleItem :role="role" :action="addStaffRoleAccess" :actionParam1="emailUser" :actionParam2="role.id" :actionParam3="role.label" icon="plus" />
                </li>
              </ul>
              <div class="p-3" v-else>
                No available roles to assign.
              </div>
          </template>
        </InfoPane>

      </div>
    </div>
  </div>
</template>
<script>
// import DataTable from '@/components/tables/DataTable.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import TextSubmitRow from '@/components/forms/TextSubmitRow.vue'
import StaffRoleItem from '@/components/admin/StaffRoleItem.vue'

import AdminDataService from '@/services/admin/AdminDataService'
import HelpContent from '@/services/HelpContent'

export default {
  components: { InfoPane, TextSubmitRow, StaffRoleItem },
  data () {
    return {
      emailSearch: '',
      emailUser: '',
      roles: [],
      userRoles: [],
      isLoading: false
    }
  },
  mounted () {
    this.getRoles(true)
  },
  computed: {
    userRolesLkp () {
      const out = {}
      for (const r of this.userRoles) {
        out[r.role] = true
      }
      return out
    },
    nonUserRoles () {
      const out = []
      for (const r of this.roles) {
        if (!(r.id in this.userRolesLkp)) {
          out.push(r)
        }
      }
      return out
    },
    allUserRoles () {
      const out = []
      for (const r of this.roles) {
        if (r.id in this.userRolesLkp) {
          out.push(r)
        }
      }
      return out
    }
  },
  methods: {
    isWarning (input) {
      if (input) {
        return 'This access is highly-sensitive and could be catastrophic in the wrong hands.'
      }
      return null
    },
    addStaffRoleAccess (email, role, roleName) {
      if (email && role) {
        this.isLoading = true
        AdminDataService.addUserRole(email, role)
          .then(() => {
            HelpContent.setTimedFlashMessage(`Staff Access Updated / user: ${email} / action: GRANT / role: ${roleName}`, 5000)
            this.getUserAccess()
          })
          .catch(e => {
            let message = 'Failed to Update Staff Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    removeStaffRoleAccess (email, role, roleName) {
      if (email && role) {
        this.isLoading = true
        AdminDataService.removeUserRole(email, role)
          .then(() => {
            HelpContent.setTimedFlashMessage(`Staff Access Updated / user: ${email} / action: REVOKE / role: ${roleName}`, 5000)
            this.getUserAccess()
          })
          .catch(e => {
            let message = 'Failed to Update Staff Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    loadUserAccess () {
      this.emailSearch = this.emailSearch.toLowerCase()
      this.emailUser = this.emailSearch
      this.$router.push({ name: 'admin-staff-access-email', params: { email: this.emailUser.toLowerCase() } })
    },
    getUserAccess () {
      this.emailSearch = this.emailSearch.toLowerCase()
      this.emailUser = this.emailSearch
      this.getUserRoles()
    },
    getUserRoles () {
      this.isLoading = true
      this.emailUser = this.emailUser.toLowerCase()
      AdminDataService.getRolesByUser(this.emailUser)
        .then(response => {
          this.userRoles = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Staff Roles for ' + this.emailUser
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getRoles (loadUser = false) {
      this.isLoading = true
      AdminDataService.getStaffRoles()
        .then(response => {
          var data = response.data
          data.sort((a, b) => {
            if (a.label < b.label) {
              return -1
            } else if (a.label > b.label) {
              return 1
            }
            return 0
          })
          this.roles = data
          if (loadUser && this.$route.params.email) {
            this.emailSearch = this.$route.params.email
            this.emailUser = this.emailSearch
            this.getUserAccess()
          }
        })
        .catch(e => {
          let message = 'Failed to load Staff Roles list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
