<template>
    <ToolsLanding />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ToolsLanding from '@/components/tools/ToolsLanding.vue'
import NavigationService from '@/services/NavigationService'

@Options({
  components: {
    ToolsLanding
  }
})
export default class ToolsHome extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Tools', '')
  }
}
</script>
