<template>
  <div :class="classes">
    {{ label }}
  </div>
</template>
<script>
export default {
  props: ['label', 'headingClass'],
  computed: {
    classes () {
      let out = 'submission-form-section-heading'
      if (this.headingClass) {
        return `${out} ${this.headingClass}`
      }
      return out
    }
  }
}
</script>
