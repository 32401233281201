<template>
  <div class="row form-group">
    <label :class="labelClasses" for="lat">GPS</label>
    <div class="col-auto form-right-input">
      <input
        type="text"
        id="lat"
        :value="modelValue.lat"
        name="lat"
        style="width:95px;padding-left:4px;padding-right:4px;"
        placeholder="39.75394126"
        maxlength="12"
        :class="inputClasses"
        @keypress="checkInput"
        @input="updateLat($event.target.value)"
      />
      <span class="mx-2">/</span>
      <input
        type="text"
        id="long"
        :value="modelValue.long"
        name="long"
        style="width:95px;padding-left:4px;padding-right:4px;"
        placeholder="-122.602038"
        maxlength="64"
        :class="inputClasses"
        @keypress="checkInput"
        @input="updateLong($event.target.value)"
      />
      <a @click="getLocation()" class="ms-2"><fa icon="compass" :class="buttonClasses" /></a>
    </div>
  </div>

</template>
<script>
import HelpContent from '@/services/HelpContent'

export default {
  props: ['id', 'modelValue', 'labelClass', 'inputClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled'],
  emits: ['update:modelValue'],
  data () {
    return {
      newFormData: {},
      isLoading: false
    }
  },
  methods: {
    geoError (e) {
      const code = e && e.code ? 'Code: ' + e.code.toString() + '; ' : ''
      const reason = e && e.message ? 'Reason: ' + e.message : 'Reason: unknown error'
      HelpContent.setFlashMessage('Could not get GPS. ' + code + reason + '. Please try again.', true)
      this.isLoading = false
    },
    updateLocation (pos) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.lat = pos.coords.latitude
      this.newFormData.long = pos.coords.longitude
      this.$emit('update:modelValue', this.newFormData)
      HelpContent.setTimedFlashMessage('GPS Location Acquired and Entered')
      this.isLoading = false
    },
    getLocation () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      if (!navigator.geolocation) {
        return
      }
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      }
      navigator.geolocation.getCurrentPosition(this.updateLocation, this.geoError, options)
    },
    checkInput (e) {
      if (!e.key || e.metaKey) {
        return
      }
      if (!'-0123456789.'.includes(e.key)) {
        e.preventDefault()
      }
    },
    updateLat (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.lat = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updateLong (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.long = value
      this.$emit('update:modelValue', this.newFormData)
    }
  },
  computed: {
    buttonClasses () {
      if (this.isLoading) {
        return 'button-icon refresh-button refreshing'
      }
      return 'button-icon refresh-button'
    },
    inputClasses () {
      let out = 'form-input text-input'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
