<template>
  <div :class="flashClass" v-if="message">
    <div class="flash-inner d-flex mt-1">
      <div class="flash-bullet d-flex">
        <div class="flash-icon">
          <fa :icon="flashIcon" class="flash-icon-svg me-2" v-if="flashIcon" />
        </div>
        <div class="flash-message">
          <span>{{ message }}</span>
        </div>
      </div>
      <div class="flash-dismiss">
        <button v-if="!isTimedOpen && canClose" class="btn btn-short" :class="{'btn-red': isError, 'btn-gold': isWarning}" @click="dismiss()">
          <fa icon="xmark" class="svg-icon" :class="{'btn-red-color': isError, 'btn-gold-color': isWarning }" />
        </button>
        <div class="me-2 flash-circle" v-if="isTimedOpen">
          <a @click="dismiss()" class="close-time">
            {{ closeTime }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HelpContent from '@/services/HelpContent.ts'

export default {
  name: 'flash',
  props: ['icon'],
  data () {
    return {
      closeTime: 0
    }
  },
  methods: {
    dismiss () {
      HelpContent.startClose()
    },
    checkCloseTime () {
      if (this.isTimedOpen) {
        this.closeTime = HelpContent.closeTime() + 1
        setTimeout(this.checkCloseTime, 100)
      } else {
        this.closeTime = 0
      }
    }

  },
  watch: {
    isTimedOpen: {
      handler () {
        if (this.isTimedOpen) {
          this.checkCloseTime()
        }
      }
    }
  },
  computed: {
    canClose () {
      return HelpContent.canCloseFlash()
    },
    isTimedOpen () {
      return HelpContent.isTimedOpen()
    },
    message () {
      return HelpContent.getFlashMessage()
    },
    isError () {
      return HelpContent.isFlashError()
    },
    isWarning () {
      return HelpContent.isFlashWarning()
    },
    flashClass () {
      var classes = 'flash'
      if (this.isError) {
        classes += ' flash-error'
      } else if (this.isWarning) {
        classes += ' flash-warning'
      }
      if (HelpContent.isClosing() === true) {
        classes += ' flash-leave'
      }
      return classes
    },
    flashIcon () {
      if (this.icon) {
        if (this.icon === 'none') {
          return null
        }
        return this.icon
      }
      const icon = HelpContent.icon()
      if (icon) {
        if (icon === 'none') {
          return null
        }
        return icon
      }
      if (this.isError || this.isWarning) {
        return 'triangle-exclamation'
      }
      return 'check'
    }
  }
}
</script>
