<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import NavigationService from '@/services/NavigationService'

export default {
  name: 'base-device-component',
  extends: BaseFleetComponent,
  data () {
    return {
      lat: '',
      long: ''
    }
  },
  methods: {
    updateBreadcrumbs () {
      if (this.device && this.customer && this.site) {
        this.$state.currentSpace = 'fleet'
        NavigationService.setNavLevel4(
          'Fleet', this.device.customer, this.device.site, this.device.id,
          '#/fleet', '#/customers/' + this.customer.id, '#/sites/' + this.site.id, '')
      }
    },
    refreshNavLabels () {
      this.customerFromSite()
      this.updateBreadcrumbs()
    }
  }
}
</script>
