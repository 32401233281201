<template>
  <TabBar activeTabName="templates" />
  <TemplateMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import TemplateMainList from '@/components/notifications/TemplateMainList.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    TemplateMainList,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
</script>
