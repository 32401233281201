<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" sz="sm">

        <template v-slot:header>
        <fa icon="user-shield" class="map-icon me-2" style="width:25px" />
        {{ email }}
        </template>

        <template v-slot:body>

        <div class="p-3 font-reduce-1">
          <p>
            You are signed in.
          </p>
          <p>
            Copy your Bearer Token below to use it with the Agrology Backend API.
          </p>
          <p>
            Don't share your Bearer Token with anyone else!
          </p>
          <textarea class="d-none" readonly style="width:100%;height:280px;font-size:14px;" v-model="bearerToken" ref="bearerToken"></textarea>

        </div>
        </template>
        <template v-slot:footer>
          <div class="col-auto buttons-right">
            <button class="btn me-2" @click="logout()">Sign Out</button>
            <button class="btn" @click="tokenToClipboard()">Copy Token to Clipboard</button>
            <button class="btn btn-blue" @click.prevent="close()">Close</button>
          </div>
        </template>

    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import HelpContent from '@/services/HelpContent'
import auth from '@/app/auth'

export default {
  data () {
    return {
      isBlocking: false,
      message: ''
    }
  },
  components: { Modal },
  methods: {
    show () {
      this.message = ''
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    },
    logout () {
      auth.logout()
      this.close()
      window.location.href = '#'
    },
    tokenToClipboard () {
      const token = auth.auth.getSignInUserSession().getIdToken().jwtToken
      navigator.clipboard.writeText(token)
      HelpContent.setTimedFlashMessage('Token copied to Clipboard')
    }
  },
  computed: {
    bearerToken () {
      return auth.auth.getSignInUserSession().getIdToken().jwtToken
    },
    email () {
      return this.$state.email
    }
  }
}
</script>
