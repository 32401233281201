<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        Customer Preferences
      </template>

      <template v-slot:body>
        <form ref="customerForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">
          <SelectRow label="Pest & Disease" v-model="formData.pestDiseaseMonitoring" selectStyle="width: 239px;">
            <template v-slot:options>
              <option value="">-- Select Forecast --</option>
              <option :value="2">Ground Truth (Opt-in)</option>
              <option :value="1">Basic</option>
              <option :value="0">No P&D Forecast / Alerts</option>
            </template>
          </SelectRow>
        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateCustomer()" :disabled="isLoading">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/modals/Modal.vue'

import SelectRow from '@/components/forms/SelectRow.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'customer-add',
  props: ['customer'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      mode: '',
      isLoading: false
    }
  },
  computed: {
    headerText () {
      if (this.customer) {
        return 'Edit Customer'
      }
      return 'Add New Customer'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      this.formData = {}
      if (this.customer) {
        this.formData = {
          pestDiseaseMonitoring: this.customer.pestDiseaseMonitoring
        }
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateCustomer () {
      this.isLoading = true
      FleetDataService.updateCustomer(this.customer.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Customer Preferences Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Customer Preferences Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, SelectRow }
}
</script>
