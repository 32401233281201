<template>
  <TabBar activeTabName="templates" />
  <TemplateInfo />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import TemplateInfo from '@/components/notifications/TemplateInfo.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    TemplateInfo,
    TabBar
  }
})
export default class TemplateDetails extends Vue {}
</script>
