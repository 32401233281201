<template>
  <div class="d-flex input-row text-input-row align-items-center">
    <label :class="labelClasses">
      {{ label }}
      <slot name="labelIcon" />
    </label>
    <div>
      <div class="d-flex form-right-input align-items-center">
        <input
          type="text"
          :class="inputClassesYear"
          :maxlength="2"
          placeholder="YY"
          style="width: 34px; margin-right: 2.5px;"
          @keypress="checkInput"
          @input="yearChanged"
          :disabled="disabled"
        />
        <input
          type="text"
          :class="inputClassesMonth"
          :maxlength="2"
          placeholder="MM"
          style="width: 39px; margin-right: 2.5px;"
          @keypress="checkInput"
          @input="monthChanged"
          :disabled="disabled"
        />
        <input
          type="text"
          :class="inputClassesDay"
          :maxlength="2"
          placeholder="DD"
          style="width: 36px; margin-right: 9.5px;"
          @keypress="checkInput"
          @input="dayChanged"
          :disabled="disabled"
        />
        <input
          type="text"
          :class="inputClassesHour"
          :maxlength="2"
          placeholder="HH"
          style="width: 36px; margin-right: 1.5px;"
          @keypress="checkInput"
          @input="hourChanged"
          :disabled="disabled"
        />
        <span class="subtle">:</span>
        <input
          type="text"
          :class="inputClassesMinute"
          :maxlength="2"
          placeholder="mm"
          style="width: 39px; margin-left: 1.5px;"
          @keypress="checkInput"
          @input="minuteChanged"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
  <div v-if="error" class="form-row-error">
    {{ error }}
  </div>
</template>
<script>
export default {
  props: ['label', 'modelValue', 'labelClass', 'inputClass', 'required',
    'disabled', 'valueChangedFunc'],
  emits: ['update:modelValue'],
  data () {
    return {
      year: undefined,
      month: undefined,
      day: undefined,
      hour: undefined,
      minute: undefined,
      //
      yearError: undefined,
      monthError: undefined,
      dayError: undefined,
      hourError: undefined,
      minuteError: undefined,
    }
  },
  methods: {
    yearChanged (e) {
      const minYear = 21
      const maxYear = (new Date()).getUTCFullYear() - 2000

      if (!e || !e.target || !e.target.value) {
        this.year = undefined
        return
      }
      let sVal = e.target.value
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const year = parseInt(sVal)
      if (isNaN(year) || year < minYear || year > maxYear ) {
        this.yearError = `Year must be between ${minYear} and ${maxYear}`
        this.year = undefined
        return
      }
      this.year = year
      this.yearError = undefined
    },
    monthChanged (e) {
      if (!e || !e.target || !e.target.value) {
        this.month = undefined
        return
      }
      let sVal = e.target.value
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const month = parseInt(sVal)
      if (isNaN(month) || month < 1 || month > 12 ) {
        this.monthError = 'Month must be between 1 and 12'
        this.month = undefined
        return
      }
      this.month = month
      this.monthError = undefined
      if (this.day) {
        this.validateDay(this.day)
      }
    },
    dayChanged (e) {
      if (!e || !e.target || !e.target.value) {
        this.day = undefined
        return
      }
      let sVal = e.target.value
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      this.validateDay(parseInt(sVal))
    },
    validateDay(day) {
      let maxDay = 31
      if (this.month) {
        if (this.month === 2) { 
          maxDay = 28
        } else if (![1, 3, 5, 7, 8, 10, 12].includes(this.month)) {
          maxDay = 30
        }
      }
      if (isNaN(day) || day < 1 || day > maxDay ) {
        this.dayError = `Day must be between 1 and ${maxDay}`
        this.day = undefined
        return
      }
      this.day = day
      this.dayError = undefined
    },
    hourChanged (e) {
      if (!e || !e.target || !e.target.value) {
        this.hour = undefined
        return
      }
      let sVal = e.target.value
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const hour = parseInt(sVal)
      if (isNaN(hour) || hour < 0 || hour > 23 ) {
        this.hourError = 'Hour must be between 0 and 23'
        this.hour = undefined
        return
      }
      this.hour = hour
      this.hourError = undefined
    },
    minuteChanged (e) {
      if (!e || !e.target || !e.target.value) {
        this.minute = undefined
        return
      }
      let sVal = e.target.value
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const minute = parseInt(sVal)
      if (isNaN(minute) || minute < 0 || minute > 59 ) {
        this.minuteError = 'Minute must be between 0 and 59'
        this.minute = undefined
        return
      }
      this.minute = minute
      this.minuteError = undefined
    },
    checkInput (e) {
      if (!e.key) {
        return
      }
      if (e.metaKey || e.ctrlKey) {
        return
      }
      if (!this.dataTypeCharacters.includes(e.key)) {
        e.preventDefault()
      }
    }
  },
  watch: {
    epochTime: {
      handler () {
        this.$emit('update:modelValue', this.epochTime)
        if (this.valueChangedFunc) {
         this.valueChangedFunc({})
        }
      }
    }
  },
  computed: {
    error () {
      if (this.yearError) {
        return this.yearError
      }
      if (this.monthError) {
        return this.monthError
      }
      if (this.dayError) {
        return this.dayError
      }
      if (this.hourError) {
        return this.hourError
      }
      if (this.minuteError) {
        return this.minuteError
      }
      return undefined
    },
    epochTime () {
      if (this.year === undefined || this.month === undefined ||
      this.day === undefined || this.hour === undefined ||
      this.minute === undefined) {
        return undefined
      }
      const year = this.year.toString().padStart(2, '0')
      const month = this.month.toString().padStart(2, '0')
      const day = this.day.toString().padStart(2, '0')
      const hour = this.hour.toString().padStart(2, '0')
      const minute = this.minute.toString().padStart(2, '0')
      const timestamp = Date.parse(`20${year}-${month}-${day}T${hour}:${minute}:00.000Z`) / 1000
      return timestamp
    },
    dataTypeCharacters (){
      return '0123456789'
    },
    inputClassesYear (){
      let out = this.inputClasses
      if (this.yearError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesMonth (){
      let out = this.inputClasses
      if (this.monthError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesDay (){
      let out = this.inputClasses
      if (this.dayError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesHour (){
      let out = this.inputClasses
      if (this.hourError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesMinute (){
      let out = this.inputClasses
      if (this.minuteError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClasses (){
      let out = 'form-input text-input ph-no-italic'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses (){
      let out = 'input-row-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
