import axios from 'axios'
import auth from './app/auth'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(function (config) {
  if (!config.url?.endsWith('/userInfo') && !config.url?.includes('/hub-index.json')) {
    const token = auth.auth.getSignInUserSession().getIdToken().jwtToken
    if (!token) {
      return Promise.reject(new Error('not logged in'))
    }
    config.headers.Authorization = 'Bearer ' + token
  }
  return Promise.resolve(config)
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

export default instance
