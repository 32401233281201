<template>
  <TabBar activeTabName="manufacturers" />
  <div class="row justify-content-center px-0 mx-0">
    <ManufacturerAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ManufacturerAddForm from '@/components/fleet/ManufacturerAddForm.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    ManufacturerAddForm,
    TabBar
  }
})
export default class AddManufacturer extends Vue {}
</script>
