<template>
  <Modal ref="editModal" :customer="customer" />
  <RemoveModal ref="removeModal" :customer="customer" :domain="domain" />
  <InfoPane
    class="dt-info-pane-sm"
    title="Internet Domains"
    v-if="customer"
    :actionFunc="showEditModal"
    actionLabel="Add Domain"
    actionIcon="plus"
  >
    <template v-slot:info>
      <ul v-if="customer" class="info-pane-list">
        <li class="info-pane-list-item" v-if="domains.length == 0">
          No Domains for this Customer
        </li>
        <li class="info-pane-list-item d-flex align-items-center" v-for="domain in domains" :key="domain">
          <div class="col-auto" v-if="domain">
            {{ domain }}
          </div>
          <div class="col d-flex justify-content-end" v-if="domain">
            <span>
              <a @click="removeDomain(domain)">
                <fa icon="trash-can" class="button-icon me-1" style="margin-top:0px;width:16px;" />
              </a>
            </span>
          </div>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/fleet/ModalAddCustomerDomain.vue'
import RemoveModal from '@/components/fleet/ModalRemoveCustomerDomain.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, Modal, RemoveModal },
  props: ['customer'],
  data () {
    return {
      domain: ''
    }
  },
  computed: {
    domains () {
      if (!this.customer || !this.customer.domains) {
        return []
      }
      return this.customer.domains.split(',')
    }
  },
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    },
    removeDomain (domain) {
      this.domain = domain
      this.$nextTick(() => {
        this.showRemoveModal()
      })
    },
    showRemoveModal () {
      this.$refs.removeModal.show()
    }
  }
}
</script>
