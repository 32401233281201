<template>
  <TabBar activeTabName="misc" />
  <div class="row mx-0 px-0">
    <div class="col mx-auto px-0 mb-3 pb-2">
      <ChambersMainList />
    </div>
    <div class="d-none d-md-block col-auto mx-3 p-0" />
    <div class="col mx-auto px-0">
      <VentsMainList />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ChambersMainList from '@/components/fleet/ChambersMainList.vue'
import VentsMainList from '@/components/fleet/VentsMainList.vue'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    ChambersMainList,
    VentsMainList,
    TabBar
  }
})
export default class ChamberConfigsList extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Chamber & Vent Configs', '#/fleet', '')
  }
}
</script>
