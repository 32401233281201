<template>
  <div :class="menuClass" @click="showMenu">
    <div style="display:flex">
      <div class="fleet-map-menu">
        <fa icon="bars" :class="{'fleet-map-menu-icon-open': isOpen}"/>
      </div>
      <span class="fleet-map-title" v-if="isOpen">Map Settings</span>
    </div>
    <hr v-if="isOpen" />
    <div v-if="isOpen" class="map-menu-body">
      <ul mt-0 mb-0>
        <li class="selectable py-1" @click="showAllNodes">
          <label class="context-menu-check-area">
            <fa v-if="show === 'all' || !show" icon="check" alt="Showing All Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show All Nodes
        </li>
        <li class="selectable pt-1 pb-2" @click="showProblemNodes">
          <label class="context-menu-check-area">
            <fa v-if="show == 'problem'" icon="check" alt="Showing Problem Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show Nodes with Problems
        </li>
        <li class="selectable pt-1 pb-2 add-border-bottom" @click="hideNodes">
          <label class="context-menu-check-area">
            <fa v-if="show === 'none'" icon="check" alt="Showing Problem Nodes" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Hide Node Pins
        </li>
        <li class="selectable py-1" @click="showAllGateways">
          <label class="context-menu-check-area">
            <fa v-if="showGW === 'all' || !showGW" icon="check" alt="Showing All Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show All Gateways
        </li>
        <li class="selectable pt-1 pb-2" @click="showProblemGateways">
          <label class="context-menu-check-area">
            <fa v-if="showGW == 'problem'" icon="check" alt="Showing Problem Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Show Gateways w/ Problems
        </li>
        <li class="selectable pt-1 pb-2" @click="hideGateways">
          <label class="context-menu-check-area">
            <fa v-if="showGW === 'none'" icon="check" alt="Showing Problem Gateways" class="button-icon" style="height: 16px; padding: 0px;" />
          </label>
          Hide Gateway Pins
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FleetMapMenu',
  emits: ['updateSettings'],
  data () {
    return {
      isOpen: false,
      show: '',
      showGW: ''
    }
  },
  computed: {
    menuClass () {
      if (this.isOpen) {
        return 'fleet-map-menu-container fleet-map-menu-open'
      }
      return 'fleet-map-menu-click'
    }
  },
  methods: {
    showMenu () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        const show = localStorage.getItem('prefs.fleetmap-node-scope')
        const showGW = localStorage.getItem('prefs.fleetmap-gw-scope')
        if (!show) {
          this.show = 'all'
        } else {
          this.show = show
        }
        if (!showGW) {
          this.showGW = 'all'
        } else {
          this.showGW = showGW
        }
      }
    },
    showAllNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'all')
      this.$emit('updateSettings')
    },
    showProblemNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'problem')
      this.$emit('updateSettings')
    },
    hideNodes () {
      localStorage.setItem('prefs.fleetmap-node-scope', 'none')
      this.$emit('updateSettings')
    },
    showAllGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'all')
      this.$emit('updateSettings')
    },
    showProblemGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'problem')
      this.$emit('updateSettings')
    },
    hideGateways () {
      localStorage.setItem('prefs.fleetmap-gw-scope', 'none')
      this.$emit('updateSettings')
    }
  }
}
</script>
