<template>
  <TabBar activeTabName="devices" />
  <div class="row justify-content-center px-0 mx-0">
    <DeviceInfo />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import DeviceInfo from '@/components/fleet/DeviceInfo.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    DeviceInfo,
    TabBar
  }
})
export default class DeviceDetails extends Vue {}
</script>
