<template>
  <div class="tag-bubble-group align-items-center">
    <TagBubble
      v-for="(tb, i) in tagBubbles"
      :key="i"
      :label="tb.label"
      :clickClose="removeBubble"
    />
    <TagBubble
      v-if="allowAddTagBubble && !isAdding"
      :label="addNewTagBubbleLabel"
      class="px-2"
      :closeDisabled="true"
      :clickButton="startAddNew"
    />
    <TagBubbleInput
      v-if="isAdding"
      :placeholder="placeholder"
      :closer="cancelAddNew"
      :adder="addTagBubbleFunc"
      :groupName="groupName"
    />
    <div v-if="!isAdding && (!tagBubbles || tagBubbles.length === 0) && emptyGroupNote" class="subtle font-reduce-1 ms-2">
      <span>
        {{ emptyGroupNote }}
      </span>
    </div>
  </div>
</template>
<script>
import TagBubble from '@/components/controls/TagBubble.vue'
import TagBubbleInput from '@/components/controls/TagBubbleInput.vue'

export default {
  name: 'tag-bubble-group-control',
  props: ['allowAddTagBubble', 'addTagBubbleLabel', 'addTagBubbleFunc', 'removeTagBubbleFunc', 'tagBubbles', 'itemType', 'newItemPlaceholder', 'groupName', 'emptyGroupNote', 'isAddingFunc'],
  components: { TagBubble, TagBubbleInput },
  data () {
    return {
      isAdding: false
    }
  },
  methods: {
    removeBubble (input) {
      if (this.removeTagBubbleFunc) {
        this.removeTagBubbleFunc(this.groupName, input)
      }
    },
    startAddNew () {
      this.isAdding = true
      if (this.isAddingFunc) {
        this.isAddingFunc(true)
      }
    },
    cancelAddNew () {
      this.isAdding = false
      if (this.isAddingFunc) {
        this.isAddingFunc(false)
      }
    }
  },
  computed: {
    placeholder () {
      if (this.newItemPlaceholder) {
        return this.newItemPlaceholder
      }
      if (this.itemType) {
        return 'New ' + this.itemType
      }
      return 'New Item'
    },
    addNewTagBubbleLabel () {
      if (this.addTagBubbleLabel) {
        return this.addTagBubbleLabel
      }
      return '+'
    }
  }
}
</script>
