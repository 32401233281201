import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center px-0 mx-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_CustomerInfo = _resolveComponent("CustomerInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabBar, { activeTabName: "customers" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CustomerInfo)
    ])
  ], 64))
}