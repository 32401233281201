<template>
  <TabBar activeTabName="alerts" />
  <div class="row justify-content-center px-0 mx-0">
    <AlertInfo />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import AlertInfo from '@/components/notifications/AlertInfo.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    AlertInfo,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
</script>
