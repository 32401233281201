<template>
  <div class="row justify-content-center mx-0 p-0">
    <div class="col-auto p-0">
      <JobInfoPane :job="job" v-if="job" />
      <JobSchedulesList :id="id" :job="job" v-if="job" />
    </div>
    <div class="d-none d-md-block col mx-3 p-0" style="width:10px;max-width:10px;" />
    <div class="col-auto p-0">
      <JobRunsList :job="job" :runID="this.runID" v-if="job" />
    </div>
  </div>
</template>
<script>
import NavigationService from '@/services/NavigationService'
import ModelingDataService from '@/services/ModelingDataService'
import JobInfoPane from '@/components/modeling/JobInfoPane.vue'
import JobSchedulesList from '@/components/modeling/JobSchedulesList.vue'
import JobRunsList from '@/components/modeling/JobRunsList.vue'
import HelpContent from '@/services/HelpContent'
import Reloadables from '@/services/reloadables'

export default {
  components: { JobInfoPane, JobSchedulesList, JobRunsList },
  data () {
    return {
      id: '',
      runID: this.$route.params.runID,
      job: null
    }
  },
  emits: ['register-reloadable'],
  methods: {
    loadJobInfo () {
      ModelingDataService.retrieveJob(this.id)
        .then(result => {
          this.job = result.data
        })
        .catch(e => {
          let message = 'Failed to get Job Info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    NavigationService.setNavLevel3('Modeling', 'jobs', this.id, '#/modeling', '', '')
    this.loadJobInfo()
    Reloadables.registerReloadable('job-info', this.loadJobInfo)
  },
  unmounted () {
    Reloadables.unregisterReloadable('job-info')
  }
}
</script>
