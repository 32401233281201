<template>
  <div>
    <Modal ref="modal" :job="job" sz="sm" />
    <DataTable
      class="dt-modeling-entries"
      title="Job Schedule"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No Schedule Entries for this Job yet"
      :actionFunc="showAddModal"
      actionIcon="plus"
      itemType="Entry"
    >
      <template v-slot:filter-pillbox>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/modeling/ModalAddScheduleEntry.vue'
import ModelingDataService from '@/services/ModelingDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  components: { DataTable, Modal },
  props: ['job'],
  data () {
    return {
      entries: [],
      cols: [
        { text: 'Entry Type' },
        { text: 'Timing' },
        { text: '' }
      ],
      isLoading: true
    }
  },
  name: 'modeling-job-schedule',
  computed: {
    rows () {
      const out = []
      if (this.entries && this.entries.length > 0) {
        for (const i in this.entries) {
          out.push([
            { value: this.entries[i].entryType, class: 'capitalize' },
            { value: this.offsetHuman(this.entries[i].entryType, this.entries[i].offsetSecs) },
            { click: this.removeEntry, i: i, icon: 'trash-can', class: 'icon-col-end' }
          ])
        }
      }
      return out
    }
  },
  methods: {
    hourString (h, m) {
      let am = 'AM'
      if (h > 12) {
        h -= 12
        am = 'PM'
      } else if (h === 12) {
        am = 'PM'
      } else if (h === 0) {
        h = 12
      }
      return `${h}:${m} ${am}`
    },
    offsetHuman (t, i) {
      if (t === 'hourly') {
        if (i === 0) {
          return 'at the Top of the Hour'
        }
        if (i === 60) {
          return 'at 1 minute past the Hour'
        }
        return `at ${i / 60} minutes past the Hour`
      }
      if (t === 'daily') {
        const hours = Math.floor(i / 3600)
        const mins = String((i - (hours * 3600)) / 60).padStart(2, '0')
        return `at ${this.hourString(hours, mins)} UTC`
      }
      if (t === 'weekly') {
        const day = Math.floor(i / 86400)
        const hours = Math.floor((i - (day * 86400)) / 3600)
        const mins = String((i - (day * 86400) - (hours * 3600)) / 60).padStart(2, '0')
        let dayName = ''
        if (day === 0) {
          dayName = 'Mondays'
        } else if (day === 1) {
          dayName = 'Tuesdays'
        } else if (day === 2) {
          dayName = 'Wednesdays'
        } else if (day === 3) {
          dayName = 'Thursdays'
        } else if (day === 4) {
          dayName = 'Fridays'
        } else if (day === 5) {
          dayName = 'Saturdays'
        } else if (day === 6) {
          dayName = 'Sundays'
        }
        return `on ${dayName} at ${this.hourString(hours, mins)} UTC`
      }
      if (t === 'monthly') {
        const day = Math.floor(i / 86400)
        const hours = Math.floor((i - (day * 86400)) / 3600)
        const mins = String(Math.floor((i - (day * 86400) - (hours * 3600)) / 60)).padStart(2, '0')
        return `on day ${day} at ${this.hourString(hours, mins)} UTC`
      }
      if (t === 'one-time') {
        const date = new Date(i * 1000)
        const options = {
          timeZone: 'Etc/UTC',
          hour12: true,
          hour: 'numeric',
          minute: '2-digit',
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        }
        return `on ${date.toLocaleTimeString('en-US', options).replaceAll(', ', ' at ')} UTC`
      }
      return i
    },
    removeEntry (i) {
      ModelingDataService.deleteScheduleEntry(this.job.name, this.entries[i].entryType, this.entries[i].offsetSecs)
        .then(() => {
          HelpContent.setTimedFlashMessage('Schedule Entry Removed')
          Reloadables.requestReload()
        })
        .catch(e => {
          let detail = ''
          if (e.response && e.response.data && e.response.data.message) {
            detail = ' - ' + e.response.data.message
          }
          HelpContent.setFlashMessage('Schedule Entry Removal Failed' + detail, true)
        })
    },
    showAddModal () {
      this.$refs.modal.show()
    },
    loadJobs () {
      this.isLoading = true
      ModelingDataService.listJobSchedule(this.job.name)
        .then(result => {
          this.entries = result.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Job Schedule'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('jobs-schedules-list', this.loadJobs)
    this.loadJobs()
  },
  unmounted () {
    Reloadables.unregisterReloadable('jobs-schedules-list')
  }
}
</script>
