import { reactive } from 'vue'

const modelingJobsMain = {
  title: 'ML Modeling Jobs Management',
  copy: [
    'This space is used to manage ML Modeling Jobs, including the scripts that are run in each job, their schedules, and their outputs.',
    'h4.Jobs',
    'A Job is effectively a user-defined script that runs in a container the Cloud on a regular schedule. Jobs support these scripting languages: Python 3.x',
    'h4.Job Versions',
    'Each Job has one or more Versions, which accrue over time as the script evolves. A Job is configured with one Active Version at a time, which is used by the Job when it runs.',
    'When the script for Job a changes, create a new Version that includes the updated script. After the new Version is created, you can execute test runs and update the script until it works correctly in the cloud.',
    'Once the Backend has verified the script integrates properly, and you are satisfied with its operation, then you can Publish the Version. Only Published Versions can be marked as Active; but once a Version is Published, the script and container configurations are locked, and a new Version must be created to introduce new script changes.',
    'h4.Job Schedules',
    'A Job\'s Schedule consists of Schedule Entries. A Schedule Entry is a point in time when the Job should run, represented as either a specific absolute time in the future, or relative time repeating on a schedule.',
    'To increase the frequency of a run, add multiple Entries. For example, if you want to run a Job every week on both Sunday and Wednesday, create two Schedule Entries (one for each day).',
    'h4.Job Runs',
    'During and after a Job Run, you can view information about it, including it\'s return code, logs, and result artifacts to assist with troubleshooting and quality assurance.'
  ]
}

const helpContentLibrary = {
  'modeling-jobs-main': modelingJobsMain
}

export const helpState = reactive({
  activeHelpContent: '',
  flashMessage: '',
  isFlashError: false,
  isFlashWarning: false,
  isClosing: false,
  isTimedOpen: false,
  canClose: true,
  closesAt: 0,
  icon: ''
})

const funcs = {
  dismiss () {
    helpState.isTimedOpen = false
    helpState.isFlashError = false
    helpState.isFlashWarning = false
    helpState.flashMessage = ''
    helpState.isClosing = false
    helpState.canClose = true
  }
}

class HelpContent {
  setFlashMessage (message: string, isError = false, isWarning = false, canClose = true, icon = '') {
    helpState.flashMessage = message
    helpState.isFlashError = isError
    helpState.isFlashWarning = isWarning
    helpState.canClose = canClose
    helpState.icon = icon
  }

  getFlashMessage () {
    return helpState.flashMessage
  }

  isFlashError () {
    return helpState.isFlashError
  }

  isFlashWarning () {
    return helpState.isFlashWarning
  }

  canCloseFlash () {
    return helpState.canClose
  }

  dismiss () {
    helpState.canClose = true
    funcs.dismiss()
  }

  startClose () {
    helpState.isClosing = true
    helpState.canClose = true
    setTimeout(funcs.dismiss, 370)
  }

  isClosing () {
    return helpState.isClosing
  }

  isTimedOpen () {
    return helpState.isTimedOpen
  }

  closeTime () {
    const d = new Date()
    return Math.floor((helpState.closesAt - d.getTime()) / 1000)
  }

  icon () {
    return helpState.icon
  }

  setTimedFlashMessage (message: string, duration = 2999) {
    helpState.isFlashError = false
    helpState.isFlashWarning = false
    helpState.canClose = true
    helpState.flashMessage = message
    helpState.isTimedOpen = true
    helpState.icon = ''
    const d = new Date()
    helpState.closesAt = d.getTime() + duration
    setTimeout(this.startClose, duration)
  }
}

export default new HelpContent()
