export interface Node {
  coords: number[]
  siteId: string
  siteName: string
  id: string
  name: string
  status: string
  statusColor: string
  devices: Devices
}

export interface Device {
  id: string
  status: string
  statusDetail: string
}

export type Devices = Device[]
export interface NodeLookup {
  [key: string]: Node
}

export const NewDevice = (): Device => {
  return {} as Device
}

export const NewDevices = (): Devices => {
  return [] as Devices
}

export const NewNode = (): Node => {
  return {} as Node
}

export const NewNodeLookup = (): NodeLookup => {
  return {} as NodeLookup
}
