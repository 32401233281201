<script>
import BaseComponent from '@/components/BaseComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import HelpContent from '@/services/HelpContent'
import auth from '@/app/auth'

export default {
  name: 'base-notifications-component',
  extends: BaseComponent,
  data () {
    return {
      alert: null,
      alerts: [],
      thresholds: [],
      threshold: null,
      thresholdName: '',
      templates: [],
      agtemplate: null,
      templateName: '',
      alertID: '',
      apnSubscriptions: [],
      apnSubscription: null
    }
  },
  methods: {
    retrieveRecentAlerts (f) {
      NotificationsDataService.getRecentAlerts()
        .then(response => {
          this.alerts = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllThresholds (f) {
      NotificationsDataService.getAllThresholds()
        .then(response => {
          this.thresholds = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllTemplates (f) {
      NotificationsDataService.getAllTemplates()
        .then(response => {
          const templates = response.data
          templates.sort((a, b) => {
            if (a.description < b.description) return -1
            if (a.description > b.description) return 1
            return 0
          })
          this.templates = templates
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveFilteredApnSubscriptions (filterBy, filterOn) {
      this.apnSubscriptions = []
      NotificationsDataService.getApnSubscriptionsByFilter(filterBy, filterOn)
        .then(response => {
          this.apnSubscriptions = response.data
        })
        .catch(e => {
          let message = 'Failed to load subscriptions List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    retrieveApnSubscriptionByToken (t, b, s, f) {
      this.apnSubscriptions = []
      NotificationsDataService.getApnSubscriptionByDeviceToken(t, b, s)
        .then(response => {
          this.apnSubscriptions = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    setNavNotif3 (label) {
      this.$state.currentSpace = 'notifications'
      NavigationService.setNavLevel3('Notifications', label, '', '#/notifications')
    },
    setNavSub (label) {
      this.$state.currentSpace = 'notifications'
      NavigationService.setNavLevel4('Notifications', 'subscriptions', label, '', '#/notifications', '#subscriptions')
    },
    setNavThreshold (label) {
      this.$state.currentSpace = 'notifications'
      NavigationService.setNavLevel4('Notifications', 'thresholds', label, '', '#/notifications', '#thresholds')
    },
    setNavTemplate (label) {
      this.$state.currentSpace = 'notifications'
      NavigationService.setNavLevel4('Notifications', 'templates', label, '', '#/notifications', '#/templates')
    },
    setNavAlert (label) {
      this.$state.currentSpace = 'notifications'
      NavigationService.setNavLevel4('Notifications', 'alerts', label, '', '#/notifications', '#/alerts')
    }
  },
  computed: {
    hasAlertsPerm () {
      return auth.userHasPrivilegeSubject(['alerts'])
    },
    hasTemplatesPerm () {
      return auth.userHasPrivilegeSubject(['templates'])
    },
    hasThresholdsPerm () {
      return auth.userHasPrivilegeSubject(['thresholds'])
    },
    hasSubscriptionsPerm () {
      return auth.userHasPrivilegeSubject(['subscriptions'])
    },
    hasExclusionZonesPerm () {
      return auth.userHasPrivilegeSubject(['notif-exclusion-zones'])
    },
    activeAlerts () {
      var active = []
      for (const alert of this.alerts) {
        if (alert.status === 'resolved') {
          continue
        }
        active.push(alert)
      }
      return active
    },
    resolvedAlerts () {
      var resolved = []
      for (const alert of this.alerts) {
        if (alert.status === 'resolved') {
          resolved.push(alert)
        }
      }
      return resolved
    },
    activeThresholds () {
      if (this.$state.showInactiveNodes) {
        return this.thresholds
      }
      var active = []
      for (const threshold of this.thresholds) {
        if (!threshold.enabled === true) {
          continue
        }
        active.push(threshold)
      }
      return active
    },
    pushTemplates () {
      var templates = []
      for (const template of this.templates) {
        if (template.medium === 'push') {
          templates.push(template)
        }
      }
      templates.sort((a, b) => {
        if (a.description < b.description) return -1
        if (a.description > b.description) return 1
        return 0
      })
      return templates
    }
  }
}
</script>
