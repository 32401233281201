<template>
  <div class="col-auto component-pane mx-0 px-0">
    <h5>Add Gateway</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form gateway-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="id">GW ID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="gateway-name"
            maxlength="28"
          />
        </div>
      </div>

      <div id="v-model-sites" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="sites">Site</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="siteName" id="sites" style="width: 225px" required>
            <option v-for="site in activeSites" :value="site.id" :key="site.id">
              {{ site.displayName }}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select class="form-select" id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header width-115" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Gateway that Agrology may find useful in the future."
          />
        </div>
      </div>

      <hr />

      <div id="v-model-manufacturers" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="manufacturers">Manuf:</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="manufacturer" id="manufacturers" style="width: 225px" required>
            <option v-for="manufacturer in activeManufacturers" :value="manufacturer.id" :key="manufacturer.id">
              {{ manufacturer.id }}
            </option>
          </select>
        </div>
      </div>

      <div id="v-model-mfmodels" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="mfmodels">Model</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="model" id="mfmodels" style="width: 225px" required>
            <option v-for="model in models" :value="model" :key="model">
              {{ model }}
            </option>
          </select>
        </div>
      </div>

      <div id="v-model-powers" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="powers">Power</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="powerSource" id="powers" style="width: 225px" required>
            <PowerSourceOptions />
          </select>
        </div>
      </div>

      <hr />

      <div id="v-model-networkServers" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="networkServers">LoRa Server:</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="networkServer" id="networkServers" style="width: 225px" required>
            <option v-for="networkServer in activeNetworkServers" :value="networkServer.id" :key="networkServer.id">
              {{ networkServer.id }}
            </option>
          </select>
        </div>
      </div>

      <div id="v-model-networkAccesses" class="row form-group">
        <label class="col-form-label form-left-header width-115 label-required" for="networkAccesses">Connection</label>
        <div class="col-auto form-right-input">
          <select class="form-select" v-model="networkAccess" id="networkAccesses" style="width: 225px" required>
            <NetworkConnections />
          </select>
        </div>
      </div>

        <div id="v-model-carrier" class="row form-group" v-if="networkAccess == 'cellular'">
          <label class="col-form-label form-left-header width-115" for="carrier">Carrier</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="carrier" id="carrier" style="width: 225px">
              <CarrierOptions />
            </select>
          </div>
        </div>

      <div class="row form-group" v-if="networkAccess == 'cellular'">
        <label class="col-form-label form-left-header width-115" for="imei">IMEI</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="imei"
            v-model="imei"
            name="imei"
            style="width:180px;"
            placeholder="00000000000000"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group" v-if="networkAccess == 'cellular'">
        <label class="col-form-label form-left-header width-115" for="iccid">ICCID</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="iccid"
            v-model="iccid"
            name="iccid"
            style="width:220px;"
            placeholder="00000000000000"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row-auto mx-0 px-0 form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn" :disabled="isLoading">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import PowerSourceOptions from '@/components/selectOptions/PowerSourceOptions.vue'
import NetworkConnections from '@/components/selectOptions/NetworkConnections.vue'
import NavigationService from '@/services/NavigationService'
import HelpContent from '@/services/HelpContent'
import CarrierOptions from '@/components/selectOptions/CarrierOptions.vue'

export default {
  name: 'gateway-add',
  extends: BaseFleetComponent,
  data () {
    return {
      id: '',
      status: 'active',
      notes: '',
      siteName: '',
      powerSource: '',
      manufacturer: '',
      model: '',
      imei: '',
      iccid: '',
      networkAccess: '',
      networkServer: '',
      carrier: ''
    }
  },
  methods: {
    handleSubmit () {
      var data = {
        id: this.id,
        status: this.status,
        notes: this.notes,
        site: this.siteName,
        powerSource: this.powerSource,
        manufacturer: this.manufacturer,
        model: this.model,
        imei: this.imei,
        iccid: this.iccid,
        networkAccess: this.networkAccess,
        networkServer: this.networkServer,
        carrier: this.carrier
      }
      this.isLoading = true
      FleetDataService.createGateway(data)
        .then(() => {
          HelpContent.setFlashMessage('Gateway Created Successfully')
          this.$router.push({ name: 'gateway-details', params: { id: this.id } })
        })
        .catch(e => {
          let message = 'Gateway Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Gateway Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.id = ''
      this.status = 'active'
      this.notes = ''
      this.siteName = ''
      this.powerSource = ''
      this.manufacturer = ''
      this.model = ''
      this.imei = ''
      this.iccid = ''
      this.networkAccess = ''
      this.networkServer = ''
      this.carrier = ''
    }
  },
  mounted () {
    this.reset()
    this.retrieveAllNetworkServers(this.retrieveAllSites(this.retrieveAllManufacturers()))
    if (this.$state.creationName) {
      this.siteName = this.$state.creationName
    }
  },
  created () {
    NavigationService.setNavLevel3('Fleet', 'gateways', 'Add Gateway', '#/fleet', '#/gateways', '')
  },
  computed: {
    models () {
      if (this.manufacturer) {
        for (var m of this.activeManufacturers) {
          if (m.id === this.manufacturer) {
            return m.models.split(',')
          }
        }
      }
      return {}
    }
  },
  components: { GeneralStatusOptions, PowerSourceOptions, CarrierOptions, NetworkConnections }
}
</script>

<style>
</style>
