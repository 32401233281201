<template>
  <div class="d-flex">
    <div class="qr-code-box text-center pt-3" :style="bgStyle">
      <QR v-if="renderValue" :value="renderValue" :size="108" :class="className" render-as="svg" :level="renderLevel" />
      <br v-if="!minimal"/>
      <label class="font-reduce-2 m-2" :class="{'qr-code-bw': blackAndWhite}" v-if="!minimal">{{ value }}</label>
    </div>
    <div class="pt-3 ms-1" v-if="!minimal">

      <div class="btn-group dt-pillbox me-3 mb-3 mt-2" role="group" aria-label="BlackAndWhite">
        <input type="radio" class="btn-check" :value="false" name="blackAndWhite"
          id="blackAndWhite-f" v-model="blackAndWhite" autocomplete="off" />
        <label class="btn btn-outline-primary" for="blackAndWhite-f">Themed</label>
        <input type="radio" class="btn-check" :value="true" name="blackAndWhite"
          id="blackAndWhite-t" v-model="blackAndWhite" autocomplete="off" />
        <label class="btn btn-outline-primary" for="blackAndWhite-t">B &amp; W</label>
      </div>

      <br />

      <label class="me-2 font-reduce-2">Quality</label>
      <br />
      <div class="btn-group dt-pillbox me-3" role="group" aria-label="RedundancyLevel">
        <input type="radio" class="btn-check" value="L" name="levelValue"
          id="levelValue-l" v-model="levelValue" autocomplete="off" />
        <label class="btn btn-outline-primary" for="levelValue-l">L</label>
        <input type="radio" class="btn-check" value="M" name="levelValue"
          id="levelValue-m" v-model="levelValue" autocomplete="off" />
        <label class="btn btn-outline-primary" for="levelValue-m">M</label>
        <input type="radio" class="btn-check" value="Q" name="levelValue"
          id="levelValue-q" v-model="levelValue" autocomplete="off" />
        <label class="btn btn-outline-primary" for="levelValue-q">Q</label>
        <input type="radio" class="btn-check" value="H" name="levelValue"
          id="levelValue-h" v-model="levelValue" autocomplete="off" />
        <label class="btn btn-outline-primary" for="levelValue-h">H</label>
      </div>

    </div>
  </div>
  <TextInputRow v-if="!value" v-model="inputValue" inputStyle="width:350px" style="width:150px" />
</template>

<script>
import QR from '@/app/qrcode.js'
import TextInputRow from '@/components/forms/TextInputRow.vue'
// import HelpContent from '@/services/HelpContent'

export default {
  data () {
    return {
      blackAndWhite: false,
      inputValue: 'Hello World',
      levelValue: 'H',
      codeSize: 108
    }
  },
  props: ['value', 'title', 'level', 'size', 'useBlackAndWhite', 'minimal'],
  components: { QR, TextInputRow },
  computed: {
    bgStyle () {
      if (!this.blackAndWhite) {
        return null
      }
      return 'background-color:white;'
    },
    className () {
      if (!this.blackAndWhite) {
        return 'qr-code-graphic'
      }
      return 'qr-code-bw'
    },
    renderValue () {
      if (this.value) {
        return this.value
      }
      return this.inputValue
    },
    renderLevel () {
      if (this.level) {
        return this.level
      }
      return this.levelValue
    }
  }
}
</script>
