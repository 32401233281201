<template>
<div class="container mx-0 px-0 mt-2">
  <div class="row mx-0 px-0">
    <div class="col mx-auto px-0 mb-3 pb-2">
      <JobsList />
    </div>
    <div class="d-none d-md-block col-auto mx-3 p-0" />
    <div class="col mx-auto px-0">
      <ScriptsList />
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import JobsList from '@/components/modeling/JobsList.vue'
import ScriptsList from '@/components/modeling/ScriptsList.vue'
import NavigationService from '@/services/NavigationService'

@Options({
  components: {
    JobsList,
    ScriptsList
  }
})
export default class ModelingHome extends Vue {
  mounted () {
    NavigationService.setNavLevel1('Modeling', '')
  }
}
</script>
