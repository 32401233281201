<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="col-auto component-pane component-pane-narrow">
          <div>
            <form @submit.prevent="handleSubmit" autocomplete="off">

              <TextInputRow label="Description"
                v-model="formData.description"
                labelClass="label-required"
                :required="true"
                placeholder="Short and Human-Readable"
                maxlength="72"
                dataType="description"
                inputClass="width-234"
              />

              <SelectRow
                label="Medium" v-model="formData.medium" :required="true" class="pb-1" selectClass="width-234">
                <template v-slot:options>
                  <NotificationMediums />
                </template>
              </SelectRow>

              <TextInputRow :label="labels.subj"
                v-model="formData.subject"
                :labelIcon="labelIcon"
                labelClass="label-required"
                :placeholder="labels.subjPlaceholder"
                maxlength="255"
                dataType="description"
                inputClass="width-234"
              >
                <template v-slot:labelIcon>
                  <Popper v-if="labels.help" v-bind:arrow="true">
                    <fa icon="circle-question" class="button-icon" />
                    <template #content>
                      <div style="max-width:200px;white-space:normal;">{{ labels.help }}</div>
                    </template>
                  </Popper>
                </template>
              </TextInputRow>

              <TextAreaRow inputClass="notes-box width-345 height-145 ms-1"
                placeholder="This is the primary content of the Notification, like a push message or the body of an email."
                v-model="formData.body"
                :label="labels.body"
                labelClass="width-85 text-left"
                :required="true"
                />

              <SelectRow label="Attachments" v-model="formData.attachments" class="pb-1" v-if="formData.medium === 'email'">
                <template v-slot:options>
                  <option value="">None</option>
                  <option v-for="(file, key) in attachmentOptions" :key="key" :value="key">{{ file }}</option>
                </template>
              </SelectRow>

              <CheckboxInputRow label=""
                labelClass="width-0"
                v-model="formData.growerSelfSvc"
                checkboxLabel="Available for Grower Self-Service"
                id="cbIsCritical"
                class="pb-2"
                @click="updateGrowerSelfSvc"
                v-if="formData.medium === 'email'"
              />

              <SelectRow
                label="Noncritical Sound"
                labelClass="width-139"
                selectClass="width-210"
                v-model="formData.normalSound"
                class="pb-1"
                v-if="formData.medium === 'push'"
              >
                <template v-slot:options>
                  <option v-for="(obj, key) in appSounds" :key="key" :value="obj.filename">{{ obj.label }}</option>
                </template>
              </SelectRow>

              <SelectRow
                label="Critical Sound"
                labelClass="width-139"
                selectClass="width-210"
                v-model="formData.criticalSound"
                class="pb-1"
                v-if="formData.medium === 'push'"
              >
                <template v-slot:options>
                  <option v-for="(obj, key) in appSounds" :key="key" :value="obj.filename">{{ obj.label }}</option>
                </template>
              </SelectRow>

              <hr />

              <TextAreaRow inputClass="notes-box width-345 height-60 ms-1"
                placeholder="Enter any info about this Template that Agrology may find useful in the future."
                v-model="formData.notes"
                label="Notes"
                labelClass="text-left ms-1"
                />
            </form>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="handleSubmitUpdate" v-if="!isCreate"
            :disabled="isLoading || !formData">Update Template</button>
          <button class="btn" v-else
            @click="handleSubmitCreate"
            :disabled="isLoading">Create Template</button>
          <button class="btn btn-blue" :disabled="isLoading" style="margin-left: 20px" @click.prevent="close()">Cancel</button>
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script>
import BaseTemplateComponent from '@/components/notifications/BaseTemplateComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NotificationMediums from '@/components/selectOptions/NotificationMediums.vue'
import HelpContent from '@/services/HelpContent'
import Popper from 'vue3-popper'
import Reloadables from '@/services/reloadables'
import Modal from '@/components/modals/Modal.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

export default {
  name: 'template-add',
  extends: BaseTemplateComponent,
  data () {
    return {
      formData: {},
      isCreate: false,
      isBlocking: false
    }
  },
  props: ['tmpl'],
  computed: {
    labelIcon () {
      if (this.labels.help) {
        return 'circle-question'
      }
      return undefined
    },
    headerText () {
      if (this.tmpl) {
        return 'Edit Template'
      }
      return 'Add New Template'
    },
    labels () {
      var d = {
        subj: 'Subject',
        body: '',
        placeholder: '',
        subjRequired: false
      }
      if (this.formData.medium === 'slack') {
        d.subj = 'Channel ID'
        d.body = 'Message'
        d.subjPlaceholder = 'C02C9GNH7GD'
        d.help = 'Find the Channel ID in a Slack Channel\'s Settings. This field does not accept #channel-names. Leave blank to use #data-alerts.'
        d.subjRequired = false
      } else if (this.formData.medium === 'email') {
        d.subj = 'Subject'
        d.body = 'Body'
        d.subjRequired = true
      } else if (this.formData.medium === 'push') {
        d.subj = 'Subject'
        d.body = 'Message'
        d.subjRequired = false
      }
      return d
    }
  },
  methods: {
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    reset () {
      if (this.tmpl) {
        this.formData = { ...this.tmpl }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    updateGrowerSelfSvc () {
      this.growerSelfSvc = !this.growerSelfSvc
    },
    handleSubmitUpdate () {
      this.isLoading = true
      NotificationsDataService.updateTemplate(this.formData.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Template Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Template Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleSubmitCreate () {
      this.isLoading = true
      var data = {
        description: this.formData.description,
        notes: this.formData.notes,
        medium: this.formData.medium,
        subject: this.formData.subject,
        body: this.formData.body,
        growerSelfSvc: this.formData.growerSelfSvc,
        attachments: this.formData.attachments,
        normalSound: this.formData.normalSound,
        criticalSound: this.formData.criticalSound
      }
      NotificationsDataService.createTemplate(data)
        .then((resp) => {
          console.log(resp.data.id)
          HelpContent.setTimedFlashMessage('Template Created Successfully')
          this.$router.push({ name: 'template-details', params: { id: resp.data.id } })
        })
        .catch(e => {
          let message = 'Template Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Template Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.getAppSounds()
  },
  components: { NotificationMediums, Modal, TextInputRow, SelectRow, TextAreaRow, CheckboxInputRow, Popper }
}
</script>

<style>
  :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #eeeeee;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 15px;
    --popper-theme-box-shadow: 0 6px 100px -6px rgba(0, 0, 0, 0.95);
  }

</style>
