import { MeasurementLookup } from './types'

const littleEndianTransform = (hex: string): string => {
  if (!hex) {
    return hex
  }
  if (hex.length % 2 !== 0) {
    hex = '0' + hex
  }
  var out = ""
  for (var i = hex.length - 2; i >= 0; i = i - 2) {
    out += hex.substring(i, i+2)
  }
  return out
}

const twosCompThresh = Math.pow(2,31)
const twosCompSub = Math.pow(2,32)

const getCoordinate = (hexPos: string): number => {
  let val = parseInt(littleEndianTransform(hexPos), 16)
  if (val >= twosCompThresh) {
    val -= twosCompSub
  }
  return val / 1e7
}

export const DecodeOyster = (payload: string): MeasurementLookup => {
  const out = {} as MeasurementLookup
  if (!payload || payload.length != 22) {
    return out
  }
  payload = payload.toUpperCase()
  out['Battery'] = { Value: (parseInt(payload.substring(20, 22), 16) * 25) / 1000, Units: 'v' }
  out['Latitude'] = { Value: getCoordinate(payload.substring(0, 8)), Units: '˚' }
  out['Longitude'] = { Value: getCoordinate(payload.substring(8, 16)), Units: '˚'  }
  let tripData = parseInt(payload.substring(16, 18), 16)
  const motionDetected = (tripData & 1) === 1
  out['Motion Detected'] = {Value: motionDetected}
  if (motionDetected) {
    out['Speed'] = {Value: parseInt(payload.substring(18, 20)), Units: 'km/h'}
    tripData >>= 1
    tripData >>= 1
    out['Heading'] = {Value:  tripData * 5.625, Units: '˚'}
  }
  return out
}
