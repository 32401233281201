<template>
  <TabBar activeTabName="gateways" />
  <GatewayMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import GatewayMainList from '@/components/fleet/GatewayMainList.vue'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    GatewayMainList,
    TabBar
  }
})
export default class GatewaysList extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Gateways', '#/fleet', '')
  }
}
</script>
