<template>
  <div class="container justify-content-center big-menu-list px-0" v-if="userHasAnyPrivilege">
    <h4>Select a Space to Manage</h4>
    <ul class="list-group info-pane" style="border:none">

      <li class="list-group-item" v-if="hasFleetPerms">
        <router-link :to="{  name: 'fleet-home'}" class="space-selector-link padded">
          Fleet
        </router-link>
        <p>Device Inventory &amp; Deployment Topology</p>
      </li>

      <li class="list-group-item" v-if="hasNotificationPerms">
        <router-link :to="{  name: 'notifications-home'}" class="space-selector-link padded">
          Notifications
        </router-link>
        <p>Threshold-based Customer Alerts</p>
      </li>

      <li class="list-group-item" v-if="isMLDeveloper">
        <router-link :to="{  name: 'modeling-home'}" class="space-selector-link padded">
          ML Modeling Jobs
        </router-link>
        <p>Manage ML Scripts and Job Schedules</p>
      </li>

      <li class="list-group-item">
        <router-link :to="{  name: 'tools-home'}" class="space-selector-link padded">
          Tools
        </router-link>
        <p>Tools, Gadgets and Helpers</p>
      </li>

      <li class="list-group-item" v-if="isAdmin">
        <router-link :to="{  name: 'admin-home'}" class="space-selector-link padded">
          System Administration
        </router-link>
        <p>Permissions, Backend Metadata, etc. </p>
      </li>

    </ul>
  </div>
  <div class="container justify-content-center big-menu-list px-0 mt-5" v-else>
    <h4>You do not have access to this system.</h4>
  </div>
  </template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'

export default {
  extends: BaseComponent,
  name: 'landing-page'
}
</script>
