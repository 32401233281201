<template>
  <div class="d-flex ps-2 mt-2 mb-2 pb-1 pt-2 add-border-top subtle">
    <span class="status-last-modified">
      {{ relativeTime }}
    </span>
    <span class="font-reduce-2 ps-1" v-if="author">by {{ author }}</span>
  </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'

export default {
  props: ['ts', 'author'],
  extends: BaseComponent,
  computed: {
    relativeTime () {
      if (!this.ts) {
        return '--'
      }
      var epoch = parseInt(this.ts)
      if (isNaN(epoch)) {
        return '--'
      }
      if (epoch < 1000000000000) {
        epoch *= 1000
      }
      return this.getSince(epoch)
    }
  },
  methods: {
    getSince (input) {
      if (!input) {
        return '--'
      }
      var secs = Math.floor((Date.now() - parseInt(input)) / 1000)
      if (isNaN(secs)) {
        return '--'
      }
      if (secs < 60) {
        return secs.toString() + 's'
      }
      if (secs < 3600) {
        return Math.floor((secs / 60)).toString() + 'm'
      }
      if (secs < 86400) {
        return Math.floor((secs / 3600)).toString() + 'h'
      }
      if (secs < 604800) {
        return Math.floor((secs / 86400)).toString() + 'd'
      }
      if (secs < 31536000) {
        return Math.floor((secs / 604800)).toString() + 'w'
      }
      return Math.floor((secs / 31536000)).toString() + 'y'
    }
  }
}
</script>
