<template>
  <div class="col-auto d-flex searchlist-container">
    <label :class="mergedLabelClass" :for="id" v-if="label">{{ label }}{{ labelSeparator }}</label>
    <div v-if="!selectedLabel">
      <input
        type="text"
        :id="mergedId"
        :name="id"
        :style="inputStyle"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="mergedInputClass"
        v-model="searchValue"
        @keypress="keyPressed"
        @keydown="inputKeyDown"
        autocomplete="off"
        ref="searchValueInput"
        @focus="show"
      />
      <div class="searchlist-results" v-if="isSearching && searchResults && searchResults.length > 0">
        <ul>
          <li
            v-for="(res, i) of searchResults"
            :key="i"
            :id="i"
            :class="{'navigation-target': i === resultsFocusIndex}"
            @click="() => { makeSelection(i)}"
          >
            <span>
              {{ res[labelField] }}
            </span>
            <div v-if="i === resultsFocusIndex" class="icon-watermark lucky-watermark">
              <fa icon="turn-down" alt="Search" class="return-key-watermark" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex searchlist-value justify-content-between" v-else>
      <label @click="selectedLabelClicked">{{ selectedLabel }}</label>
      <a class="active-link-framed" @click="clearSelection">&#x2715;</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'search-list',
  emits: ['update:modelValue'],
  props: [
    'kvps',
    'label',
    'placeholder',
    'id',
    'labelClass',
    'inputStyle',
    'inputClass',
    'separator',
    'disabled',
    'labelField',
    'valueField',
    'modelValue'
  ],
  data () {
    return {
      resultsFocusIndex: 0,
      searchValue: '',
      selectedLabel: null,
      isSearching: true,
      defaultState: true
    }
  },
  watch: {
    searchValue: {
      handler () {
        this.resultsFocusIndex = 0
      }
    }
  },
  methods: {
    show () {
      if (!this.isSearching || this.defaultState) {
        this.isSearching = true
        this.defaultState = false
        // adding 500ms timeout allows time for the user release the mouse
        // curser after clicking into the input box to prevent the imminent
        // mouseup event from registering as a click and hiding the menu again
        setTimeout(() => { document.addEventListener('click', this.hide) }, 500)
      }
    },
    hide () {
      if (this.isSearching) {
        this.isSearching = false
        document.removeEventListener('click', this.hide)
      }
    },
    selectedLabelClicked () {
      this.selectedLabel = null
      this.show()
      this.$nextTick(() => {
        if (this.$refs.searchValueInput) {
          this.$refs.searchValueInput.focus()
          this.$refs.searchValueInput.select()
        }
      })
    },
    clearSelection () {
      this.$emit('update:modelValue', null)
      this.selectedLabelClicked()
    },
    keyPressed (e) {
      if (this.searchResults.length === 0 || e.keyCode !== 13 ||
      this.resultsFocusIndex >= this.searchResults.length) {
        return
      }
      this.makeSelection(this.resultsFocusIndex)
    },
    makeSelection (i) {
      this.resultsFocusIndex = i
      this.hide()
      this.selectedLabel = this.searchResults[i][this.labelField]
      this.$emit('update:modelValue', this.searchResults[i][this.valueField])
    },
    inputKeyDown (e) {
      if (!e || !e.keyCode || e.keyCode === 13) {
        return
      }
      if (e.keyCode === 27) {
        this.searchValue = ''
      }
      this.show()
      if (this.searchResults.length === 0) {
        return
      }
      if (e.keyCode === 38) {
        if (this.resultsFocusIndex === 0) {
          return
        }
        let i = this.resultsFocusIndex - 1
        if (i < 0) {
          i = 0
        }
        this.resultsFocusIndex = i
        e.preventDefault()
        return
      }
      if (e.keyCode === 40) {
        if (this.resultsFocusIndex >= this.searchResults.length - 1) {
          return
        }
        let i = this.resultsFocusIndex + 1
        if (i >= this.searchResults.length) {
          i = this.searchResults.length - 1
        }
        this.resultsFocusIndex = i
        e.preventDefault()
      }
    }
  },
  computed: {
    inputRef () {
      if (!this.id) {
        return 'searchlist-input-ref'
      }
      return `${this.id}-searchlist-input`
    },
    searchResults () {
      const out = []
      const dedupe = {}
      if (!this.searchValue || this.searchValue.length < 3) {
        return out
      }
      for (const key of this.keys) {
        if (key.includes(this.searchValue.toLowerCase()) && !(this.kvps[key][this.valueField] in dedupe)) {
          out.push(this.kvps[key])
          dedupe[this.kvps[key][this.valueField]] = true
        }
      }
      return out
    },
    keys () {
      const out = []
      if (!this.kvps) {
        return out
      }
      for (const k in this.kvps) {
        out.push(k)
      }
      return out
    },
    labelSeparator () {
      if (this.separator || this.separator === '') {
        return this.separator
      }
      return ':'
    },
    mergedLabelClass () {
      if (this.labelClass) {
        return 'searchlist-label ' + this.labelClass + ' me-2'
      }
      return 'searchlist-label me-2'
    },
    mergedInputClass () {
      if (this.inputClass) {
        return 'searchlist-input form-input ' + this.inputClass
      }
      return 'searchlist-input form-input'
    },
    mergedId () {
      if (this.id) {
        return this.id
      }
      return 'search-list-input'
    }
  }
}
</script>
