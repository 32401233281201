<template>
  <option v-for="(id, role) in nodeRoles" :key="role" :value="id">
    {{ role }}
  </option>
</template>
<script>
import { NodeRoles } from '@/app/data/noderoles.ts'
export default {
  computed: {
    nodeRoles () {
      return { ... NodeRoles }
    }
  }
}
</script>
