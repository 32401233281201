<template>
  <div class="component-pane component-pane-narrow info-pane-wrapper info-pane">
    <div class="d-flex justify-content-between align-items-center title-row add-border-bottom" v-if="title">
      <h5 class="dt-title" v-if="title">{{ title }}</h5>
      <slot name="filter-pillbox">
      </slot>
      <div>
        <slot name="action-icon">
          <button v-if="actionFunc" @click="actionFunc()" class="btn btn-table-header" style="margin-top:2px;margin-right:2px;" :disabled="actionDisabled">
            <fa :icon="icon" class="button-icon-wrapped" style="margin-top:-2px;margin-right: 3px;" />
            <span v-if="actionLabel">
              {{ actionLabel }}
            </span>
            <span v-else>
              Edit<span v-if="itemType">&nbsp;{{ itemType }}</span>
            </span>
          </button>
        </slot>
      </div>
    </div>
    <div :class="infoPaneClass">
      <div>
        <slot name="info" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'actionFunc', 'actionIcon', 'actionLabel', 'itemType', 'paneClass', 'actionDisabled'],
  computed: {
    infoPaneClass () {
      let classNames = ''
      if (this.paneClass) {
        classNames += ` ${this.paneClass}`
      }
      return classNames
    },
    icon () {
      if (this.actionIcon) {
        return this.actionIcon
      }
      return 'pen-to-square'
    }
  }
}
</script>
