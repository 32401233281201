<template>
  <JobInfo />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import JobInfo from '@/components/modeling/JobInfo.vue'

@Options({
  components: {
    JobInfo
  }
})
export default class ModelingJobInfo extends Vue {
}
</script>
