export interface MapMarker {
  icon: string
  color: string
  coords: number[]
  content?: MapMarkerContent
}

export type MapItemClickFunc = () => void

export interface MapMarkerContent {
  title: string
  titleLinkText?: string
  titleLink?: string
  titleRoute?: string
  titleRouteParams?: RouteParamPairs
  titleClickFunc?: MapItemClickFunc
  subtitle?: string
  subtitleLinkText?: string
  subtitleLink?: string
  subtitleRoute?: string
  subtitleRouteParams?: RouteParamPairs
  subtitleClass?: string
  items: MapMarkerContentItems
}

export interface MapMarkerContentItem {
  title?: string
  titleLinkText?: string
  link?: string
  route?: string
  routeParams?: RouteParamPairs
  body?: string
}

export interface RouteParamPairs {
  [key: string]: string
}

export type MapMarkers = MapMarker[]
export type MapMarkerContentItems = MapMarkerContentItem[]

export const NewMarker = (): MapMarker => {
  return {} as MapMarker
}

export const NewMarkers = (): MapMarkers => {
  return [] as MapMarkers
}

export const NewMapMarkerContent = (): MapMarkerContent => {
  return {
    items: MapMarkerContentItems()

  } as MapMarkerContent
}

export const NewMapMarkerContentItem = (): MapMarkerContentItem => {
  return {} as MapMarkerContentItem
}

export const MapMarkerContentItems = (): MapMarkerContentItems => {
  return [] as MapMarkerContentItems
}