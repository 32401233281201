import http from '@/http-common'
import qs from 'qs'

class AdminDataService {
  getCurrentUserPrivileges () {
    return http.get('/provisioning/user')
  }

  // User Management
  getSiteUsers (siteID: string) {
    return http.get(`/provisioning/sites/${siteID}/access`)
  }

  getUserSites (email: string) {
    return http.get(`/provisioning/users/${email}/sites`)
  }

  deleteSiteFromUser (email: string, siteID: string) {
    return http.delete(`/provisioning/sites/${siteID}/access/${email}`)
  }

  upsertUserSite (siteID: string, data: any) {
    return http.post(`/provisioning/sites/${siteID}/access`, qs.stringify(data))
  }

  deleteAppUser (email: string) {
    return http.delete(`/provisioning/users/${email}`)
  }

  getCustomerUsers (customerID: string) {
    return http.get(`/provisioning/customers/${customerID}/access`)
  }

  getCustomerSiteSpecificUsers (customerID: string) {
    return http.get(`/provisioning/customers/${customerID}/access/site`)
  }

  getUserCustomers (email: string) {
    return http.get(`/provisioning/users/${email}/customers`)
  }

  deleteCustomerFromUser (email: string, customerID: string) {
    return http.delete(`/provisioning/customers/${customerID}/access/${email}`)
  }

  upsertUserCustomer (customerID: string, data: any) {
    return http.post(`/provisioning/customers/${customerID}/access`, qs.stringify(data))
  }

  getStaffRoles () {
    return http.get('/provisioning/staff/roles')
  }

  updateStaffRole (role: string, data: any) {
    return http.put(`/provisioning/staff/roles/${role}`, data)
  }

  createStaffRole (data: any) {
    return http.post('/provisioning/staff/roles', data)
  }

  getStaffRolePrivs (role: string) {
    return http.get(`/provisioning/staff/roles/${role}/privileges`)
  }

  addStaffRolePriv (role: string, data: any) {
    return http.post(`/provisioning/staff/roles/${role}/privileges`, data)
  }

  deleteStaffRolePriv (role: string, priv: string) {
    return http.delete(`/provisioning/staff/roles/${role}/privileges/${priv}`)
  }

  getRolesByUser (email: string) {
    return http.get(`/provisioning/staff/access/${email}`)
  }

  addUserRole (email: string, role: string) {
    return http.post('/provisioning/staff/access', { email: email, role: role })
  }

  removeUserRole (email: string, role: string) {
    return http.delete(`/provisioning/staff/access/${email}/role/${role}`)
  }

  getUserApiKeyAccess (email: string) {
    return http.get(`/provisioning/apikey-access/${email}`)
  }

  grantUserApiKeyAccess (email: string) {
    return http.post(`/provisioning/apikey-access/${email}`)
  }

  revokeUserApiKeyAccess (email: string) {
    return http.delete(`/provisioning/apikey-access/${email}`)
  }
}

export default new AdminDataService()
