<template>
      <span class="spinner">
        <a>
          <fa icon="rotate" class="button-icon refresh-button" v-bind:class="{ 'refreshing': isLoading, 'refresh-error': isError }" />
        </a>
      </span>
</template>

<script>
export default {
  data () {
    return {
      isLoading: this.spinOnCreation,
      isError: false,
      startTime: 0
    }
  },
  props: ['spinOnCreation'],
  methods: {
    stopSpinningASAP () {
      this.isLoading = false
    },
    startSpinning () {
      this.isLoading = true
      this.isError = false
      this.startTime = Date.now()
    },
    stopSpinning () {
      var timeout = 500 - ((Date.now() - this.startTime) % 500)
      if (timeout > 12) {
        timeout -= 12
      }
      setTimeout(this.stopSpinningASAP, timeout)
    },
    setError () {
      this.isError = true
    }
  }
}
</script>
