<template>
    <option value="fire-in-area">Events / Fire in Area</option>
    <option value="fire-weather-watch">Events / Fire Weather Watch</option>
    <option value="red-flag-warning">Events / Red Flag Warning</option>
    <option value="temperature">72h Forecast / Temp &#176;C</option>
    <option value="cloudCover">72h Forecast / Cloud Cover</option>
    <option value="dewPoint">72h Forecast / Dew Point &#176;C</option>
    <option value="humidity">72h Forecast / Humidity</option>
    <option value="precipitationType">72h Forecast / Precip Type</option>
    <option value="precipitationIntensity">72h Forecast / Precip Intensity</option>
    <option value="epaIndex">72h Forecast / EPA Index</option>
</template>
