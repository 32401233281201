<template>
  <SearchList
    :label="mergedLabel"
    :placeholder="mergedPlaceholder"
    :kvps="kvps"
    :id="id"
    :labelClass="labelClass"
    :inputStyle="inputStyle"
    :inputClass="inputClass"
    :separator="separator"
    :disabled="disabled"
    labelField="displayName"
    valueField="id"
    v-model="searchVal"
    />
</template>
<script>
import SearchList from '@/components/forms/SearchList.vue'

export default {
  name: 'site-search-list',
  components: { SearchList },
  props: [
    'sites',
    'label',
    'placeholder',
    'id',
    'labelClass',
    'inputStyle',
    'inputClass',
    'separator',
    'disabled',
    'modelValue'
  ],
  data () {
    return {
      searchVal: null
    }
  },
  watch: {
    searchVal: {
      handler () {
        this.$emit('update:modelValue', this.searchVal)
      }
    }
  },
  computed: {
    kvps () {
      const out = {}
      if (!this.sites || this.sites.length === 0) {
        return out
      }
      for (const site of this.sites) {
        out[site.id.toLowerCase()] = site
        out[site.displayName.toLowerCase()] = site
      }
      return out
    },
    mergedLabel () {
      if (this.label || this.label === '') {
        return this.label
      }
      return 'Site'
    },
    mergedPlaceholder () {
      if (this.placeholder || this.placeholder === '') {
        return this.placeholder
      }
      return 'Start typing a Site Name or ID'
    }
  }
}
</script>
