export interface NodeLayout {
  id: string
  displayName: string
  function: string
  positions: PositionACL
}

export interface PositionACL {
  [key: string]: string
}

export type SlotData = any[][]

export interface DeviceData {
  id: string
  position: string
  model: string
  node: string
}

export type DeviceDatas = DeviceData[]

// returns an array of arrays in the format of:
// ["Position/Label", isBadSlotBool, "Description/DevEUI", "Extra", HasExtraBool?]

export const getSlotData = (layout: NodeLayout, devs: DeviceDatas): SlotData => {
  const out = [] as SlotData
  if (!layout || !('positions' in layout) ||
  devs.length === 0) {
    return out
  }
  const devices = [...devs] as DeviceDatas
  const used = [] as string[]
  for (const pos in layout.positions) {
    const slots = layout.positions[pos].split('|')
    for (const i in slots) {
      const dts = slots[i].split(',')
      let slotFilled = false
      for (const dev of devices) {
        if (used.includes(dev.id) || dev.position !== pos || !dts.includes(dev.model)) {
          continue
        }
        slotFilled = true
        out.push(
          [pos, true, dev.id, dev.model, false]
        )
        used.push(dev.id)
        break
      }
      if (!slotFilled) {
        out.push(
          [pos, false, 'needs ' + slots[i], '', false]
        )
      }
    }
  }
  for (const dev of devices) {
    if (used.includes(dev.id)) {
      continue
    }
    out.push(
      ['Extra Device', false, dev.id, dev.model, true]
    )
  }
  return out
}

export const devicesByNodeId = (nodeId: string, devs: DeviceDatas): DeviceDatas => {
  const out = [] as DeviceDatas
  if (!nodeId || !devs || devs.length === 0) {
    return out
  }
  for (const dev of devs) {
    if (dev.node !== nodeId) {
      continue
    }
    out.push(dev)
  }
  return out
}

export const deviceDataFromIndexItem = (input: any): DeviceData => {
  const dd = {} as DeviceData
  if (input.key) {
    dd.id = input.key.replace('device.', '')
  }
  if (input.position) {
    dd.position = input.position
  }
  if (input.suffix) {
    const parts = input.suffix.split(' ')
    dd.model = parts[parts.length-1]
    if (dd.model === 'v1') {
      dd.model = 'v1_ground'
    }
  }
  if (input.node) {
    dd.node = input.node
  }
  return dd
}
