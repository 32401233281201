<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
        <span v-if="deviceData && deviceData.id" class="ms-1 font-reduce-1">
          {{ deviceData.id }}
        </span>
      </template>

      <template v-slot:body>
        <div class="pillbox-wrapper">
          <Pillbox :pills="pills" :activePillChanged="activePillChanged" :defaultPill="activeSection" />
        </div>

        <hr class="form-sep" />

        <form ref="deviceForm" @submit.prevent="handleSubmit" autocomplete="off"
          style="overflow-x: hidden">

          <div class="d-flex flex-wrap">

            <div v-if="activeSection === 'general'">
              <TextInputRow label="ID"
                v-model="formData.id"
                :readonly="!isCreate"
                :inputClass="{'subtle-info': !isCreate}"
                :labelClass="{'label-required': isCreate}"
                :required="isCreate"
                inputStyle="text-transform: lowercase;"
                placeholder="short-and-lowercase"
                maxlength="28"
                dataType="slug"
              />

              <SelectRow label="Status" v-model="formData.status" :required="true" class="pb-1">
                <template v-slot:options>
                  <option value="">-- Select Status --</option>
                  <GeneralStatusOptions />
                </template>
              </SelectRow>

              <CheckboxInputRow label=""
                v-model="formData.isMuted"
                checkboxLabel="Suppress Monitoring Alerts"
                id="cbMonitoringMuted"
                class="pb-2"
              />

              <TextAreaRow label="Notes"
                v-model="formData.notes"
                placeholder="Enter any info about this Device that Agrology may find useful in the future."
                class="pb-0"
                inputStyle="height: 77.5px;"
              />


              <SelectRow label="Make" v-model="formData.manufacturer">
                <template v-slot:options>
                  <option value="">-- Select Make --</option>
                  <option v-for="manufacturer in activeManufacturers" :value="manufacturer.id" :key="manufacturer.id">
                    {{ manufacturer.id }}
                  </option>
                </template>
              </SelectRow>

              <SelectRow label="Model" v-model="formData.model" :change="modelChanged">
                <template v-slot:options>
                  <option value="">-- Select Model --</option>
                  <option v-for="model in models" :value="model" :key="model">
                    {{ model }}
                  </option>
                </template>
              </SelectRow>

              <TextInputRow label="Serial"
                v-model="formData.serial"
                placeholder="230192KA-2"
                maxlength="18"
                dataType="description"
              />

              <SelectRow label="Known FW" v-model="formData.swVersionCurrent"
                v-if="firmware && firmware.length > 0">
                <template v-slot:options>
                  <option value="">-- Select Firmware --</option>
                  <option value="unknown">Unknown</option>
                  <option v-for="fw in firmware" :value="fw.version" :key="fw.id">
                    {{ fw.version }}
                  </option>
                </template>
              </SelectRow>

              <SelectRow label="Target FW" v-model="formData.swVersionTarget"
                v-if="firmware && firmware.length > 0">
                <template v-slot:options>
                  <option value="">-- Select Firmware --</option>
                  <option value="default">Default for Model</option>
                  <option v-for="fw in firmware" :value="fw.version" :key="fw.id">
                    {{ fw.version }}
                  </option>
                </template>
              </SelectRow>

            </div>
            <div v-if="activeSection === 'location'">

              <SelectRow label="Site" v-model="formData.site" :required="true" :change="siteChanged">
                <template v-slot:options>
                  <option value="">-- Select Site --</option>
                  <option v-for="site in activeSites" :value="site.id" :key="site.id">
                    {{ site.displayName }}
                  </option>
                </template>
              </SelectRow>

              <SelectRow label="Node" v-model="formData.node">
                <template v-slot:options>
                  <option value="">-- Select Node --</option>
                  <option v-for="node in siteNodes" :value="node.id" :key="node.id">
                    {{ nodeOptionVal(node) }}
                  </option>
                </template>
              </SelectRow>

              <SelectRow label="Position" v-model="formData.position">
                <template v-slot:options>
                  <option value="">-- Select Position --</option>
                  <DevicePositionOptions />
                </template>
              </SelectRow>

              <NumberInputRow
                label="Probe Depth"
                v-model="formData.probeDepth"
                inputStyle="width:75px;padding-left:7px;"
                placeholder=""
                maxlength="4"
                class="mb-1"
                :suffix="probeDepthInches"
              />

            </div>
            <div v-if="activeSection === 'network'">

              <SelectRow label="LoRa Server" v-model="formData.networkServer">
                <template v-slot:options>
                  <option value="">-- Select Server --</option>
                  <option v-for="networkServer in activeNetworkServers" :value="networkServer.id" :key="networkServer.id">
                    {{ networkServer.id }}
                  </option>
                </template>
              </SelectRow>

              <SelectRow label="LoRa Profile" v-model="formData.awsDeviceProfile"
                v-if="formData.networkServer == 'aws-iot'">
                <template v-slot:options>
                  <option value="">-- Select LoRa Profile --</option>
                    <LoRaAWSDeviceProfileOptions />
                </template>
              </SelectRow>

              <SelectRow label="Network Zone" v-model="formData.networkZone"
                v-if="formData.networkServer == 'senet'">
                <template v-slot:options>
                  <option value="">-- Select Senet Zone --</option>
                    <SenetNetworkZones />
                </template>
              </SelectRow>

              <TextInputRow label="App Key"
                v-model="formData.applicationKey"
                :disabled="!appKeyReset && deviceData && deviceData.applicationKey"
                :inputClass="{ 'input-disabled': !appKeyReset && deviceData && deviceData.applicationKey }"
                id="applicationKey"
                placeholder="32-digit Hex Key"
                maxlength="32"
                dataType="hex"
              />

              <div style="margin-top: -5px; margin-bottom: 3px; text-align: right;">
                <a @click="resetAppKey" class="normal font-reduce-2 padded"
                  v-if="!appKeyReset && deviceData && deviceData.applicationKey">
                  Reset App Key
                </a>
                <a @click="generateUUID" class="normal font-reduce-2 padded" v-else>
                  Generate App Key
                </a>
              </div>

            </div>
          </div>

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateDevice()" v-if="!isCreate"
            :disabled="isLoading || !formData || !formData.status || !formData.site">{{ updateButtonText }}</button>
          <button class="btn" @click="createDevice()" v-else
            :disabled="isLoading || !formData.id || !formData.status || !formData.site">Submit</button>
          <button class="btn btn-blue" :disabled="isLoading" style="margin-left: 20px" @click.prevent="close()">Cancel</button>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import SenetNetworkZones from '@/components/selectOptions/SenetNetworkZones.vue'
import LoRaAWSDeviceProfileOptions from '@/components/selectOptions/LoRaAWSDeviceProfileOptions.vue'
import DevicePositionOptions from '@/components/selectOptions/DevicePositionOptions.vue'
import Modal from '@/components/modals/Modal.vue'

import Pillbox from '@/components/controls/Pillbox.vue'
import NumberInputRow from '@/components/forms/NumberInputRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'device-add-update',
  props: ['deviceData', 'siteID'],
  extends: BaseFleetComponent,
  data () {
    return {
      isLoading: true,
      isBlocking: false,
      formData: {
        isMuted: false
      },
      isCreate: false,
      mode: '',
      products: null,
      siteNodes: null,
      firmware: null,
      appKeyReset: false,
      activeSection: 'general'
    }
  },
  computed: {
    pills () {
      return [
        {label: 'General'},
        {label: 'Location'},
        {label: 'Network'},
      ]
    },
    updateButtonText () {
      if (!this.formData) {
        return 'Update'
      }
      if (this.formData.networkServer === 'senet' && this.formData.applicationKey) {
        if (this.deviceData && this.formData.networkZone !== this.deviceData.networkZone) {
          return 'Update Agrology & Transfer in Senet'
        }
        return 'Update Agrology and Senet'
      }
      return 'Update'
    },
    probeDepthInches () {
      if (this.formData.probeDepth && !isNaN(this.formData.probeDepth) &&
        this.formData.probeDepth > 0) {
        return 'cm  /  ' +
          (this.formData.probeDepth * 0.39370079).toFixed(1).toString() + ' inches'
      }
      return 'cm'
    },
    models () {
      if (this.formData) {
        if (this.formData.manufacturer) {
          for (var m of this.activeManufacturers) {
            if (m.id === this.formData.manufacturer) {
              return m.models.split(',')
            }
          }
        }
      }
      return []
    },
    headerText () {
      if (this.deviceData) {
        return 'Edit Device'
      }
      return 'Add New Device'
    }
  },
  methods: {
    activePillChanged (value) {
      if (value) {
        this.activeSection = value
      }
    },
    generateUUID () {
      if (!this.formData) {
        return
      }
      this.formData.applicationKey = uuidv4().replaceAll('-', '').toLowerCase()
    },
    resetAppKey () {
      this.appKeyReset = true
    },
    retrieveFirmware (make, model) {
      this.isLoading = true
      FleetDataService.listFirmwareVersionsByMakeModel(make, model)
        .then(response => {
          this.firmware = response.data
        })
        .catch(e => {
          let message = 'Failed to load Device Firmware List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          this.firmware = null
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    nodeOptionVal (node) {
      if (!node) {
        return ''
      }
      return `${node.displayName}`
    },
    modelChanged () {
      if (this.formData && this.formData.manufacturer && this.formData.model) {
        this.retrieveFirmware(this.formData.manufacturer, this.formData.model)
      }
    },
    siteChanged () {
      if (this.formData && this.formData.site) {
        this.retrieveSiteNodes(this.formData.site)
      }
    },
    retrieveSiteNodes (id) {
      this.isLoading = true
      FleetDataService.getSiteNodes(id)
        .then(response => {
          this.siteNodes = response
        })
        .catch(e => {
          let message = 'Failed to load Site Nodes'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.appKeyReset = false
      this.mode = ''
      if (this.deviceData) {
        this.formData = { ...this.deviceData }
        this.formData.isMuted = this.formData.monitoringMuted === 1
        if (this.formData.site) {
          this.retrieveSiteNodes(this.formData.site)
        }
      } else {
        this.isCreate = true
        this.formData = {
          isMuted: false
        }
        if (this.siteID) {
          this.formData.site = this.siteID
        }
      }
    },
    show () {
      this.reset()
      this.retrieveAllSites()
      this.siteChanged()
      this.retrieveAllCustomers()
      this.retrieveAllManufacturers()
      this.retrieveAllNetworkServers()
      this.getFirmwareOnShow()
      this.isBlocking = true
    },
    getFirmwareOnShow () {
      if (!this.deviceData || !this.deviceData.manufacturer || !this.deviceData.model) {
        return
      }
      this.retrieveFirmware(this.deviceData.manufacturer, this.deviceData.model)
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateDevice () {
      this.isLoading = true
      const out = { ...this.formData }
      if (out.node && this.siteNodes) {
        let found = false
        for (const node of this.siteNodes) {
          if (node.id === out.node) {
            found = true
            break
          }
        }
        if (!(found)) {
          out.node = ''
        }
      } else if ('node' in out) {
        out.node = ''
      }
      if (out.notes) {
        out.notes = out.notes.trim()
      }
      if (out.applicationKey) {
        out.applicationKey = out.applicationKey.toLowerCase()
      }
      if (out.isMuted) {
        out.monitoringMuted = 1
      } else {
        out.monitoringMuted = 0
      }
      if (out.probeDepth === '0') {
        out.probeDepth = ''
      }
      if (out.applicationKey && out.networkZone && this.deviceData.networkZone &&
        out.networkZone !== this.deviceData.networkZone) {
        HelpContent.setFlashMessage('Just a moment. Senet activations and transfers can take up to 30s.', false, true, false, 'hourglass')
      }
      FleetDataService.updateDevice(out.id, out)
        .then(() => {
          HelpContent.setTimedFlashMessage('Device Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Update Device Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createDevice () {
      this.isLoading = true
      const out = { ...this.formData }
      if (out.notes) {
        out.notes = out.notes.trim()
      }
      if (out.applicationKey) {
        out.applicationKey = out.applicationKey.toLowerCase()
      }
      if (out.isMuted) {
        out.monitoringMuted = 1
      } else {
        out.monitoringMuted = 0
      }
      if (out.probeDepth === '0') {
        out.probeDepth = ''
      }
      FleetDataService.createDevice(out)
        .then(() => {
          HelpContent.setTimedFlashMessage('Device Created')
          Reloadables.requestReload()
          this.close()
          this.$router.push({
            name: 'device-details',
            params: { id: this.formData.id.toUpperCase() }
          })
        })
        .catch(e => {
          let message = 'Device Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Device Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow, NumberInputRow, SelectRow, TextAreaRow, CheckboxInputRow, GeneralStatusOptions, LoRaAWSDeviceProfileOptions, DevicePositionOptions, SenetNetworkZones, Pillbox }
}
</script>
