import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mx-0 px-0" }
const _hoisted_2 = { class: "col mx-auto px-0 mb-3 pb-2" }
const _hoisted_3 = { class: "col mx-auto px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_ChambersMainList = _resolveComponent("ChambersMainList")!
  const _component_VentsMainList = _resolveComponent("VentsMainList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabBar, { activeTabName: "misc" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ChambersMainList)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-none d-md-block col-auto mx-3 p-0" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VentsMainList)
      ])
    ])
  ], 64))
}