<template>
  <LandingPage />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '../services/NavigationService'
import LandingPage from '@/components/LandingPage.vue'

@Options({
  components: {
    LandingPage
  }
})
export default class Home extends Vue {
  mounted () {
    NavigationService.setNavHome()
  }
}
</script>
