<template>
  <TabBar activeTabName="misc" />
  <MiscMain />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import MiscMain from '@/components/fleet/MiscMain.vue'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    MiscMain,
    TabBar
  }
})
export default class MiscHome extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Miscellaneous', '#/fleet/misc', '')
  }
}
</script>
