<template>
  <TabBar activeTabName="misc" />
  <FlashingApplianceMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'
import FlashingApplianceMainList from '@/components/fleet/FlashingApplianceMainList.vue'

@Options({
  components: {
    TabBar,
    FlashingApplianceMainList
  }
})
export default class TazHome extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Flashing Appliances', '#/fleet/taz', '')
  }
}
</script>
