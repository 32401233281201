<template>
  <TabBar activeTabName="gateways" />
  <div class="row justify-content-center px-0 mx-0">
    <GatewayAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import GatewayAddForm from '@/components/fleet/GatewayAddForm.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    GatewayAddForm,
    TabBar
  }
})
export default class AddGateway extends Vue {}
</script>
