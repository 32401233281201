<template>
  <Modal ref="editModal" :script="script" />
  <InfoPane
    class="script-info-pane"
    title="Script Info"
    v-if="script"
    :actionFunc="showEditModal"
    actionIcon="pen-to-square"
    itemType="Info"
  >
    <template v-slot:info>
      <ul v-if="script" class="info-pane-list">
        <li class="info-pane-list-item"><label class="info-item-label">Name:</label>{{ script.name }}</li>
        <li class="info-pane-list-item"><label class="info-item-label">Status:</label>{{ script.status }}
          <span v-if="script.status === 'paused' && script.pauseUntil">
            for {{ getForUntil(script.pauseUntil) }}
          </span>
        </li>
        <li class="info-pane-list-item" v-if="script.description"><label class="info-item-label">Description:</label>{{ script.description }}</li>
        <li class="info-pane-list-item"><label class="info-item-label">Active Version:</label>{{ script.activeVersion }}<span v-if="!script.activeVersion">--</span></li>
        <li class="info-pane-list-item" v-if="script.lastModified">
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(script.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="script.lastModifiedBy"> by {{ script.lastModifiedBy }}</span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/modeling/ModalAddUpdateScript.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, Modal },
  props: ['script', 'loading'],
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    }
  }
}
</script>
