<template>
  <div>
    <DataTable
      class="dt-recent-sites"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="showCreateSiteModal"
      actionIcon="plus"
      itemType="Site"
      :initialSort="0"
    >
      <template v-slot:filter-pillbox>
        <div class="btn-group dt-pillbox me-2" role="group" aria-label="Site State Selector">
          <input type="radio" class="btn-check" value="prod" name="listFilter"
            id="listFilter-prod" v-model="listFilter" autocomplete="off" @click="refreshList('prod')" />
          <label class="btn btn-outline-primary" for="listFilter-prod">Monitored</label>
          <input type="radio" class="btn-check" value="nonprod" name="listFilter"
            id="listFilter-nonprod" v-model="listFilter" autocomplete="off" @click="refreshList('nonprod')" />
          <label class="btn btn-outline-primary" for="listFilter-nonprod">Unmonitored</label>
          <input type="radio" class="btn-check" value="all" name="listFilter"
            id="listFilter-all" v-model="listFilter" autocomplete="off" @click="refreshList('all')" />
          <label class="btn btn-outline-primary" for="listFilter-all">All Sites</label>
        </div>
      </template>
    </DataTable>
    <Modal ref="createSiteModal" />
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/fleet/ModalAddUpdateSite.vue'
import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'sites-list',
  extends: BaseFleetComponent,
  data () {
    return {
      listFilter: 'prod',
      cols: [
        { text: 'Site Name', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Customer', sort: 2 },
        { icon: 'heart-pulse', sort: 3 },
        { text: 'GPS', sort: 4 },
        { text: 'Timezone', sort: 5 },
        { text: 'Notes', sort: 6 }
      ],
      loading: false
    }
  },
  watch: {
    listFilter: {
      handler () {
        if (this.listFilter) {
          this.$router.replace({ to: 'fleet-home', query: { filter: this.listFilter } })
        }
      }
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.filteredSiteList && this.filteredSiteList.length > 0) {
        for (const site of this.filteredSiteList) {
          const key = ('site.' + site.id).toLowerCase()
          if (!(key in FleetDataService.manifest.data)) {
            continue
          }
          out.push([
            { value: site.displayName, route: 'site-details', params: { id: site.id }, class: this.siteLinkColor(site, '') },
            { value: this.getSince(site.report_time), class: this.lastReportClass(site), sortValue: Date.now() - site.report_time },
            { value: this.customerLabel(site.customer) },
            { value: this.isProd(site.hwMonitoringStatus), style: 'text-align: center' },
            { value: this.formatHasGPS(site), leftIcon: this.formatHasGPSIcon(site), class: { 'dt-centered': true, 'status-danger': !this.formatHasGPS(site) } },
            { value: this.formatTz(site.tz), leftIcon: this.timezoneIcon(site.tz), class: { 'status-danger': !site.tz } },
            { value: site.notes }
          ])
        }
      }
      return out
    },
    filteredSiteList () {
      if (this.listFilter === 'prod') {
        const out = []
        for (const i in this.activeSites) {
          if (this.activeSites[i].hwMonitoringStatus) {
            out.push(this.activeSites[i])
          }
        }
        return out
      }
      if (this.listFilter === 'nonprod') {
        const out = []
        for (const i in this.activeSites) {
          if (!this.activeSites[i].hwMonitoringStatus) {
            out.push(this.activeSites[i])
          }
        }
        return out
      }
      return this.activeSites
    },
    listTitle () {
      if (this.listFilter === 'prod') {
        return 'Monitored'
      } else if (this.listFilter === 'nonprod') {
        return 'Unmonitored'
      }
      return 'All Sites'
    }
  },
  methods: {
    customerLabel (input) {
      return FleetDataService.getCustomerDisplayName(input)
    },
    loadCustomers () {
      FleetDataService.listCustomers()
        .then(response => {
          this.customers = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    timezoneIcon (tz) {
      if (tz) {
        return ''
      }
      return 'exclamation'
    },
    refreshFunc () {
      this.loading = true
      FleetDataService.getAllSites()
        .then(response => {
          this.sites = response
        })
        .catch(e => {
          let message = 'Failed to load Sites List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatHasGPSIcon (elem) {
      if (elem && elem.lat && elem.long) {
        return ''
      }
      return 'exclamation'
    },
    formatHasGPS (elem) {
      if (elem && elem.lat && elem.long) {
        return '✓'
      }
      return ''
    },
    showCreateSiteModal () {
      this.$refs.createSiteModal.show()
    },
    isProd (b) {
      if (b) {
        return '✓'
      }
      return '-'
    },
    siteLinkColor (site, baseClass) {
      let classes = baseClass
      if (site.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    lastReportClass (site) {
      let classes = ''
      if (!site) {
        return classes
      }
      if (!site.monitoringMuted && this.isTimeNotable(site.report_time)) {
        classes += ' notable'
      }
      if (!site.monitoringMuted && this.isTimeAlertable(site.report_time)) {
        classes += ' status-error'
      }
      if (!site.monitoringMuted && this.isTimeCautionable(site.report_time)) {
        classes += ' status-caution'
      }
      return classes
    },
    refreshList (newFilter) {
      if (!newFilter && this.$route.query.filter) {
        newFilter = this.$route.query.filter
      }
      if (newFilter) {
        this.listFilter = newFilter
        localStorage.setItem('sitelistTab', newFilter)
      } else {
        const v = localStorage.getItem('sitelistTab')
        if (v) {
          this.listFilter = v
        }
      }
      this.refreshFunc()
    }
  },
  mounted () {
    this.loadCustomers()
    Reloadables.registerReloadable('sites-list', this.refreshFunc)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('sites-list')
  },
  components: { DataTable, Modal }
}
</script>
