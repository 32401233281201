import { reactive } from 'vue'
import http from '@/http-common'

const apiUrl = process.env.VUE_APP_API_URL
export const ds = reactive({})

export const api = {
  deleteCacheEntry (cacheKey, itemKey = '', listIndex = -1) {
    if (cacheKey in ds) {
      if (ds[cacheKey] && listIndex > -1 &&
        Object.prototype.toString.call(ds[cacheKey]) === '[object Array]') {
        delete ds[cacheKey][listIndex]
      } else if (ds[cacheKey] && itemKey) {
        delete ds[cacheKey][itemKey]
      } else {
        delete ds[cacheKey]
      }
    }
  },
  // general functions
  async post (path, data, cacheKey = '') {
    if (cacheKey && cacheKey in ds) {
      delete ds[cacheKey]
    }
    return http.post(path, data)
  },
  async put (path, data, cacheKey = '') {
    if (cacheKey && cacheKey in ds) {
      delete ds[cacheKey]
    }
    return http.put(path, data)
  },
  // gets from the Agrology API
  async get (path, ttl = -1, cacheKey = '', itemKey = '', keyfield = '') {
    ttl *= 1000
    let resp = {}
    const cacheKeyExp = cacheKey + '-exp'
    const cacheKeyPF = cacheKey + 'IsPartial'
    let cacheHit = false
    if (ttl >= 0 && cacheKey && cacheKey in ds) {
      resp = ds[cacheKey]
      cacheHit = true
      if ((!itemKey && resp && ds[cacheKeyPF]) || (resp && itemKey && !(itemKey in resp))) {
        resp = {}
        cacheHit = false
      } else if (itemKey && resp && itemKey in resp) {
        resp = resp[itemKey]
        cacheHit = true
      }
      if (cacheHit && resp && ((ttl === 0) ||
        (ttl > 0 && cacheKeyExp in ds && ds[cacheKeyExp] >= Date.now()))) {
        return resp
      }
    }
    await http
      .get(`${apiUrl}${path}`)
      .then(response => {
        if (ttl >= 0 && cacheKey) {
          if (itemKey) {
            if (!(cacheKey in ds)) {
              // add item to the cache key, but mark the key as partial data
              ds[cacheKey] = {}
              ds[cacheKeyPF] = true
              if (ttl > 0) {
                ds[cacheKeyExp] = Date.now() + ttl
              }
            }
            ds[cacheKey][itemKey] = response.data
          } else {
            ds[cacheKey] = response.data
            ds[cacheKeyPF] = false
            if (ttl > 0) {
              ds[cacheKeyExp] = Date.now() + ttl
            }
          }
        }
        resp = response.data
      })
      .catch(error => {
        console.log('error fetching :', error)
      })
    if (resp && keyfield && Object.prototype.toString.call(resp) === '[object Array]') {
      const dict = {}
      for (const i in resp) {
        dict[resp[i][keyfield]] = resp[i]
      }
      resp = dict
    }
    return resp
  }
}

export default api
