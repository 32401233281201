<template>
  <div class="row justify-content-center p-0">
    <div class="col-auto m-0 p-0">
      <CustomerInfoPane :customer="customer" />
      <CustomerPrefsPane :customer="customer" />
      <CustomerDomainsList :customer="customer" />
    </div>
    <div class="d-none d-md-block col-auto mx-3 p-0" />
    <div class="col-auto m-0 p-0">
      <SitesByCustomer />
      <AlertsByN entityType="Customer" />
    </div>
  </div>
</template>
<script>
import NavigationService from '@/services/NavigationService'
import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import CustomerInfoPane from '@/components/fleet/CustomerInfoPane.vue'
import CustomerPrefsPane from '@/components/fleet/CustomerPrefsPane.vue'
import CustomerDomainsList from '@/components/fleet/CustomerDomainsList.vue'
import SitesByCustomer from '@/components/fleet/SitesByCustomer.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'

export default {
  extends: BaseFleetComponent,
  components: { CustomerInfoPane, CustomerPrefsPane, CustomerDomainsList, SitesByCustomer, AlertsByN },
  data () {
    return {
    }
  },
  emits: ['register-reloadable'],
  methods: {
    getCustomer (id) {
      FleetDataService.getCustomer(id)
        .then(response => {
          this.customer = response.data
          this.customerBeforeEdit = Object.assign({}, this.customer)
          this.$state.creationName = this.customer.id
          NavigationService.setNavLevel2('Fleet', this.customer.displayName, '#/fleet', '')
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Customer')
        })
    },
    refreshInfo () {
      this.getCustomer(this.$route.params.id)
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.refreshInfo()
    Reloadables.registerReloadable('customer-info', this.refreshInfo)
  },
  unmounted () {
    Reloadables.unregisterReloadable('customer-info')
  }
}
</script>
