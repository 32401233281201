import { SelectOptions } from "@/components/selectOptions/selectOptions"

export const MetricsGroundTruth = {
  "airTemp": "Canopy Temperature",
  "aqi": "AQI",
  "aqi1024h": "AQI PM 10 - 24h",
  "aqi103h": "AQI PM 10 - 3h",
  "aqi2524h": "AQI PM 2.5 - 24h",
  "aqi253h": "AQI PM 2.5 - 3h",
  "barometerTemp": "Barometer Temperature",
  "barometricPressure": "Barometric Pressure",
  "battery": "Battery",
  "carbonFlux": "Carbon Flux",
  "co2Concentration": "CO2 Concentration",
  "dewPoint": "Dew Point",
  "humidity": "Canopy Humidity",
  "irrigationCnt": "Irrigation Count",
  "irrigationDur": "Irrigation Duration",
  "pm05NC": "PM 0.5 # Concentration",
  "pm10MC": "PM 10 Mass Concentration",
  "pm10NC": "PM 10 # Concentration",
  "pm1MC": "PM 1 Mass Concentration",
  "pm1NC": "PM 1 # Concentration",
  "pm25MC": "PM 2.5 Mass Concentration",
  "pm25NC": "PM 2.5 # Concentration",
  "pm4MC": "PM 4 Mass Concentration",
  "pm4NC": "PM 4 # Concentration",
  "rmox12": "Rmox 12",
  "rmox7": "Rmox 7",
  "soilConductivity": "Soil Conductivity",
  "soilMoisture": "Soil Moisture",
  "soilMoistureRaw": "Soil Moisture Raw",
  "soilTemperature": "Soil Temperature",
  "soilTension": "Soil Tension",
  "totalVOCs": "VOCs",
  "typicalParticleSize": "Typical Particle Size",
  "vpd": "Vapor Pressure Deficit",
  "vwc": "Volumetric Water Content",
  "waterPotential": "Water Potential"
}

export function MetricsGroundTruthOptions(): SelectOptions {
  const out = [] as SelectOptions
  for (const key in MetricsGroundTruth) {
    out.push({
      id: key,
      value: key,
      displayName: MetricsGroundTruth[key]
    })
  }
  return out
}