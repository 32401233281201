<template>
  <div class="component-pane">
    <div class="row me-4 mb-3" id="apnsubs-filter-box" style="min-width:365px;max-width:100vw;">

      <div class="col-auto">
        <label class="me-2" style="width:55px;">Type:</label>
        <div class="btn-group" role="group" aria-label="User Search Filter" style="max-height:27px !important;">
          <input type="radio" class="btn-check" value="apple" name="medium"
            id="medium-apple" v-model="medium" autocomplete="off" />
          <label class="btn btn-outline-primary" for="medium-apple">Apple</label>
          <input type="radio" class="btn-check" value="android" name="medium" :disabled="true"
            id="medium-android" v-model="medium" autocomplete="off" />
          <label class="btn btn-outline-primary" for="medium-android" :disabled="true">Android</label>
          <input type="radio" class="btn-check" value="email" name="medium"
            id="medium-email" v-model="medium" autocomplete="off" :disabled="true" />
          <label class="btn btn-outline-primary" for="medium-email" :disabled="true">Email</label>
        </div>
      </div>

      <div class="col-auto d-none d-lg-inline me-4" style="max-width:1px" />

      <div class="col-auto me-2">
        <label class="me-2" style="width:55px;">List by:</label>
        <div class="btn-group" role="group" aria-label="User Search Filter" style="max-height:27px !important;">
          <input type="radio" class="btn-check" value="site" name="scope"
            id="scope-site" v-model="scope" autocomplete="off" />
          <label class="btn btn-outline-primary" for="scope-site" @click="site = ''">Site</label>
          <input type="radio" class="btn-check" value="user" name="scope"
            id="scope-user" v-model="scope" autocomplete="off" />
          <label class="btn btn-outline-primary" for="scope-user" @click="email = ''">User</label>
          <input type="radio" class="btn-check" value="token" name="scope"
            id="scope-token" v-model="scope" autocomplete="off" />
          <label class="btn btn-outline-primary" for="scope-token" @click="token = ''">Token</label>
        </div>
      </div>

      <SiteSearchList :sites="activeSites" v-if="scope === 'site'" v-model="site" />

      <div v-else-if="scope === 'user'" class="col-auto">
        <input
          v-model="emailSearch"
          class="form-input ps-1"
          style="height:27px !important; width:250px;"
          placeholder="user@example.com"
          @keypress="keyPressed"
        />
        <button class="btn ms-2" style="max-height:27px !important;padding-top:2px;margin-top:-3px;" @click="filterByEmail()">Go</button>
      </div>

      <div v-else-if="scope === 'token'" class="col-auto mb-3">
        <input
          v-model="tokenSearch"
          class="form-input ps-1"
          style="height:27px !important; width:250px;"
          placeholder="b381c1dc73fb7c17a581bf59115215f5c5d57db5c065dc2630f85fdf15da6983"
          @keypress="keyPressed"
        />
        <button class="btn ms-2" style="max-height:27px !important;padding-top:2px;margin-top:-3px;" @click="getSub()">Go</button>
      </div>

    </div>
    <DataTable
      class="dt-recent-sites"
      title="Apple Push Subscriptions"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :initialSort="0"
      noDataMessage="No Subscriptions Listed"
      v-show="canReload"
      v-if="apnSubscriptions && apnSubscriptions.length > 0"
    />
  </div>
</template>

<script>
import BaseSubscriptionComponent from '@/components/notifications/BaseSubscriptionComponent.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'
import DataTable from '@/components/tables/DataTable.vue'
import SiteSearchList from '@/components/fleet/forms/SiteSearchList.vue'

export default {
  name: 'subscriptions-recent-list',
  extends: BaseSubscriptionComponent,
  components: { DataTable, SiteSearchList },
  data () {
    return {
      isLoading: false,
      sites: [],
      scope: 'site',
      medium: 'apple',
      token: '',
      site: '',
      email: '',
      emailSearch: '',
      tokenSearch: '',
      cols: [
        { text: 'Token', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Email', sort: 2 },
        { text: 'Site', sort: 3 },
        { text: 'BundleID', sort: 4 },
        { text: 'DeviceType', sort: 5 }
      ]
    }
  },
  methods: {
    siteDisplayName (input) {
      if (!input || !this.activeSites) {
        return input
      }
      for (const s of this.activeSites) {
        if (s.id === input) {
          return s.displayName
        }
      }
      return input
    },
    keyPressed (e) {
      if (e.keyCode === 13) {
        if (this.scope === 'user') {
          this.filterByEmail()
        } else if (this.scope === 'token') {
          this.getSub()
        }
        e.target.setSelectionRange(0, e.target.value.length)
      }
    },
    getSub () {
      if (this.scope !== 'token' || !this.tokenSearch) {
        return
      }
      this.token = this.tokenSearch
      this.refreshList()
    },
    filterByEmail () {
      if (this.scope !== 'user' || !this.emailSearch) {
        return
      }
      this.email = this.emailSearch
      this.refreshList()
    },
    getSites () {
      this.isLoading = true
      FleetDataService.getAllSites()
        .then(response => {
          this.sites = response
        })
        .catch(e => {
          let message = 'Failed to load Sites List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshList () {
      this.$nextTick(() => {
        let filterOn = ''
        if (this.scope === 'site') {
          filterOn = this.site
        } else if (this.scope === 'user') {
          filterOn = this.email
        } else if (this.scope === 'token') {
          filterOn = this.token
        }
        if (filterOn) {
          this.retrieveFilteredApnSubscriptions(this.scope, filterOn)
        }
      })
    }
  },
  watch: {
    site: {
      handler () {
        this.refreshList()
      }
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.apnSubscriptions && this.apnSubscriptions.length > 0) {
        for (const sub of this.apnSubscriptions) {
          out.push([
            { value: this.getLast10(sub.address), route: 'subscription-details', params: { deviceToken: sub.address, bundleID: sub.appID, site: sub.site } },
            { value: this.getSinceSecs(sub.lastModified), sortValue: Date.now() - sub.lastModified },
            { value: sub.user },
            { value: this.siteDisplayName(sub.site) },
            { value: sub.appID },
            { value: sub.deviceType }
          ])
        }
      }
      return out
    },
    canReload () {
      return (this.scope === 'site' && this.site) ||
      (this.scope === 'user' && this.email) ||
      (this.scope === 'token' && this.token)
    },
    activeSites () {
      const active = []
      for (const site of this.sites) {
        if (site.status === 'inactive') {
          continue
        }
        active.push(site)
      }
      return active
    }
  },
  mounted () {
    this.setNavNotif3('Subscriptions')
    this.getSites()
    this.refreshList()
    Reloadables.registerReloadable('subscriptions-list', this.refreshList)
  },
  onUnmounted () {
    Reloadables.unregisterReloadable('subscriptions-list')
  }
}
</script>
