<template>
  <div>
    <Modal ref="addEditModal" />
    <DataTable
      class="dt-recent-thresholds"
      title="Thresholds"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No Thresholds Listed"
      actionIcon="plus"
      :actionFunc="showEditor"
      itemType="Threshold"
      :initialSort="0"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/notifications/ModalAddUpdateThreshold.vue'

export default {
  name: 'thresholds-recent-list',
  extends: BaseComponent,
  data () {
    return {
      cols: [
        { text: 'Threshold', sort: 0 },
        { text: 'Enabled', sort: 1 },
        { text: 'Category', sort: 2 },
        { text: 'Scope', sort: 3 },
        { text: 'Source', sort: 4 },
        { text: 'Metric', sort: 5 },
        { text: 'Filter', sort: 6 },
        { text: 'Notes', sort: 7 }
      ],
      thresholds: []
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.thresholds && this.thresholds.length > 0) {
        for (const threshold of this.thresholds) {
          out.push([
            { value: threshold.description, route: 'threshold-details', params: { id: threshold.id }, class: { inactive: threshold.enabled === 0, warning: threshold.isCritical === true } },
            { value: this.enabledText(threshold), leftIcon: this.enabledIcon(threshold), class: this.enabledClass(threshold), sortValue: this.enabledClass(threshold) },
            { value: threshold.category },
            { value: threshold.scope },
            { value: threshold.source },
            { value: threshold.metric },
            { value: threshold.filter },
            { value: threshold.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showEditor () {
      this.$refs.addEditModal.show()
    },
    enabledIcon (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return 'check'
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return 'lock'
      }
      return ''
    },
    enabledClass (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return 'status-ok text-center'
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return 'lock-icon text-center'
      }
      return 'text-center'
    },
    enabledText (threshold) {
      if (threshold.enabled && !threshold.audienceDomains) {
        return ''
      }
      if (threshold.enabled && threshold.audienceDomains) {
        return ''
      }
      return '--'
    },
    refreshList () {
      this.isLoading = true
      NotificationsDataService.getAllThresholds()
        .then(response => {
          this.thresholds = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Threshold List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.listFilter = localStorage.getItem('recentThresholdsTab')
    if (!this.listFilter) {
      this.listFilter = 'recent'
    }
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel2('Notifications', 'thresholds', '#/notifications', '')
    Reloadables.registerReloadable('thresholds-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('thresholds-list')
  },
  components: { DataTable, Modal }
}
</script>
