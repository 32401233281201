import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container justify-content-center px-0 mx-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StaffRoleManagement = _resolveComponent("StaffRoleManagement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StaffRoleManagement)
  ]))
}