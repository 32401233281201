<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="ms-2 me-3">
          <TextInputRow label="Job Name"
            maxlength="28"
            v-model="formData.name"
            :readonly="!isCreate"
            :inputClass="{'subtle-info': !isCreate}"
            :required="isCreate"
            dataType="version"
          />
          <TextInputRow label="Description" v-model="formData.description" dataType="description" />
          <SelectRow label="Job Status" v-model="formData.status" v-if="!isCreate" :required="true">
            <template v-slot:options>
              <JobStatusOptions />
            </template>
          </SelectRow>
          <SelectRow label="Paused" v-model="formData.pauseUntil" v-if="!isCreate && formData.status === 'paused'" :required="true">
            <template v-slot:options>
              <option :value="formData.pauseUntil" v-if="formData.pauseUntil">until {{ getFormattedDateMinUTC(formData.pauseUntil) }}</option>
              <PauseUntilOptions />
            </template>
          </SelectRow>
          <hr class="form-section mt-1 mb-3" />
          <SelectRow label="Script Name" v-model="formData.scriptName" @change="getPublishedJobVersions">
            <template v-slot:options>
              <option value="">-- Select a Script --</option>
              <option v-for="(script, i) in scripts" :key="i" :value="script.name">{{ script.name }}</option>
            </template>
          </SelectRow>
          <CheckboxInputRow id="useCustomScript" v-model="customVersion" checkboxLabel="Use Custom Script Version" :disabled="!formData.scriptName" class="mb-2" />
          <SelectRow v-if="customVersion" label="Version" v-model="formData.scriptVersion">
            <template v-slot:options>
              <option value="">-- Select a Version --</option>
              <option v-for="(version, i) in publishedVersions" :key="i" :value="version.version">{{ version.version }}</option>
            </template>
          </SelectRow>
          <CheckboxInputRow id="useConcurrency" v-model="concurrency" checkboxLabel="Multiple Concurrent Runs" :disabled="!formData.scriptName" class="mb-2" />
          <TextInputRow v-if="concurrency" label="Concurrency" v-model="formData.concurrency" dataType="integer" />
          <TextAreaRow label="Runtime Args"
            v-model="formData.args"
            :placeholder="'arg1 arg2 arg3 arg4 etc.\n\nno quoted/spaced arguments'"
          />

        </div>
      </template>

      <template v-slot:footer>
        <button class="btn" v-if="!job" @click="createJob()" :disabled="isLoading">
          Create Job
        </button>
        <button class="btn" v-if="job" @click="updateJob()" :disabled="isLoading">
          Update Job
        </button>
        <button class="btn btn-blue ms-2" @click="close()">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import JobStatusOptions from '@/components/selectOptions/JobStatusOptions.vue'
import PauseUntilOptions from '@/components/selectOptions/PauseUntilOptions.vue'
import Reloadables from '@/services/reloadables'
import ModelingDataService from '@/services/ModelingDataService'
import BaseComponent from '@/components/BaseComponent.vue'
import HelpContent from '@/services/HelpContent'

export default {
  extends: BaseComponent,
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: {},
      isCreate: false,
      mode: '',
      publishedVersions: [],
      scripts: [],
      customVersion: false,
      concurrency: false
    }
  },
  props: ['job'],
  components: { Modal, TextInputRow, TextAreaRow, CheckboxInputRow, SelectRow, JobStatusOptions, PauseUntilOptions },
  computed: {
    pauseUntilValue () {
      if (!this.formData.pauseUntil) {
        return ''
      }
      if (this.formData.pauseUntil === '1h') {
        return Date.now() + 3600000 + 300000
      }
      if (this.formData.pauseUntil === '4h') {
        return Date.now() + (4 * 3600000) + 300000
      }
      if (this.formData.pauseUntil === '12h') {
        return Date.now() + (12 * 3600000) + 300000
      }
      if (this.formData.pauseUntil === '1d') {
        return Date.now() + (1 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '3d') {
        return Date.now() + (3 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1w') {
        return Date.now() + (7 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '2w') {
        return Date.now() + (14 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1m') {
        return Date.now() + (30 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '3m') {
        return Date.now() + (91 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '4m') {
        return Date.now() + (122 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '6m') {
        return Date.now() + (184 * 86400000) + 300000
      }
      if (this.formData.pauseUntil === '1y') {
        return Date.now() + (365 * 86400000) + 300000
      }
      return this.formData.pauseUntil
    },
    headerText () {
      if (this.job) {
        return 'Edit Job'
      }
      return 'Add New Job'
    }
  },
  methods: {
    createJob () {
      this.isLoading = true
      if (!this.concurrency || !this.formData.concurrency) {
        this.formData.concurrency = 1
      } else {
        this.formData.concurrency = parseInt(this.formData.concurrency, 10)
        if (this.formData.concurrency < 1) {
          this.formData.concurrency = 1
        }
      }
      ModelingDataService.addJob(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Job Created Succesfully')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Job Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateJob () {
      this.isLoading = true
      const name = this.formData.name
      delete this.formData.name
      if (this.formData.status === 'paused') {
        this.formData.pauseUntil = this.pauseUntilValue
      } else {
        delete this.formData.pauseUntil
      }
      if (!this.customVersion) {
        this.formData.scriptVersion = ''
      }
      if (!this.concurrency || !this.formData.concurrency) {
        this.formData.concurrency = 1
      } else {
        this.formData.concurrency = parseInt(this.formData.concurrency, 10)
        if (this.formData.concurrency < 1) {
          this.formData.concurrency = 1
        }
      }
      ModelingDataService.updateJob(name, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Job Updated Succesfully')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Job Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getScripts () {
      ModelingDataService.listAllScripts()
        .then(response => {
          this.scripts = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Scripts list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
    },
    getPublishedJobVersions () {
      if (!this.formData.scriptName) {
        return
      }
      ModelingDataService.listScriptVersions(this.formData.scriptName)
        .then(response => {
          const out = []
          for (const i in response.data) {
            if (response.data[i].published) {
              out.push(response.data[i])
            }
          }
          this.publishedVersions = out
        })
        .catch(e => {
          let message = 'Failed to load Script Versions'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
    },
    reset () {
      this.getScripts()
      this.publishedVersions = []
      this.customVersion = false
      this.concurrency = false
      if (this.job) {
        this.formData = { ...this.job }
        if (!this.formData.concurrency) {
          this.formData.concurrency = 1
        }
        if (this.job.scriptVersion) {
          this.customVersion = true
        }
        this.concurrency = (this.job.concurrency && this.job.concurrency > 1)
        this.getPublishedJobVersions()
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    }
  }
}
</script>
