<template>
    <option value="1h">for 1h</option>
    <option value="4h">for 4h</option>
    <option value="12h">for 12h</option>
    <option value="1d">for 24h</option>
    <option value="3d">for 3d</option>
    <option value="1w">for 1w</option>
    <option value="2w">for 2w</option>
    <option value="1m">for 1mo</option>
    <option value="3m">for 3mo</option>
    <option value="4m">for 4mo</option>
    <option value="6m">for 6mo</option>
    <option value="1y">for 1y</option>
</template>
