<template>
  <ScriptInfo />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ScriptInfo from '@/components/modeling/ScriptInfo.vue'

@Options({
  components: {
    ScriptInfo
  }
})
export default class ModelingScriptInfo extends Vue {
}
</script>
