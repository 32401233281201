<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        <h5 v-if="job">Start an Ad-Hoc Run</h5>
        <h5 v-else-if="script">Start a Test Run</h5>
      </template>
      <template v-slot:body>
        <StartRunWidget ref="startRunWidget" :job="job" :script="script" :version="version" :updateFunc="updateCanRun" :calledFunc="runCallFinished" />
      </template>
      <template v-slot:footer>
        <button class="btn" :disabled="(!canRun) || isLoading" @click="startRun()">
          Start the Run
        </button>
        <button class="btn btn-blue ms-2" @click="close">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/modals/Modal.vue'
import StartRunWidget from '@/components/modeling/StartRunWidget.vue'
import HelpContent from '@/services/HelpContent'
import Reloadables from '@/services/reloadables'

export default {
  data () {
    return {
      isBlocking: false,
      canRun: false,
      isLoading: false
    }
  },
  props: ['job', 'script', 'version'],
  components: { Modal, StartRunWidget },
  methods: {
    runCallFinished (message = '', isError = false) {
      if (isError) {
        HelpContent.setFlashMessage(message, true)
      } else {
        HelpContent.setTimedFlashMessage('Ad-Hoc Run has been scheduled')
        this.close()
        Reloadables.requestReload()
      }
      this.isLoading = false
    },
    startRun () {
      this.isLoading = true
      this.$refs.startRunWidget.start()
    },
    updateCanRun (t) {
      this.canRun = t
    },
    show () {
      this.isLoading = false
      this.isBlocking = true
      this.$nextTick(() => {
        this.$refs.startRunWidget.refreshVersions()
      })
    },
    close () {
      this.isBlocking = false
    }
  }
}
</script>
