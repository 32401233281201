<template>
  <PreferencesPane ref="prefspane" />
  <SearchResults ref="searchMenu" @searchVisibile="isSearchMenuOpen = true" @searchHidden="isSearchMenuOpen = false"/>
  <SettingsMenu ref="settingsMenu"
    @settingsMenuVisibile="isSettingsMenuOpen = true"
    @settingsMenuHidden="isSettingsMenuOpen = false"
    @showPrefsPane="showPrefsPane()"
  />
  <div class="nav-header">
    <div class="nav-container" style="width:100%;">
      <nav class="navbar navbar-expand-lg navbar-dark branded-navbar">
        <div class="d-flex align-items-center align-content-center justify-content-between" style="width:1200px;margin-left:auto;;margin-right:auto;">
          <div class="d-flex align-content-center align-items-center" style="width:100%;">
            <router-link :to="{  name: 'Home' }" class="navbar-brand">
              <LogoIcon alt="Agrology Logo Icon" height="24" width="24" class="navbar-logo" />
            </router-link>
            <router-link :to="{  name: 'Home' }" class="d-none d-sm-block d-md-none d-lg-block">
              <LogoText height="14" class="navbar-logo-text" />
            </router-link>
            <router-link :to="{  name: 'Home' }" class="d-block d-sm-none d-md-block d-lg-none" v-if="$state.currentSpace === ''">
              <LogoText height="14" class="navbar-logo-text" />
            </router-link>
            <Breadcrumbs v-if="isSignedIn" />
          </div>
          <div class="nav-right-area justify-content-end align-items-center">
            <fa ref="searchIcon" id="searchIcon" icon="search" alt="Search" class="button-icon me-3" style="margin-top:3px;padding:2px;" @click="toggleSearchMenu" :class="{'active-link': isSearchMenuOpen}"/>
            <Reloader />
            <fa ref="settingsIcon" id="settingsIcon" icon="gear" alt="Settings" class="button-icon me-3" style="margin-top:3px;padding:2px;" @click="toggleSettingsMenu" :class="{'active-link': isSettingsMenuOpen}"/>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import PreferencesPane from '@/components/PreferencesPane.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import LogoIcon from '@/components/svg/LogoIcon.vue'
import LogoText from '@/components/svg/LogoText.vue'
import SearchResults from '@/components/menus/SearchResults.vue'
import SettingsMenu from '@/components/menus/SettingsMenu.vue'
import Reloader from '@/components/Reloader.vue'

export default {
  name: 'navbar',
  components: { PreferencesPane, Breadcrumbs, LogoIcon, LogoText, SearchResults, Reloader, SettingsMenu },
  data () {
    return {
      isSearchMenuOpen: false,
      isSettingsMenuOpen: false
    }
  },
  methods: {
    toggleSearchMenu () {
      this.$refs.searchMenu.toggle()
    },
    toggleSettingsMenu () {
      this.$refs.settingsMenu.toggle()
    },
    showPrefsPane () {
      this.$refs.prefspane.show()
    }
  },
  computed: {
    isSignedIn () {
      return this.$state.isSignedIn
    }
  }
}
</script>
