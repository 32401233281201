<template>
  <DecoderWidget ref="decoderWidget" />
  <ModalQRWidget ref="qrWidget" />
  <ModalUplinkRelocator ref="uplinkRelocator" />
  <div class="container justify-content-center big-menu-list">
    <div style="max-width: 350px;">
      <h4>Tools</h4>
      <div class="pt-3 pane-border text-center">
        <ul class="info-pane-list">
          <li>
            <a class="btn mt-2 mb-4" style="width:200px" @click="showPayloadDecoderWidget()">Payload Decoder</a>
          </li>
          <li>
            <a class="btn mb-4" style="width:200px" @click="showQRWidget()">QR Code Generator</a>
          </li>
          <li v-if="isAdmin">
            <a class="btn mb-4" style="width:200px" @click="showUplinkRelocator()">Uplink Relocator</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DecoderWidget from '@/components/tools/DecoderWidget.vue'
import ModalQRWidget from '@/components/tools/ModalQRWidget.vue'
import ModalUplinkRelocator from './UplinkRelocator/ModalUplinkRelocator.vue'
import BaseComponent from '../BaseComponent.vue'
import auth from '@/app/auth'

export default {
  name: 'tools-landing',
  components: { DecoderWidget, ModalQRWidget, ModalUplinkRelocator },
  extends: BaseComponent,
  computed: {
    isAdmin () {
      return auth.isAdmin()
    }
  },
  methods: {
    showPayloadDecoderWidget () {
      this.$refs.decoderWidget.show()
    },
    showQRWidget () {
      this.$refs.qrWidget.show()
    },
    showUplinkRelocator () {
      this.$refs.uplinkRelocator.show()
    }
  }
}
</script>

<style>
</style>
