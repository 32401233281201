import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center px-0 mx-0" }
const _hoisted_2 = { class: "col-auto px-0 mx-0" }
const _hoisted_3 = { class: "col-auto px-0 mx-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_GatewayInfo = _resolveComponent("GatewayInfo")!
  const _component_DevicesByGateway = _resolveComponent("DevicesByGateway")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabBar, { activeTabName: "gateways" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_GatewayInfo)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto d-xl" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DevicesByGateway)
      ])
    ])
  ], 64))
}