<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <InfoPane
    class="dt-info-pane-sm"
    title="Threshold Info"
    v-else-if="threshold"
    :actionFunc="showEditor"
    actionIcon="pen-to-square"
    itemType="Threshold"
  >
    <template v-slot:info>
      <Modal ref="addEditModal" :thresh="threshold" />
      <ul class="info-pane-list">

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.enabled !== 1">
          <div class="col-auto node-inactive-header">
            DISABLED
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.isCritical">
          <div class="col-auto warning">
            <b>This Threshold has been marked Critical</b>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label me-1">ID:</span><span class="font-reduce-1">{{ threshold.id }}</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col-auto contact-row-item" style="white-space:normal;">
            <span class="attribute-label">Alert Audience:&nbsp;</span>
            {{ getAudienceFromDomains(threshold.audienceDomains) }}
            <fa icon="lock" class="lock-icon" v-if="threshold.audienceDomains" />
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.description">
          <div class="col node-notes">
            <span class="attribute-label me-1">Description:</span>{{ threshold.description }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.subject">
          <div class="col node-notes">
            <span class="attribute-label me-1">Alert Title:</span>{{ threshold.subject }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.notes">
          <div class="col node-notes node-general-notes">
            {{ threshold.notes }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.category">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Category:</span> {{ threshold.category }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.scope">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Scope:</span> {{ threshold.scope }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.filter">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Filter:</span> {{ threshold.filter }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.attribute">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Attribute:</span> {{ threshold.attribute }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.source">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Source:</span> {{ threshold.source }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.metric">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Threshold:</span>
            {{ threshold.metric }} {{ threshold.operator }} {{ threshold.startVal }}
            <span v-if="threshold.operator === 'btw'"> and {{ threshold.endVal}}</span>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.compareTo">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Compare To:</span> {{ threshold.compareTo }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.triggerTrendSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Trigger Trend:</span> {{ threshold.triggerTrendSecs }}s
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.triggerTemplates">
          <div class="col-auto contact-row-item" style="white-space:normal;">
            <span class="attribute-label">Triggers when Threshold is crossed:</span>
            <ul>
              <li v-for="tmpl in parsedTemplates" v-bind:key="tmpl.id">
                {{ tmpl.medium }} - {{ tmpl.description }}
              </li>
            </ul>
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.resolveTrendSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Resolve Trend:</span> {{ threshold.resolveTrendSecs }}s
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.resolveTemplates">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Resolved Template:</span> {{ threshold.resolveTemplates }}
          </div>
        </li>

        <!-- <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.flapBufferSecs">
          <div class="col-auto contact-row-item" >
            <span class="attribute-label">Flapping Trend:</span> {{ threshold.flapBufferSecs }}s
          </div>
        </li> -->

        <li class="info-pane-list-item d-flex align-items-center" v-if="threshold.lastModified">
          <span class="subtle-info status-last-modified me-1">
            {{ getSinceSecs(threshold.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="threshold.lastModifiedBy"> by {{ threshold.lastModifiedBy }}</span>
        </li>

      </ul>

    </template>
  </InfoPane>
</template>

<script>
import BaseThresholdComponent from '@/components/notifications/BaseThresholdComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import InfoPane from '@/components/tables/InfoPane.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/notifications/ModalAddUpdateThreshold.vue'

export default {
  name: 'threshold',
  extends: BaseThresholdComponent,
  data () {
    return {
      thresholdBeforeEdit: {},
      notifTemplates: [],
      audiences: {
        'Customers & Staff': '',
        'Staff Only': 'agrology.ag,virga.io'
      },
      threshold: null
    }
  },
  computed: {
    parsedTemplates () {
      if (!this.threshold || !this.threshold.triggerTemplates || !this.templates) {
        return []
      }
      var mapped = []
      for (const n of this.notifTemplates) {
        for (const t of this.templates) {
          if (t.id === n) {
            mapped.push(t)
          }
        }
      }
      return mapped
    }
  },
  methods: {
    showEditor () {
      this.$refs.addEditModal.show()
    },
    newNotifTemplate () {
      this.notifTemplates.push('')
    },
    remNotifTemplate (idx) {
      this.notifTemplates.splice(idx, 1)
    },
    getAudienceFromDomains (domains) {
      if (!domains) {
        return 'Customers & Staff'
      }
      for (const key in this.audiences) {
        if (this.audiences[key] === domains) {
          return key
        }
      }
      return domains
    },
    updateNotifTemplates () {
      if (!this.threshold || !this.threshold.triggerTemplates) {
        this.notifTemplates = []
        return
      }
      this.notifTemplates = this.threshold.triggerTemplates.replaceAll(' ', '').split(',')
    },
    refreshInfo () {
      this.getThreshold(this.$route.params.id)
    },
    getThreshold (id) {
      this.isLoading = true
      NotificationsDataService.getThreshold(id)
        .then(response => {
          this.threshold = response.data
          this.setNavThreshold(this.threshold.description)
          this.thresholdBeforeEdit = Object.assign({}, this.threshold)
          this.$state.creationName = this.threshold.id
          if (!this.threshold.audienceDomains) {
            this.threshold.audienceDomains = ''
          }
          this.updateNotifTemplates()
        })
        .catch(e => {
          let message = 'Failed to Load Threshold Info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.refreshInfo()
    this.setNavThreshold(this.$route.params.id)
    this.retrieveAllTemplates()
    Reloadables.registerReloadable('threshold-info', this.refreshInfo)
  },
  unmounted () {
    Reloadables.unregisterReloadable('threshold-info')
  },
  components: {
    InfoPane,
    Modal
  }
}
</script>

<style>
  .btn-blue {
    margin-left: 20px;
  }
</style>
