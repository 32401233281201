<template>
  <div>
    <ModalRunInfo ref="runInfoModal" :run="activeRun" :reloadFunc="reloadFunc" />
    <ModalStartRun ref="startRunModal" :script="script" />
    <DataTable class="dt-modeling-runs mb-2"
      title="Recent Test Runs" :cols="cols" :rows="rows" :loading="loading"
      :actionFunc="showNewRunModal"
      actionIcon="play"
      actionLabel=" Start Test Run"
      noDataMessage="No Recent Test Runs for this Script"
      :initialSort="0"
    >
    </DataTable>
    <span class="subtle font-reduce-2 ms-1">Scheduled → Starting → Running → Completed / Failed</span>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import BaseComponent from '@/components/BaseComponent.vue'
import ModalRunInfo from '@/components/modeling/ModalRunInfo.vue'
import ModalStartRun from '@/components/modeling/ModalStartRun.vue'

export default {
  extends: BaseComponent,
  components: { DataTable, ModalRunInfo, ModalStartRun },
  props: ['script', 'runs', 'loading', 'reloadFunc', 'runID'],
  data () {
    return {
      activeRun: null,
      activeRunIndex: -1,
      cols: [
        { icon: 'clock', sort: 0 },
        { text: 'Version', sort: 1 },
        { text: 'Status', sort: 2 },
        { icon: 'chart-area' }
      ]
    }
  },
  name: 'modeling-test-runs-list',
  watch: {
    runs: {
      handler () {
        if (this.runs && this.runs.length > this.activeRunIndex) {
          this.activeRun = this.runs[this.activeRunIndex]
        }
        this.checkDeepLink()
      }
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.runs && this.runs.length > 0) {
        for (const i in this.runs) {
          out.push([
            { value: this.getSinceSecs(this.runs[i].startTime) + ' ago', click: this.showRunInfoModal, i: i, sortValue: (Date.now() / 1000) - this.runs[i].startTime },
            { value: this.runs[i].scriptVersion },
            {
              value: this.runs[i].status,
              class: {
                notable: this.runs[i].status === 'running' || this.runs[i].status === 'starting' || this.runs[i].status === 'scheduled',
                subtle: this.runs[i].status === 'completed' || this.runs[i].status === 'canceled',
                'status-danger': this.runs[i].status === 'failed'
              }
            },
            { extLink: this.getLink(this.runs[i]), value: this.noLinkVal(this.runs[i]) }
          ])
        }
      }
      return out
    }
  },
  methods: {
    checkDeepLink () {
      if (this.runID && this.runs && this.runs.length > 0) {
        for (const i in this.runs) {
          if (this.runs[i].startTime && this.runs[i].startTime.toString() === this.runID) {
            this.showRunInfoModal(i)
          }
        }
      }
    },
    noLinkVal (run) {
      if (run.status !== 'completed' || (run.status === 'completed' && !run.chartURL)) {
        return '--'
      }
      return ''
    },
    getLink (run) {
      if (!run.chartURL) {
        return ''
      }
      return run.chartURL
    },
    showNewRunModal () {
      this.$refs.startRunModal.show()
    },
    showRunInfoModal (i) {
      this.activeRunIndex = i
      this.activeRun = this.runs[i]
      this.$nextTick(() => {
        this.$refs.runInfoModal.show()
      })
    }
  },
  mounted () {
    if (this.runID) {
      this.checkDeepLink()
    }
  }
}
</script>
