<template>
  <div>
    <div class="font-reduce-2 pb-1 mt-3 d-flex">
      <div class="bold">
        {{  role.label  }}
      </div>
      <div class="ms-auto me-2">
        <fa class="button-icon button-icon-table" :icon="icon"  v-if="action" @click="doAction"/>
        <fa class="button-icon button-icon-table" :icon="icon"  v-else />
      </div>
    </div>
    <div v-if="role.description" class="subtle font-reduce-2 wrap">
      {{  role.description }}
    </div>
    <div v-if="role.sensitive" class="status-caution font-reduce-2 mt-2 d-flex wrap">
      <fa icon="warning" class="icon-regular status-caution mt-1" />
      <label class="ms-2 status-caution width-330 wrap">
        <span>This access is highly-sensitive and would be catastrophic in the wrong hands.</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: ['role', 'action', 'actionParam1', 'actionParam2', 'actionParam3', 'icon'],
  methods: {
    doAction () {
      if (this.actionParam1 && this.actionParam2 && this.actionParam3 && this.action) {
        this.action(this.actionParam1, this.actionParam2, this.actionParam3)
      } else if (this.action) {
        this.action()
      }
    }
  }
}
</script>
