<template>
  <ul class="nav nav-tabs">
    <li class="nav-item" v-if="hasAlertsPerm">
        <router-link :to="{  path: '/alerts' }" class="nav-link" v-bind:class="{'active': activeTab === 'alerts'}">Alerts</router-link>
    </li>
    <li class="nav-item" v-if="hasThresholdsPerm">
        <router-link :to="{  name: 'threshold-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'thresholds'}">Thresholds</router-link>
    </li>
    <li class="nav-item" v-if="hasTemplatesPerm">
        <router-link :to="{  name: 'template-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'templates'}">Templates</router-link>
    </li>
    <li class="nav-item d-sm-none" v-if="hasSubscriptionsPerm">
        <router-link :to="{  name: 'subscription-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'subscriptions'}">Subs</router-link>
    </li>
    <li class="nav-item d-none d-sm-inline" v-if="hasSubscriptionsPerm">
        <router-link :to="{  name: 'subscription-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'subscriptions'}">Subscriptions</router-link>
    </li>
    <li class="nav-item" v-if="hasThresholdsPerm">
        <router-link :to="{  name: 'ads-list' }" class="nav-link" v-bind:class="{'active': activeTab === 'ads'}">ADS</router-link>
    </li>
    <li class="nav-item d-none d-sm-inline" v-if="hasExclusionZonesPerm">
        <router-link :to="{  name: 'notifications-misc' }" class="nav-link" v-bind:class="{'active': activeTab === 'misc'}">Misc</router-link>
    </li>
  </ul>
</template>
<script>
import BaseNotificationComponent from '@/components/notifications/BaseNotificationComponent.vue'

export default {
  extends: BaseNotificationComponent,
  data () {
    return {
      activeTab: this.activeTabName
    }
  },
  props: ['activeTabName']
}
</script>
