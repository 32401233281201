<template>
    <div class="navbar-nav d-block justify-content-start justify-items-start align-items-center" style="white-space:nowrap;" >

        <ul class="nav">
            <li id="l1-li" ref="l1-li" class="nav-item dropdown nav-breadcrumbs" v-if="$route && $route.name !== 'Home'">
                <span id="l1-s" ref="l1-s" class="notable"></span>
                <a class="nav-link" id="l1-a" ref="l1-a" ></a>
          </li>
            <li class="nav-item nav-breadcrumbs d-none d-md-block d-lg-block d-xl-block" id="l2-li" ref="l2-li">
                <span id="l2-s" ref="l2-s" class="notable"></span>
                <a class="nav-link" id="l2-a" ref="l2-a" href="#"></a>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-md-block d-lg-block d-xl-block" id="l3-li" ref="l3-li">
                <span id="l3-s" ref="l3-s" class="notable"></span>
                <a class="nav-link" id="l3-a" ref="l3-a" href="#"></a>
            </li>
            <li class="nav-item nav-breadcrumbs d-none d-sm-block" id="l4-li">
                <span id="l4-s" ref="l4-s" class="notable"></span>
                <a class="nav-link" id="l4-a" ref="l4-a" href="#"></a>
            </li>
        </ul>

    </div>
</template>
<script>
export default {
  name: 'breadcrumbs',
  methods: {
  }
}
</script>
