import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container mx-0 px-0 mt-2" }
const _hoisted_2 = { class: "row mx-0 px-0" }
const _hoisted_3 = { class: "col mx-auto px-0 mb-3 pb-2" }
const _hoisted_4 = { class: "col mx-auto px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JobsList = _resolveComponent("JobsList")!
  const _component_ScriptsList = _resolveComponent("ScriptsList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_JobsList)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-none d-md-block col-auto mx-3 p-0" }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ScriptsList)
      ])
    ])
  ]))
}