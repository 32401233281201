<template>
  <div>
    <ModalSubmissionForm ref="chamberadd"  elementType="Flux Chamber Type" :fields1="formFields" :createFunc="createChamber" />
    <ModalSubmissionForm ref="chamberedit" elementType="Flux Chamber Type" :data="chamberData" :fields1="formFields" :updateFunc="updateChamber" />
    <DataTable
      class="dt-chamberslist"
      title="Flux Chamber Types"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="showChamberAdd"
      actionIcon="plus"
      itemType="Chamber"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import ModalSubmissionForm from '@/components/forms/ModalSubmissionForm.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'fluxChambers-list',
  extends: BaseFleetComponent,
  data () {
    return {
      fluxChambers: {},
      chamberData: undefined,
      cols: [
        { text: 'Chamber Name' },
        { text: 'Volume' },
        { text: 'Surface Area' },
        { text: '# Vent Holes' }
      ],
      loading: false
    }
  },
  computed: {
    formFields () {
      return [
        {
          field: 'id',
          label: 'ID',
          controlType: 'id',
          hideLabel: true
        },
        {
          field: 'displayName',
          label: 'Display Name',
          controlType: 'textInput',
          required: true,
          dataType: 'description'
        },
        {
          field: 'description',
          label: 'Description',
          controlType: 'textInput',
          dataType: 'description'
        },
        {
          field: 'volume',
          label: 'Volume',
          controlType: 'textInput',
          required: true,
          dataType: 'decimal',
          inputClass: 'width-115',
          suffix: 'L'
        },
        {
          field: 'surfaceArea',
          label: 'Surface Area',
          controlType: 'textInput',
          required: true,
          dataType: 'decimal',
          inputClass: 'width-115',
          suffix: 'm²'
        },
        {
          field: 'ventHoleCount',
          label: '# Vent Holes',
          controlType: 'textInput',
          required: true,
          dataType: 'integer',
          inputClass: 'width-55',
        }
      ]
    },
    rows () {
      const out = []
      if (this.fluxChambers && this.fluxChambers.length > 0) {
        for (const chamber of this.fluxChambers) {
          out.push([
            { value: chamber.displayName, click: this.showChamberEditor, i: chamber, class: this.fluxChamberLinkColor(chamber, '') },
            { value: chamber.volume },
            { value: chamber.surfaceArea },
            { value: chamber.ventHoleCount }
          ])
        }
      }
      return out
    }
  },
  methods: {
    preparePayload (data) {
      if ('volume' in data){
        data.volume = parseFloat(data.volume)
      }
      if ('surfaceArea' in data){
        data.surfaceArea = parseFloat(data.surfaceArea)
      }
      if ('ventHoleCount' in data){
        data.ventHoleCount = parseInt(data.ventHoleCount)
      }
    },
    async updateChamber (data) {
      this.preparePayload(data)
      await FleetDataService.updateChamber(data.id, data)
    },
    async createChamber (data) {
      this.preparePayload(data)
      await FleetDataService.createChamber(data)
    },
    showChamberEditor (chamber) {
      this.chamberData = chamber
      this.$nextTick(() => {
        this.$refs.chamberedit.show()
      })
    },
    showChamberAdd () {
      this.$refs.chamberadd.show()
    },
    fluxChamberLinkColor (fluxChamber, baseClass) {
      let classes = baseClass
      if (fluxChamber.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.loading = true
      FleetDataService.getAllFluxChambers()
        .then(response => {
          this.fluxChambers = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('flux-chambers-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('flux-chambers-list')
  },
  components: { DataTable, ModalSubmissionForm }
}
</script>
