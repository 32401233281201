<template>
  <div>
    <DataTable
      class="dt-recent-alerts"
      :title="'Recent Alerts (' + rows.length + ')'"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      noDataMessage="No Alerts Listed"
      :initialSort="1"
    >
      <template v-slot:filter-pillbox>
        <div class="btn-group dt-pillbox" role="group" aria-label="Alert State Selector">
          <input type="radio" class="btn-check" value="recent" name="listFilter" id="listFilter-all" v-model="listFilter" autocomplete="off" />
          <label class="btn btn-outline-primary" for="listFilter-all">&nbsp;All&nbsp;</label>

          <input type="radio" class="btn-check" value="active" name="listFilter" id="listFilter-active" v-model="listFilter" autocomplete="off">
          <label class="btn btn-outline-primary" for="listFilter-active">Active</label>

          <input type="radio" class="btn-check" value="resolved" name="listFilter" id="listFilter-resolved" v-model="listFilter" autocomplete="off">
          <label class="btn btn-outline-primary" for="listFilter-resolved">Resolved</label>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'alerts-recent-list',
  extends: BaseComponent,
  data () {
    return {
      listFilter: '',
      cols: [
        { text: 'Alert ID', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Status', sort: 2 },
        { text: 'Category', sort: 3 },
        { text: 'Customer', sort: 4 },
        { text: 'Threshold', sort: 5 },
        { text: 'Details', sort: 6 }
      ],
      alerts: [],
      thresLkp: {}
    }
  },
  watch: {
    listFilter: {
      handler () {
        localStorage.setItem('recentAlertsTab', this.listFilter)
      }
    }
  },
  computed: {
    rows () {
      const out = []

      let alerts = this.alerts
      if (this.listFilter === 'active') {
        alerts = this.activeAlerts
      } else if (this.listFilter === 'resolved') {
        alerts = this.resolvedAlerts
      }

      if (alerts && alerts.length > 0) {
        for (const alert of alerts) {
          out.push([
            { value: this.getLastWord(alert.id), route: 'alert-details', params: { id: alert.id }, class: { inactive: alert.status === 'inactive' } },
            { value: this.getSinceSecs(alert.lastModified), sortValue: (Date.now() / 1000) - alert.lastModified },
            { value: alert.status, class: { notable: alert.status === 'active', 'subtle-info': alert.status === 'resolved' } },
            { value: alert.category },
            { value: FleetDataService.getCustomerDisplayName(alert.customer) },
            { value: this.getThreshDisplayname(alert.threshold) },
            { value: alert.detail }
          ])
        }
      }
      return out
    },
    activeAlerts () {
      var active = []
      for (const alert of this.alerts) {
        if (alert.status === 'resolved') {
          continue
        }
        active.push(alert)
      }
      return active
    },
    resolvedAlerts () {
      var resolved = []
      for (const alert of this.alerts) {
        if (alert.status === 'resolved') {
          resolved.push(alert)
        }
      }
      return resolved
    }
  },
  methods: {
    getThreshDisplayname (id) {
      if (id in this.thresLkp) {
        return this.thresLkp[id].description
      }
      return id
    },
    refreshList () {
      this.isLoading = true
      let isLoadingThresh = false
      let isLoadingAlerts = false
      NotificationsDataService.getAllThresholds()
        .then(response => {
          for (const thresh of response.data) {
            this.thresLkp[thresh.id] = thresh
          }
        })
        .catch(e => {
          let message = 'Unable to Load Threshold List'
          console.log(e)
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          isLoadingThresh = true
          this.isLoading = isLoadingThresh && isLoadingAlerts
        })
      NotificationsDataService.getRecentAlerts()
        .then(response => {
          this.alerts = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Alert List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          isLoadingAlerts = true
          this.isLoading = isLoadingThresh && isLoadingAlerts
        })
    },
    alertLinkColor (alert, baseClass) {
      let classes = baseClass
      if (alert.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    }
  },
  mounted () {
    this.listFilter = localStorage.getItem('recentAlertsTab')
    if (!this.listFilter) {
      this.listFilter = 'recent'
    }
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel2('Notifications', 'alerts', '#/notifications', '')
    Reloadables.registerReloadable('alerts-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('alerts-list')
  },
  components: { DataTable }
}
</script>
