<template>
    <div>
        <Modal ref="createModal" />
        <DataTable
          class="dt-modeling-scripts"
          title="Scripts"
          :cols="cols"
          :rows="rows"
          :loading="loading"
          :actionFunc="showCreateScript"
          actionIcon="plus"
          itemType="Script"
          noDataMessage="No Scripts"
          :initialSort="0"
        >
          <template v-slot:filter-pillbox>
          </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import ModelingDataService from '@/services/ModelingDataService'
import Modal from '@/components/modeling/ModalAddUpdateScript.vue'
import HelpContent from '@/services/HelpContent'
import Reloadables from '@/services/reloadables'

export default {
  components: { DataTable, Modal },
  data () {
    return {
      loading: true,
      scriptsData: [],
      cols: [
        { text: 'Name', sort: 0 },
        { text: 'Active Ver', sort: 1 },
        { text: 'Description', sort: 2 }
      ]
    }
  },
  name: 'modeling-scripts-list',
  computed: {
    rows () {
      const out = []
      if (this.scriptsData && this.scriptsData.length > 0) {
        for (const i in this.scriptsData) {
          out.push([
            { value: this.scriptsData[i].name, route: 'modeling-scriptinfo', params: { id: this.scriptsData[i].name } },
            { value: this.scriptsData[i].activeVersion },
            { value: this.scriptsData[i].description }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showCreateScript () {
      this.$refs.createModal.show()
    },
    loadScripts () {
      this.loading = true
      ModelingDataService.listAllScripts()
        .then(result => {
          this.scriptsData = result.data
        })
        .catch(e => {
          let message = 'Unable to fetch Scripts List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('scripts-list', this.loadScripts)
    this.loadScripts()
  },
  unmounted () {
    Reloadables.unregisterReloadable('scripts-list')
  }
}
</script>
