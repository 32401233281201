<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking && (isCreate || node) && site" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <div class="pillbox-wrapper">
          <Pillbox :pills="pills" :activePillChanged="activePillChanged" :defaultPill="activeSection" />
        </div>

        <hr class="form-sep" />

        <form ref="nodeForm" @submit.prevent="handleSubmit" autocomplete="off">

          <div v-if="!isCreate" class="ps-2 mt-0 mb-3 pe-2 pb-2 add-border-bottom subtle">
            <ElementIdRow label="ID" :value="formData.id" :hideLabel="true" />
          </div>

          <div v-if="activeSection === 'general'">

            <TextLabelRow label="Site"
              :value="site.displayName"
              valueClass="subtle ms-1"
            />

            <TextInputRow label="Display Name"
              v-model="formData.displayName"
              labelClass="label-required"
              :required="true"
              placeholder="B21, Hill, North, 6, etc."
              maxlength="36"
              dataType="description"
            />

            <SelectRow label="Status" v-model="formData.status" :required="true">
              <template v-slot:options>
                <GeneralStatusOptions />
              </template>
            </SelectRow>

            <TextInputRow label="Description"
              v-model="formData.description"
              labelClass="label-required"
              placeholder=""
              maxlength="48"
              dataType="description"
            />

            <GPSInputRow label="GPS"
              v-model="formData"
            />

            <TextInputRow label="Notes"
              v-model="formData.notes"
              placeholder="Northeast End, Lot 57, etc."
              maxlength="28"
              dataType="notes"
            />

          </div>

          <div v-if="activeSection === 'crops&soil'">

            <SelectRow label="Crop" v-model="formData.crop" v-if="site.products && site.products.length > 0">
              <template v-slot:options>
                <option value="">-- Select Crop --</option>
                <option v-for="crop in crops" :value="crop" :key="crop">{{ crop }}</option>
              </template>
            </SelectRow>

            <SelectRow label="Soil Type" v-model="formData.soilType" :required="true">
              <template v-slot:options>
                <SoilTypeOptions />
              </template>
            </SelectRow>

            <TextInputRow label="Soil Density"
              v-model="formData.soilDensity"
              labelClass="label-required"
              :required="true"
              placeholder=""
              maxlength="10"
              dataType="decimal"
              suffix="g/cm³"
              inputClass="width-55"
            />

            <TextInputRow label="Act. Soil Depth"
              v-model="formData.activeSoilDepth"
              labelClass="label-required font-reduce-2"
              :required="true"
              placeholder=""
              maxlength="10"
              dataType="decimal"
              suffix="cm"
              inputClass="width-55"
            />
          
          </div>

          <div v-if="activeSection === 'role&layout'">

            <SelectRow
              label="Node Role"
              v-model="formData.role"
              @change="getLayoutsByFunction"
            >
              <template v-slot:options>
                <option value="">None</option>
                <NodeRoleOptions />
              </template>
            </SelectRow>

            <SelectRow
              label="Node Layout"
              v-model="formData.layout"
              :disabled="!formData.role || isLoading"
            >
              <template v-slot:options>
                <option value="">None</option>
                <option v-for="(layout, i) in roleLayouts" :key="i" :value="layout.id">{{  layout.displayName  }}</option>
              </template>
            </SelectRow>

            <CheckboxInputRow label=""
              v-model="formData.isPendingDeployment"
              checkboxLabel="Deployment is Pending"
              id="cbDeploymentPending"
              :disabled="!formData.role || !formData.layout"
            />

            <SelectRow v-if="hasChamber"
              v-model="formData.chamberType"
              label="Chamber Type"
            >
              <template v-slot:options>
                <option value="">None</option>
                <option v-for="(chamberType, i) in chamberTypes" :key="i" :value="chamberType.id">{{ chamberType.displayName }}</option>
              </template>
            </SelectRow>

            <SelectRow v-if="hasChamber && formData.chamberType"
              v-model="formData.ventType"
              label="Vent Type"
            >
              <template v-slot:options>
                <option value="">None</option>
                <option v-for="(ventType, i) in ventTypes" :key="i" :value="ventType.id">{{ ventType.manufacturer }} / {{ ventType.model }}</option>
              </template>
            </SelectRow>

            <TextInputRow  v-if="hasChamber && formData.chamberType && formData.ventType"
              label="# Open Vents"
              labelClass="font-reduce-2"
              placeholder=""
              maxlength="1"
              dataType="integer"
              inputClass="width-55"
              v-model="formData.openVentCount"
            />

            <div class="warning ms-5" v-if="hasChamber && tooManyHoles">Max Open Vent Holes is {{ maxOpenHoles }}</div>

            <hr class="form-sep" v-if="layoutSlots.length !== 0" />

            <div v-if="layoutSlots.length !== 0" class="centered my-3 ms-2 font-reduce-2">
              <table>
                <tr v-for="(slot, i) in layoutSlots" :key="i">
                  <td class="px-2" v-if="!slot[1]">❌</td>
                  <td class="px-2 status-ok" v-else>✓</td>
                  <td class="pe-3">{{ slot[0] }}</td>
                  <td class="pe-2 warning" colspan="2" v-if="!slot[1] && !slot[4]">{{ slot[2] }}</td>
                  <td class="pe-2 warning" v-else-if="!slot[1]">{{ slot[2] }}</td>
                  <td class="pe-2" v-else>{{ slot[2] }}</td>
                  <td v-if="slot[3]">{{ slot[3] }}</td>
                </tr>
              </table>
            </div>

          </div>

          <LastModifiedRow :ts="formData.lastModified" :author="formData.lastModifiedBy" v-if="formData.lastModified"/>

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateNode()" v-if="!isCreate" :disabled="!canSubmit">Submit</button>
          <button class="btn" @click="createNode()" :disabled="!canSubmit" v-else>Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'
import { getSlotData } from '@/components/fleet/nodeLayout.ts'

import Modal from '@/components/modals/Modal.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import GPSInputRow from '@/components/forms/GPSInputRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import ElementIdRow from '@/components/forms/ElementIdRow.vue'
import Pillbox from '@/components/controls/Pillbox.vue'
import NodeRoleOptions from '@/components/selectOptions/NodeRoleOptions.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import SoilTypeOptions from '@/components/selectOptions/SoilTypeOptions.vue'

export default {
  name: 'node-add-update',
  props: ['site', 'node', 'deviceList'],
  data () {
    return {
      isBlocking: false,
      formData: {
        status: 'active',
        soilDensity: 1.3,
        soilType: 'generic',
        activeSoilDepth: 15,
        openVentCount: 0,
      },
      isCreate: false,
      isLoading: false,
      crops: [],
      ventTypes: [],
      chamberTypes: [],
      oldRoute: null,
      mode: '',
      activeSection: 'general',
      roleLayouts: [],
    }
  },
  computed: {
    tooManyHoles () {
      if (!this.hasChamber || !this.formData.openVentCount) {
        return false
      }
      return this.maxOpenHoles < this.formData.openVentCount
    },
    maxOpenHoles () {
      if (!this.hasChamber || !this.formData.chamberType) {
        return 0
      }
      for (const chmbr of this.chamberTypes) {
        if (chmbr.id === this.formData.chamberType) {
          return chmbr.ventHoleCount
        }
      }
      return 0
    },
    hasChamber () {
      return this.currentLayout && 'positions' in this.currentLayout &&
      'Soil Chamber' in this.currentLayout.positions
    },
    nodeDevices () {
      const out = []
      if (!this.formData.id || !this.deviceList || this.deviceList.length === 0) {
        return out
      }
      for (const dev of this.deviceList) {
        if (dev.node !== this.formData.id) {
          continue
        }
        out.push(dev)
      }
      return out
    },
    layoutSlots () {
      return getSlotData(this.currentLayout, this.nodeDevices)
    },
    currentLayout () {
      if (!this.formData.layout || !this.roleLayouts || this.roleLayouts.length === 0) {
        return undefined
      }
      for (const layout of this.roleLayouts) {
        if (layout && 'id' in layout && layout.id === this.formData.layout) {
          return layout
        }
      }
      return undefined
    },
    nodeDevices () {
      const out = []
      if (!this.formData.id || !this.deviceList || this.deviceList.length === 0) {
        return out
      }
      for (const d of this.deviceList) {
        if (!('node' in d) || d.node !== this.formData.id) {
          continue
        }
        out.push(d)
      }
      return out
    },
    pills () {
      return [
        {label: 'General'},
        {label: 'Role & Layout'},
        {label: 'Crops & Soil'},
      ]
    },
    headerText () {
      if (this.node) {
        return 'Edit Node'
      }
      return 'Add New Node'
    },
    canSubmit () {
      if (this.isLoading || !this.formData.displayName || 
        this.formData.displayName.trim() === '' ||
        !this.formData.soilType || !this.formData.soilDensity ||
        !this.formData.activeSoilDepth || this.tooManyHoles) {
        return false  
      }
      return true
    }
  },
  methods: {
    activePillChanged (value) {
      if (value) {
        this.activeSection = value
      }
    },
    reset () {
      this.mode = ''
      if (this.site && this.site.products && this.site.products.length > 0) {
        this.getCrops()
      }
      if (this.node) {
        this.formData = { ...this.node }
        this.getLayoutsByFunction()
        if (this.node.crops && this.node.crops.length > 0) {
          this.formData.crop = this.node.crops[0]
        }
        if (!this.formData.openVentCount) {
          this.formData.openVentCount = 0
        }
        this.isCreate = false
      } else {
        this.isCreate = true
        this.formData = {
          status: 'active',
          soilDensity: 1.3,
          soilType: 'generic',
          activeSoilDepth: 15,
          openVentCount: 0
        }
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
      let path = this.$route.href
      let st = path.indexOf('/node/')
      if (st > 0) {
        path = path.substring(0, st)
      }
      this.oldRoute = path
      if (this.node && this.node.id) {
        history.pushState(
          {},
          null,
          path + '/node/' + this.node.id
        )
      }
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      history.pushState(
        {},
        null,
        this.oldRoute
      )
    },
    getChamberTypes () {
      this.isLoading = true
      FleetDataService.getAllFluxChambers()
        .then(response => {
          this.chamberTypes = response.data
        })
        .catch(e => {
          let message = 'Failed to load chamberTypes List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getVentTypes () {
      this.isLoading = true
      FleetDataService.getAllChamberVents()
        .then(response => {
          this.ventTypes = response.data
        })
        .catch(e => {
          let message = 'Failed to ventTypes List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getCrops () {
      this.isLoading = true
      if (!this.site.products || this.site.products.length === 0) {
        this.crops = []
        return
      }
      FleetDataService.getProduct(this.site.products[0])
        .then(response => {
          this.crops = response.data.crops
        })
        .catch(e => {
          let message = 'Failed to load crops List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateNode () {
      if ((this.formData.lat !== '' && (this.formData.lat > 90 || this.formData.lat < -90)) ||
        (this.formData.long !== '' && (this.formData.long > 180 || this.formData.long < -180))) {
        HelpContent.setFlashMessage('Invalid Lat/long: ' + this.formData.lat + ',' + this.formData.long, true)
        return
      }
      this.formData.displayName = this.formData.displayName.trim()
      this.isLoading = true
      const name = this.formData.id
      const site = this.formData.site
      delete this.formData.id
      delete this.formData.site
      if (this.formData.crop) {
        this.formData.crops = this.formData.crop
        delete this.formData.crop
      }
      FleetDataService.updateSiteNode(this.site.id, this.node.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Node Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Node Update Failed'
          this.formData.id = name
          this.formData.site = site
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createNode () {
      if ((this.formData.lat !== '' && (this.formData.lat > 90 || this.formData.lat < -90)) ||
        (this.formData.long !== '' && (this.formData.long > 180 || this.formData.long < -180))) {
        HelpContent.setFlashMessage('Invalid Lat/long: ' + this.formData.lat + ',' + this.formData.long, true)
        return
      }
      this.isLoading = true
      this.formData.displayName = this.formData.displayName.trim()
      if (this.formData.crop) {
        this.formData.crops = this.formData.crop
        delete this.formData.crop
      }
      FleetDataService.createSiteNode(this.site.id, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Node Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Node Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Node Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getLayoutsByFunction () {
      if (!this.formData.role) {
        return
      }
      this.isLoading = true
      FleetDataService.listNodeLayoutsByFunction(this.formData.role)
        .then(response => {
          this.roleLayouts = response.data
        })
        .catch(e => {
          let message = 'Failed to list Device Group Layouts for Function ' + this.formData.role
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          console.log('ERROR', e)
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getChamberTypes()
      this.getVentTypes()
    })
  },
  components: { Modal, GPSInputRow, TextInputRow, ElementIdRow, TextLabelRow, SelectRow, LastModifiedRow, GeneralStatusOptions, SoilTypeOptions, Pillbox, NodeRoleOptions, CheckboxInputRow }
}
</script>
