<template>
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <span class="list-heading-label" v-if="label && !hideLabel">
        {{ label }}:
      </span>
      <span :class="valueClass">
        {{ value }}
      </span>
    </div>
    <div>
      <fa icon="copy" class="button-icon ms-1" @click="copyToClipboard" />
    </div>
  </div>
</template>
<script>
import HelpContent from '@/services/HelpContent'

export default {
  props: ['label', 'value', 'hideLabel', 'copyValue', 'disableVariableSize'],
  computed: {
    valueClass () {
      if (this.disableVariableSize) {
        return undefined
      }
      if (this.value && this.value.length >= 36) {
        return 'font-reduce-3'
      }
      return 'font-reduce-1'
    }
  },
  methods: {
    copyToClipboard () {
      const val = this.copyValue ? this.copyValue : this.value
      navigator.clipboard.writeText(val)
      HelpContent.setTimedFlashMessage(this.label + ' copied to clipboard')
    }
  }
}
</script>
