<template>
    <div>
        <Menu @updateSettings="updateSettings" />
        <div>
          <MapControl
            :height="mapHeight"
            :markers="markers"
            :useBounds="true"
            :boundBorderPct="10"
            v-if="markers && markers.length > 0"
          />
        </div>
    </div>
</template>
<script>
import { NewMarkers, NewMarker, NewMapMarkerContent, NewMapMarkerContentItem } from '@/components/map/MapControl.ts'
import { NewNodeLookup, NewDevice, NewDevices } from '@/components/fleet/FleetMap/Topology.ts'
import MapControl from '@/components/map/MapControl.vue'
import Menu from '@/components/fleet/FleetMap/Menu.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'FleetMap',
  components: {
    MapControl,
    Menu
  },
  data () {
    return {
      show: '',
      showGW: '',
      mapHeight: (window.innerHeight - 200) + 'px',
      isActive: true,
      defaultZoom: 3.7
    }
  },
  methods: {
    updateHeightLoop () {
      if (!this.isActive) {
        return
      }
      this.mapHeight = (window.innerHeight - 200) + 'px'
      setTimeout(this.updateHeightLoop, 150)
    },
    updateSettings () {
      if (window.innerWidth < 400) {
        this.defaultZoom = 2
      } else if (window.innerWidth < 500) {
        this.defaultZoom = 2.2
      }
      const show = localStorage.getItem('prefs.fleetmap-node-scope')
      const showGW = localStorage.getItem('prefs.fleetmap-gw-scope')
      if (!show) {
        this.show = 'all'
      } else {
        this.show = show
      }
      if (!showGW) {
        this.showGW = 'all'
      } else {
        this.showGW = showGW
      }
    },
    updateSearchData () {
      if (FleetDataService.manifest.data) {
        return
      }
      FleetDataService.getSearchIndexURL()
        .then(resp => {
          if (resp.data && resp.data.url) {
            FleetDataService.getSearchIndex(resp.data.url)
              .then(response => {
                FleetDataService.setManifest(response.data)
              })
              .catch(e => {
                console.log('error downloading index file', e)
              })
          }
        })
        .catch(e => {
          console.log('failed to fetch search index url', e)
        })
    }
  },
  mounted () {
    this.updateSettings()
    this.updateSearchData()
    this.updateHeightLoop()
  },
  unmounted () {
    this.isActive = false
  },
  computed: {
    gatewaysFromTopology () {
      const list = []
      if (!FleetDataService.manifest.data) {
        return list
      }
      for (const key in FleetDataService.manifest.data) {
        if (key.startsWith('gateway.')) {
          const gw = FleetDataService.manifest.data[key]
          if (gw.lat !== undefined && gw.lat !== 0 && gw.long !== undefined && gw.long !== 0) {
            let color = '#333'
            let status = 1
            if (gw.status === 'unmonitored') {
              color = '#cccccc'
              status = 0
            } else if (gw.status === 'warning') {
              color = '#eeaa00'
              status = 2
            } else if (gw.status === 'error') {
              color = '#cc0000'
              status = 3
            }
            if ((this.showGW !== 'all' && status < 2) || this.showGW === 'none') {
              continue
            }
            let detail = ''
            if (gw.statusDetail) {
              detail = ': ' + gw.statusDetail
            }
            list.push({ coords: [gw.long, gw.lat], siteName: gw.siteName, siteId: gw.site, id: gw.title, name: gw.title, detail: gw.status + detail, statusColor: color, status: status })
          }
        }
      }
      return list
    },
    nodesFromTopology () {
      const nodes = NewNodeLookup()
      for (const key in FleetDataService.manifest.data) {
        if (key.startsWith('device.')) {
          const d = FleetDataService.manifest.data[key]
          if (d.lat !== undefined && d.lat !== 0 && d.long !== undefined && d.long !== 0 && d.site && d.node) {
            let color = '#009900'
            let status = 1
            if (d.status === 'unmonitored') {
              color = '#cccccc'
              status = 0
            } else if (d.status === 'warning') {
              color = '#eeaa00'
              status = 2
            } else if (d.status === 'error') {
              color = '#cc0000'
              status = 3
            }
            if ((this.show !== 'all' && status < 2) || this.show === 'none') {
              continue
            }
            let node = nodes[d.node]
            if (!node) {
              let nodeName = d.node
              const manifestKey = 'node.' + d.node
              if (manifestKey in FleetDataService.manifest.data) {
                nodeName = FleetDataService.manifest.data[manifestKey].title
              }
              node = {
                coords: [d.long, d.lat],
                siteId: d.site,
                siteName: d.siteName,
                id: d.node,
                name: nodeName,
                status: status,
                devices: NewDevices(),
                statusColor: color
              }
              nodes[d.node] = node
            } else if (status > node.status) {
              node.statusColor = color
              node.status = status
            }
            const nd = NewDevice()
            nd.id = d.title
            nd.status = d.status
            nd.statusDetail = d.statusDetail
            node.devices.push(nd)
          }
        }
      }
      return nodes
    },
    nodeMarkers () {
      const out = NewMarkers()
      for (const key in this.nodesFromTopology) {
        const n = this.nodesFromTopology[key]
        const marker = NewMarker()
        marker.icon = 'map-pin'
        marker.coords = n.coords
        marker.color = n.statusColor
        const content = NewMapMarkerContent()
        content.title = `Site`
        content.titleLinkText = n.siteName
        content.titleRoute = 'site-details'
        content.titleRouteParams = { 'id': n.siteId }
        content.subtitle = `Node`
        content.subtitleLinkText = n.name
        content.subtitleRoute = 'site-details-node'
        content.subtitleRouteParams = { 'id': n.siteId, 'nodeID': n.id }
        if (n.devices && n.devices.length > 0) {
          for (const d of n.devices) {
            const item = NewMapMarkerContentItem()
            item.title = `Device`
            item.titleLinkText = d.id
            item.route = 'device-details'
            item.routeParams = { 'id': d.id }
            if (d.status) {
              item.body = d.status
              if (d.statusDetail) {
                item.body = `${item.body}: ${d.statusDetail}`
              }
            } else if (d.statusDetail) {
              item.body = d.statusDetail
            }
            content.items.push(item)
          }
        }
        marker.content = content
        out.push(marker)
      }
      return out
    },
    gatewayMarkers () {
      const out = NewMarkers()
      for (const gw of this.gatewaysFromTopology) {
        const marker = NewMarker()
        marker.icon = 'tower-broadcast'
        marker.coords = gw.coords
        marker.color = gw.statusColor
        const content = NewMapMarkerContent()
        content.title = `Site`
        content.titleLinkText = gw.siteName
        content.titleRoute = 'site-details'
        content.titleRouteParams = { 'id': gw.siteId }
        content.subtitle = `Gateway`
        content.subtitleLinkText = gw.id
        content.subtitleRoute = 'gateway-details'
        content.subtitleRouteParams = { 'id': gw.id }
        const item = NewMapMarkerContentItem()
        item.title = "Status:"
        item.body = gw.detail
        content.items.push(item)
        marker.content = content
        out.push(marker)
      }
      return out
    },
    markers () {
      const out = this.nodeMarkers.concat(this.gatewayMarkers)
      return out
    }
  }
}
</script>
