<template>
  <transition name="modal">
    <div class="spreadsheet-mask" v-if="isBlocking" @close="close()">
      <div class="spreadsheet-wrapper">
        <div class="spreadsheet-container">
          <div class="d-flex justify-content-between" style="max-width:100vw;height:40px;margin-top:5px;">
            <div style="overflow:auto;white-space:nowrap">
              <h4 style="font-size:22px;" class="pt-1 ps-2">
                {{ titleText }}
                <a @click="close()" class="btn btn-blue me-3 ms-3 d-md-none">Close</a>
              </h4>
            </div>
            <div class="d-none d-md-inline">
              <a @click="close()" class="btn btn-blue me-3">Close</a>
            </div>
          </div>
          <div class="spreadsheet-table-wrapper">
            <div v-if="isLoading" class="font-increase-2 bold p-4">
              Loading Data ...
            </div>
            <table class="spreadsheet-table" v-if="!isLoading && sortedRows.length > 0">
              <thead>
                <tr class="header">
                  <th class="header-col" v-if="numberedRows" />
                  <th v-for="(field,j) of headerRow" :key="j" :class="columnClass(j)" @click="setSort(j)">
                    {{ field }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line,i) of sortedRows" :key="i" v-bind:class="{'d-none': i == 0, 'data-row-last': i+1 == sortedRows.length}">
                  <td class="header-col" v-if="numberedRows">{{ i }}</td>
                  <td v-for="(field,j) of line" :key="j" :class="{'header-col': !numberedRows && j === 0}">
                    {{ field }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      sort: this.initialSort,
      reverseSort: this.initialSortReverse,
      isBlocking: false
    }
  },
  props: ['sheetData', 'sheetTitle', 'numberedRows', 'isLoading', 'initialSort', 'initialSortReverse', 'closer'],
  computed: {
    titleText () {
      return this.sheetTitle
    },
    rows () {
      if (!this.sheetData) {
        return []
      }
      const parsed = []
      const lines = this.sheetData.trim().split('\n')
      for (const line of lines) {
        const fields = line.split('\t')
        parsed.push(fields)
      }
      return parsed
    },
    headerRow () {
      if (!this.rows || this.rows.length === 0) {
        return null
      }
      return this.rows[0]
    },
    sortedRows () {
      if (!this.rows || this.rows.length === 0) {
        return null
      }
      const rows = [...this.rows]
      rows.shift()
      if (!this.sort && this.sort !== 0) {
        return rows
      }
      let sr = Array.from(rows)
      sr.sort(this.sortRows)
      if (this.reverseSort) {
        sr = sr.reverse()
      }
      return sr
    }
  },
  methods: {
    show () {
      this.isBlocking = true
    },
    close () {
      if (this.closer) {
        this.closer()
      }
      this.isBlocking = false
    },
    handleKeyPress (e) {
      if (e.code === 'Escape') {
        this.close()
      }
    },
    columnClass (col) {
      let classes = 'clickable sortable'
      if (!this.numberedRows && col === 0) {
        classes += ' header-col'
      }
      if (col === this.sort) {
        classes += ' col-sorted'
        classes = this.reverseSort ? classes + ' sorted-desc' : classes + ' sorted-asc'
      }
      return classes
    },
    setSort (sort) {
      if (!(sort >= 0)) {
        return
      }
      if (sort === this.sort) {
        this.reverseSort = !this.reverseSort
      } else {
        this.sort = sort
        this.reverseSort = false
      }
    },
    sortRows (a, b) {
      const a1 = a[this.sort] ? a[this.sort] : a[this.sort]
      const b1 = b[this.sort] ? b[this.sort] : b[this.sort]

      if (a1 === undefined || a1 === null) {
        if (b1 === undefined || b1 === null) {
          return 0
        }
        return -1
      }
      if (b1 === undefined || b1 === null) {
        return 1
      }
      if (a1 === b1) {
        return 0
      } else {
        return (a1 < b1) ? -1 : 1
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyPress)
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleKeyPress)
  }
}
</script>
