<template>
  <TabBar activeTabName="subscriptions" />
  <SubscriptionsMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SubscriptionsMainList from '@/components/notifications/SubscriptionsMainList.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    SubscriptionsMainList,
    TabBar
  }
})
export default class NetworkServerDetails extends Vue {}
</script>
