<script>
import BaseComponent from '@/components/BaseComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import NavigationService from '@/services/NavigationService'
import auth from '@/app/auth'
import { DevicePositions } from '@/app/data/positions.ts'

export default {
  name: 'base-fleet-component',
  extends: BaseComponent,
  data () {
    return {
      customer: null,
      site: null,
      device: null,
      customers: [],
      sites: [],
      devices: [],
      networkServers: [],
      networkServer: null,
      networkServerName: '',
      manufacturers: [],
      manufacturer: null,
      manufacturerName: '',
      gateways: [],
      gateway: null,
      gatewayName: '',
      siteName: '',
      customerName: '',
      deviceID: ''
    }
  },
  methods: {
    shortModel (input) {
      if (!input) {
        return input
      }
      return input.replaceAll('_ground', '').replaceAll('_training', '')
    },
    shortPos (input) {
      if (!input || !(input in DevicePositions)) {
        return '--'
      }
      return DevicePositions[input]
    },
    getDeviceCombinedDescription (id, desc) {
      if (!desc) {
        return id
      }
      return this.getLastN(id, 6) + ' - ' + desc
    },
    customerFromSite () {
      if (this.device) {
        for (const site of this.sites) {
          if (site.id === this.device.site) {
            this.device.customer = site.customer
            return
          }
        }
      }
    },
    retrieveAllSites (f) {
      FleetDataService.getAllSites()
        .then(response => {
          this.sites = response
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllCustomers (f) {
      FleetDataService.listCustomers()
        .then(response => {
          this.customers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllNetworkServers (f) {
      FleetDataService.getAllNetworkServers()
        .then(response => {
          this.networkServers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllManufacturers (f) {
      FleetDataService.getAllManufacturers()
        .then(response => {
          this.manufacturers = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    retrieveAllGateways (f) {
      FleetDataService.getAllGateways()
        .then(response => {
          this.gateways = response.data
          if (f) {
            f()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },
    setNavCustomer (custLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel2('Fleet', custLabel, '#/fleet')
    },
    setNavSite (custLabel, siteLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', custLabel, siteLabel, '#/fleet', '#/customers/' + custLabel)
    },
    setNavNetworkServer (serverLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', 'networks', serverLabel, '#/fleet', '#/networks', '')
    },
    setNavManufacturer (manLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', 'manufacturers', manLabel, '#/fleet', '#/manufacturers', '')
    },
    setNavGateway (gwLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', 'gateways', gwLabel, '#/fleet', '#/gateways', '')
    },
    hypenateDeviceId (id) {
      var out = ''
      if (id && id.length > 0) {
        // Change all letters to upper case
        out = id.toUpperCase()
        // Remove all but alphanumeric characters
        out = out.replace(/\W/ig, '')
        // Append a hyphen after every two characters
        out = out.replace(/(.{2})/g, '$1-')
        // remove any trailing hyphen
        if (out.slice(-1) === '-') {
          out = out.substring(0, out.length - 1)
        }
      }
      return out
    }
  },
  computed: {
    hasFleetPerms () {
      return auth.hasFleetPerms()
    },
    hasDevices () {
      return auth.userHasPrivilegeSubject(['devices'])
    },
    hasSites () {
      return auth.userHasPrivilegeSubject(['sites'])
    },
    hasGateways () {
      return auth.userHasPrivilegeSubject(['gateways'])
    },
    hasCustomers () {
      return auth.userHasPrivilegeSubject(['customers'])
    },
    hasNWServers () {
      return auth.userHasPrivilegeSubject(['nwservers'])
    },
    hasMakes () {
      return auth.userHasPrivilegeSubject(['makes'])
    },
    hasProducts () {
      return auth.userHasPrivilegeSubject(['crops-products'])
    },
    hasSenet () {
      return auth.userHasPrivilegeSubject(['senet-api'])
    },
    activeSites () {
      if (this.$state.showInactiveNodes) {
        return this.sites
      }
      var active = []
      for (const site of this.sites) {
        if (site.status === 'inactive') {
          continue
        }
        active.push(site)
      }
      active = active.sort((c1, c2) => c1.displayName < c2.displayName ? -1 : 1)
      return active
    },
    activeDevices () {
      if (this.$state.showInactiveNodes) {
        return this.devices
      }
      var active = []
      for (const device of this.devices) {
        if (device.status === 'inactive') {
          continue
        }
        active.push(device)
      }
      return active
    },
    activeCustomers () {
      if (this.$state.showInactiveNodes) {
        return this.customers
      }
      var active = []
      for (const customer of this.customers) {
        if (customer.status === 'inactive') {
          continue
        }
        active.push(customer)
      }
      active = active.sort((c1, c2) => c1.displayName < c2.displayName ? -1 : 1)
      return active
    },
    activeNetworkServers () {
      if (this.$state.showInactiveNodes) {
        return this.networkServers
      }
      var active = []
      for (const networkServer of this.networkServers) {
        if (networkServer.status === 'inactive') {
          continue
        }
        active.push(networkServer)
      }
      return active
    },
    activeManufacturers () {
      if (this.$state.showInactiveNodes) {
        return this.manufacturers
      }
      var active = []
      for (const manufacturer of this.manufacturers) {
        if (manufacturer.status === 'inactive') {
          continue
        }
        active.push(manufacturer)
      }
      return active
    },
    activeGateways () {
      if (this.$state.showInactiveNodes) {
        return this.gateways
      }
      var active = []
      for (const gateway of this.gateways) {
        if (gateway.status === 'inactive') {
          continue
        }
        active.push(gateway)
      }
      return active
    }
  }
}
</script>

<style>
</style>
