<template>
  <div>
    <DataTable
      class="dt-networkservers"
      title="All Network Servers"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      routerLink="network-add"
      actionIcon="plus"
      itemType="Server"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'networkServers-list',
  extends: BaseFleetComponent,
  data () {
    return {
      cols: [
        { text: 'Network ID', style: 'width:140px !important;' },
        { text: 'Description', style: 'width:130px !important;' },
        { text: 'Notes' }
      ],
      loading: false
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.activeNetworkServers && this.activeNetworkServers.length > 0) {
        for (const networkServer of this.activeNetworkServers) {
          out.push([
            { value: networkServer.id, route: 'network-details', params: { id: networkServer.id }, class: this.networkServerLinkColor(networkServer, '') },
            { value: networkServer.description },
            { value: networkServer.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    networkServerLinkColor (networkServer, baseClass) {
      let classes = baseClass
      if (networkServer.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.loading = true
      FleetDataService.getAllNetworkServers()
        .then(response => {
          this.networkServers = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('networkServers-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('networkServers-list')
  },
  components: { DataTable }
}
</script>
