export const HelpADSThreshold = `ADS Threshold Help

ADS Thresholds flag devices for maintenance when the threshold is met.

The Reference Type determines how to test the metric to know if it should alarm.
- 'Measurement Trend' tests a device's reported measurement against its trend (e.g., Weekly) and alarm if it's not within the expected range.
- 'Constant Value' will test device measurements against the provided value and alarm if they are above or below the value.

The Trend # Days/Hours fields indicate how far back from time.Now() to look for generating Reference and Test Trend data.

A Trend Function performs a mathematical operation on the Trend data before testing the Threshold.

Filters are inclusive, meaning that a given device must match all configured filters or it will not be tested against the Threshold.` as string
