<template>
  <option value="AB">AB</option>
  <option value="BC">BC</option>
  <option value="MB">MB</option>
  <option value="NB">NB</option>
  <option value="NL">NL</option>
  <option value="NS">NS</option>
  <option value="ON">ON</option>
  <option value="PE">PE</option>
  <option value="QC">QC</option>
  <option value="SK">SK</option>
</template>
