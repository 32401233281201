<template>
  <Modal ref="editModal" :job="job" />
  <InfoPane
    class="job-info-pane"
    title="Job Info"
    v-if="job"
    :actionFunc="showEditModal"
    actionIcon="pen-to-square"
    itemType="Job"
  >
    <template v-slot:info>
      <ul v-if="job" class="info-pane-list">
        <li class="info-pane-list-item"><label class="info-item-label">Name:</label>{{ job.name }}</li>
        <li class="info-pane-list-item" v-if="job.description"><label class="info-item-label">Description:</label>{{ job.description }}</li>
        <li class="info-pane-list-item"><label class="info-item-label">Status:</label>{{ job.status }}
          <span v-if="job.status === 'paused' && job.pauseUntil">
            for {{ getForUntil(job.pauseUntil) }}
          </span>
        </li>
        <li class="info-pane-list-item"><label class="info-item-label">Script Name:</label>{{ job.scriptName }}<span v-if="!job.scriptName">--</span></li>
        <li class="info-pane-list-item" v-if="job.scriptVersion"><label class="info-item-label">Script Version:</label>{{ job.scriptVersion }}</li>
        <li class="info-pane-list-item" v-if="job.concurrency && job.concurrency > 1"><label class="info-item-label">Concurrency:</label>{{ job.concurrency }}</li>
        <li class="info-pane-list-item node-notes"><label class="info-item-label">Runtime Args:</label>{{ job.args }}<span v-if="!job.args">--</span></li>
        <li class="info-pane-list-item" v-if="job.lastRunTS"><label class="info-item-label">Last Run <fa icon="clock" class="svg-icon-label" />:</label>{{ getSinceSecs(job.lastRunTS) + ' ago' }}</li>
        <li class="info-pane-list-item" v-if="job.lastRunStatus"><label class="info-item-label">Last State:</label>{{ job.lastRunStatus }}</li>
        <li class="info-pane-list-item" v-if="job.lastModified">
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(job.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="job.lastModifiedBy"> by {{ job.lastModifiedBy }}</span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/modeling/ModalAddUpdateJob.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, Modal },
  props: ['job'],
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    }
  }
}
</script>
