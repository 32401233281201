<template>
  <div class="col-auto component-pane mx-0 px-0">
    <h5>Add Manufacturer</h5>
    <form @submit.prevent="handleSubmit" autocomplete="off" class="node-form manufacturer-add-form">
      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="id">Name</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="id"
            required
            v-model="id"
            name="id"
            style="width:225px;"
            placeholder="manufacturer-name"
            maxlength="28"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header label-required" for="status">Status</label>
        <div class="col-auto form-right-input">
          <select class="form-select" id="status" name="status" v-model="status" style="width: 100px" required>
            <GeneralStatusOptions />
          </select>
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="description">Description</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="description"
            v-model="description"
            name="description"
            style="width:225px;"
            placeholder="Proper Name, Website, etc."
            maxlength="72"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="notes">Notes</label>
        <div class="col-auto form-right-input">
          <textarea
            id="notes"
            v-model="notes"
            name="notes"
            class="notes-box"
            placeholder="Enter any info about this Manufacturer that Agrology may find useful in the future."
          />
        </div>
      </div>

      <hr />

      <div class="row form-group">
        <label class="col-form-label form-left-header" for="models">Models</label>
        <div class="col-auto form-right-input">
          <input
            type="text"
            id="models"
            v-model="models"
            name="models"
            style="width:225px;"
            placeholder="comma,separated,model,list"
            maxlength="128"
          />
        </div>
      </div>

      <div class="row-auto mx-0 px-0 form-button-row">
        <div class="col-auto buttons-right">
          <button class="btn" :disabled="isLoading">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="$router.go(-1)">Cancel</a>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import NavigationService from '@/services/NavigationService'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'manufacturer-add',
  extends: BaseFleetComponent,
  data () {
    return {
      id: '',
      status: 'active',
      description: '',
      notes: '',
      models: ''
    }
  },
  methods: {
    handleSubmit () {
      this.isLoading = true
      var data = {
        id: this.id,
        status: this.status,
        description: this.description,
        notes: this.notes,
        models: this.models
      }
      FleetDataService.createManufacturer(data)
        .then(() => {
          HelpContent.setFlashMessage('Manufacturer Created Successfully')
          this.$router.push({ name: 'manufacturer-details', params: { id: this.id } })
        })
        .catch(e => {
          let message = 'Manufacturer Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Manufacturer Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    reset () {
      this.id = ''
      this.status = 'active'
      this.description = ''
      this.notes = ''
      this.models = ''
    }
  },
  mounted () {
    this.reset()
  },
  created () {
    NavigationService.setNavLevel3('Fleet', 'manufacturers', 'Add Manufacturer', '#/fleet', '#/manufacturers', '')
  },
  components: { GeneralStatusOptions }
}
</script>

<style>
</style>
