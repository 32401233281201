<template>
  <div :class="mapClass">
    <div :style="'height:' + mapHeight">
      <div class="map-expand-click" @click="toggleExpanded">
        <div class="fleet-map-menu">
          <fa v-if="!isExpanded" icon="up-right-and-down-left-from-center" />
          <fa v-else icon="down-left-and-up-right-to-center" />
        </div>
      </div>
      <MapControl
        :key="mapRenderKey"
        :markers="markers"
        :polygons="polygons"
        :height="mapHeight"
        :useBounds="true"
        :showGeolocation="true"
        :boundBorderPct="10"
        v-if="markers"
      />
    </div>
    <div class="text-center" v-if="!hideStyles">
      <div class="btn-group no-border" role="group" aria-label="User Search Filter" style="max-height:27px !important; width:100%">
        <input type="radio" class="btn-check" value="streets-v11" name="mapstyle"
          id="mapstyle-street" v-model="mapBoxStyle" autocomplete="off" />
        <label class="btn btn-outline-primary no-border" for="mapstyle-street">Streets</label>

        <input type="radio" class="btn-check" value="satellite-v9" name="mapstyle"
          id="mapstyle-satellite" v-model="mapBoxStyle" autocomplete="off" />
        <label class="btn btn-outline-primary no-border" for="mapstyle-satellite">Satellite</label>

        <input type="radio" class="btn-check" value="satellite-streets-v11" name="mapstyle"
          id="mapstyle-satplus" v-model="mapBoxStyle" autocomplete="off" />
        <label class="btn btn-outline-primary no-border" for="mapstyle-satplus">Satellite+Streets</label>

        <input type="radio" class="btn-check" value="outdoors-v11" name="mapstyle"
          id="mapstyle-topographic" v-model="mapBoxStyle" autocomplete="off" />
        <label class="btn btn-outline-primary no-border" for="mapstyle-topographic">Topographic</label>

      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import MapControl from '@/components/map/MapControl.vue'

export default defineComponent({
  name: 'SiteMap',
  props: ['markers', 'polygons', 'hideMainMarker', 'hideStyles', 'height'],
  data () {
    return {
      isExpanded: false,
      mapBoxStyle: ''
    }
  },
  components: {
    MapControl
  },
  watch: {
    mapBoxStyle: {
      handler () {
        if (this.mapBoxStyle) {
          localStorage.setItem('mapBoxStyle', this.mapBoxStyle)
          this.isLoading = true
          setTimeout(this.stopLoading, 100)
        }
      }
    }
  },
  mounted () {
    const style = localStorage.getItem('mapBoxStyle')
    if (!style) {
      this.mapBoxStyle = 'satellite-v9'
      localStorage.setItem('mapBoxStyle', 'satellite-v9')
    } else {
      this.mapBoxStyle = style
    }
  },
  unmounted () {
    if (this.isExpanded) {
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  },
  methods: {
    handleKeyPress (e) {
      if (this.isExpanded && e.code === 'Escape') {
        this.toggleExpanded()
      }
    },
    toggleExpanded () {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        document.addEventListener('keydown', this.handleKeyPress)
      } else {
        document.removeEventListener('keydown', this.handleKeyPress)
      }
    }
  },
  computed: {
    mapRenderKey () {
      var i = 0
      if (this.isExpanded) {
        i += 1
      }
      switch (this.mapBoxStyle) {
        case 'streets-v11':
          i += 11
          break
        case 'satellite-v9':
          i += 12
          break
        case 'satellite-streets-v11':
          i += 13
          break
        case 'outdoors-v11':
          i += 14
          break
      }
      return i
    },
    mapClass () {
      if (this.isExpanded) {
        return 'map-container-expandable map-container-expanded'
      }
      return 'map-container-expandable'
    },
    mapHeight () {
      if (this.isExpanded) {
        return '100%'
      }
      if (this.height) {
        return this.height
      }
      return '320px'
    }
  }
})
</script>
