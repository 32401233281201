<template>
  <Modal ref="editModal" :customer="customer" />
  <InfoPane
    class="dt-info-pane-sm"
    title="Customer Preferences"
    v-if="customer"
    :actionFunc="showEditModal"
    actionIcon="pen-to-square"
    itemType="Prefs"
  >
    <template v-slot:info>
      <ul v-if="customer" class="info-pane-list">
        <li class="info-pane-list-item">
          <span>
            <span class="list-heading-label">
              Pest & Disease Forecast:
            </span>
            <span v-if="customer.pestDiseaseMonitoring && customer.pestDiseaseMonitoring == 1">
              Basic
            </span>
            <span v-else-if="customer.pestDiseaseMonitoring && customer.pestDiseaseMonitoring == 2">
              Ground Truth
            </span>
            <span v-else>
              --
            </span>
          </span>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Modal from '@/components/fleet/ModalCustomerPrefs.vue'

export default {
  extends: BaseComponent,
  components: { InfoPane, Modal },
  props: ['customer'],
  methods: {
    showEditModal () {
      this.$refs.editModal.show()
    }
  }
}
</script>
