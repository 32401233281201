<template>
  <TabBar activeTabName="gateways" />
  <div class="row justify-content-center px-0 mx-0">
    <div class="col-auto px-0 mx-0">
      <GatewayInfo />
    </div>
    <div class="col-auto d-xl"></div>
    <div class="col-auto px-0 mx-0">
      <DevicesByGateway />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import GatewayInfo from '@/components/fleet/GatewayInfo.vue'
import DevicesByGateway from '@/components/fleet/DevicesByGateway.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    GatewayInfo,
    DevicesByGateway,
    TabBar
  }
})
export default class GatewayDetails extends Vue {}
</script>
