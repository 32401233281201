<template>
  <TabBar activeTabName="subscriptions" />
  <ApnSubscriptionInfo />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ApnSubscriptionInfo from '@/components/notifications/ApnSubscriptionInfo.vue'
import TabBar from '@/components/notifications/TabBar.vue'

@Options({
  components: {
    ApnSubscriptionInfo,
    TabBar
  }
})
export default class TemplateDetails extends Vue {}
</script>
