<template>
  <TabBar activeTabName="sites" />
  <SiteMainList />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SiteMainList from '@/components/fleet/SiteMainList.vue'
import NavigationService from '@/services/NavigationService'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    SiteMainList,
    TabBar
  }
})
export default class SitesList extends Vue {
  mounted () {
    NavigationService.setNavLevel2('Fleet', 'Sites', '#/fleet', '')
  }
}
</script>
