<template>
  <ModalUserInfo ref="userInfo" />
  <div class="footer">
    <span class="pe-1">
      © {{ new Date().getFullYear() }} Agrology PBC
    </span>
    <span v-if="email" class="pe-1">
      •
    </span>
    <span v-if="email" class="pe-1">
      <a class="username-link" @click="showModalUserInfoWidget()">
        {{ email }}
      </a>
    </span>
    <span class="pe-1">
      •
    </span>
    <span class="pe-1">v{{ version }}</span>
  </div>
</template>

<script>
import ModalUserInfo from '@/components/modals/ModalUserInfo.vue'
import auth from '@/app/auth'
import { VERSION } from '@/app/version'

export default {
  name: 'ui-footer',
  components: { ModalUserInfo },
  data () {
    return {
      intervalId: undefined,
    }
  },
  computed: {
    email () {
      return this.$state.email
    },
    version () {
      return VERSION
    }
  },
  methods: {
    checkAuthStatus () {
      if (!auth.auth.isUserSignedIn()) {
        console.log("no longer signed in")
        this.$state.isSignedIn = false
        localStorage.setItem('preSigninLocation', window.location.hash.replace('#', ''))
        auth.auth.getSession()
      }
    },
    showModalUserInfoWidget () {
      this.$refs.userInfo.show()
    },
    logout () {
      auth.logout()
    }
  },
  mounted () {
    this.intervalId = setInterval(this.checkAuthStatus, 30000)
  },
  unmounted () {
    clearInterval(this.intervalId)
  }
}
</script>
<style>
  .username-link {
    color: inherit;
    text-decoration: none;
  }
  .username-link:hover {
    color: inherit;
    text-decoration: underline;
  }
</style>
