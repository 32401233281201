<template>
  <div>
    <ModalAddUpdateProduct ref="productadd" @reloadProducts="reloadProducts" />
    <ModalAddUpdateProduct ref="productedit" :product="productData" @reloadProducts="reloadProducts" />
    <DataTable
      class="dt-products"
      title="All Products"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :actionFunc="showAddModal"
      actionIcon="plus"
      itemType="Product"
    >
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import ModalAddUpdateProduct from '@/components/fleet/ModalAddUpdateProduct.vue'

import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'products-list',
  data () {
    return {
      cols: [
        { text: 'Name' },
        { text: 'Description' },
        { text: 'Crops / Varietals' }
      ],
      isLoading: false,
      products: null,
      productData: null
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.products && this.products.length > 0) {
        for (const product of this.products) {
          out.push([
            { value: product.name, click: this.showEditModal, i: product },
            { value: product.description },
            { value: this.getCrops(product.crops) }
          ])
        }
      }
      return out
    }
  },
  methods: {
    getCrops (input) {
      if (!input) {
        return ''
      }
      return input.join(', ')
    },
    refreshList () {
      this.isLoading = true
      FleetDataService.listAllProducts()
        .then(response => {
          this.products = response.data
        })
        .catch(e => {
          let message = 'Failed to load Products List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showAddModal () {
      this.$refs.productadd.show('')
    },
    showEditModal (product) {
      this.productData = { ...product }
      this.$nextTick(() => {
        this.$refs.productedit.show()
      })
    },
    reloadProducts () {
      this.$emit('reloadProducts')
    }
  },
  mounted () {
    Reloadables.registerReloadable('products-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('products-list')
  },
  components: { DataTable, ModalAddUpdateProduct }
}
</script>
