<template>
  <option v-for="(_, pos) of positions" :key="pos" :value="pos">
    {{ pos }}
  </option>
</template>
<script>
import { DevicePositions } from '@/app/data/positions.ts'
export default {
  computed: {
    positions () {
      return { ... DevicePositions }
    }
  }
}
</script>
