<template>
  <div :class="classes" v-if="!isClosed" @click="buttonFunc">
    <div>
      <a v-if="!clickButton && clickLabel" @click="clickLabel" :class="labelLinkClasses">
        {{ label }}
      </a>
      <a v-else-if="clickButton" :class="labelLinkClasses">
        {{ label }}
      </a>
      <span v-else :class="labelClasses">
        {{ label }}
      </span>
    </div>
    <div class="tag-bubble-closer" v-if="!closeDisabled">
      <a class="normal font-reduce-1" @click="closeFunc">
        &#x2715;
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'tag-bubble-control',
  computed: {
    buttonFunc () {
      if (this.clickButton) {
        return this.clickButton
      }
      return this.emptyFunc
    },
    closeFunc () {
      if (this.clickClose) {
        this.clickClose(this.label)
        return
      }
      return this.defaultCloser
    },
    labelClasses () {
      if (this.labelClass) {
        return this.labelClass
      }
      return ''
    },
    labelLinkClasses () {
      if (this.labelClass) {
        return 'normal ' + this.labelClass
      }
      return 'normal'
    },
    classes () {
      if (this.class) {
        return 'tag-bubble d-flex ' + this.class
      }
      return 'tag-bubble d-flex'
    }
  },
  data () {
    return {
      isClosed: false
    }
  },
  methods: {
    emptyFunc () {
      // intentionally empty
    },
    defaultCloser () {
      this.isClosed = true
    }
  },
  props: [
    'label',
    'labelClass',
    'closeDisabled',
    'class',
    'clickLabel',
    'clickButton',
    'clickClose',
    'closeLabel'
  ]
}
</script>
