<template>
  <transition name="modal">
    <modal v-if="isBlocking" @close="close()" class="ps-0" sz="sm">

        <template v-slot:header>
          Senet Device Manager
        </template>

        <template v-slot:body>

          <div class="row justify-content-between mx-0 px-0">
            <div class="col-auto pe-0" v-if="!inputMode">
              <h5 class="mb-0">{{ deviceEUI }}</h5>
              <span v-if="networkZone" class="subtle">{{ networkZone }} network zone</span>
              <div class="mt-2" v-else>
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPrivate" v-model="nz" value="private" >
                  <label class="form-check-label font-reduce-1" for="nzPrivate">
                    Priv
                  </label>
                </div>
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPublic" v-model="nz" value="public" >
                  <label class="form-check-label font-reduce-1" for="nzPublic">
                    Pub
                  </label>
                </div>
              </div>
            </div>
            <div class="col-auto" v-else>
              <input type="text" class="form-input" v-model="dev" style="width:200px" placeholder="Device EUI" @keydown="resetSenetObj" />
              <div class="mt-2">
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPrivate" v-model="nz" value="private" >
                  <label class="form-check-label font-reduce-1" for="nzPrivate">
                    Priv
                  </label>
                </div>
                <div class="form-check form-check-inline radio-control">
                  <input class="form-check-input" type="radio"  id="nzPublic" v-model="nz" value="public" >
                  <label class="form-check-label font-reduce-1" for="nzPublic">
                    Pub
                  </label>
                </div>
              </div>

            </div>
            <div class="col-auto me-2 pe-0">
              <button class="btn" :disabled="isLoading" @click="getData()">Check Senet</button>
            </div>
          </div>

          <hr class="info-sep mt-3" v-if="senetData" />

          <div class="row mt-3 ps-2 justify-content-between" v-if="senetData">
            <div class="col-auto" v-if="!senetData.State || !['ACTIVATED', 'DEACTIVATED', 'REGISTERED'].includes(senetData.State)">
              <div v-if="applicationKey && !inputMode">
                <button class="btn" :disabled="isLoading" @click="registerSenetDevice">Send Registration</button>
                <button v-if="networkZone === 'public'" :disabled="isLoading" class="btn ms-3" @click="performZoneTransfer(dev, 'private', 'public')">Transfer from Private Zone</button>
                <button v-else-if="networkZone === 'private'" class="btn ms-3" :disabled="isLoading" @click="performZoneTransfer(dev, 'public', 'private')">Transfer from Public Zone</button>
              </div>
              <div v-if="!applicationKey || inputMode">
                <p class="notable">Device is not registered with Senet.</p>
                <p class="notable" v-if="!applicationKey && !inputMode">Set the AppKey in the Device Editor to enable the Register button.</p>
                <p class="notable" v-if="inputMode">Go to the Device Editor to Register it.</p>
              </div>
            </div>
            <div class="col-auto" v-if="senetData.State && senetData.State == 'ACTIVATED'">
              <button class="btn" :disabled="isLoading" @click="deactivateDevice()">Deactivate in Senet</button>
            </div>
            <div class="col-auto" v-if="senetData.State && senetData.State != 'ACTIVATED'">
              <button class="btn" :disabled="isLoading" @click="activateDevice()">Activate in Senet</button>
            </div>
            <div class="col-auto" v-if="!inputMode && senetData.State && ['ACTIVATED', 'DEACTIVATED'].includes(senetData.State)">
              <button class="btn me-2" :disabled="isLoading" @click="updateDevice()">Send Data Update</button>
            </div>
          </div>

          <hr class="info-sep mt-3" v-if="senetData && senetData.State" />

          <div v-if="senetData && senetData.State" style="height:320px;max-height:320px;overflow-y:scroll;overflow-x:hidden;">

            <table class="kvp-table" style="width:350px;max-width:350px;">
              <tr v-if="senetData.State"><th>State</th><td>{{ senetData.State }}</td></tr>
              <tr v-if="senetData.LastHeardFrom"><th>LastHeardFrom</th><td>{{ senetData.LastHeardFrom }}</td></tr>
              <tr v-if="senetData.DevType"><th>DevType</th><td>{{ senetData.DevType }}</td></tr>
              <tr v-if="senetData.Tags"><th>Tags</th><td>{{ senetData.Tags }}</td></tr>
              <tr v-if="senetData.Metadata"><th>Metadata</th><td>{{ senetData.Metadata }}</td></tr>
              <tr v-if="senetData.Latitude && senetData.Latitude != 0 && senetData.Longitude && senetData.Longitude != 0"><th>Latitude</th><td>{{ senetData.Latitude }}</td></tr>
              <tr v-if="senetData.Latitude && senetData.Latitude != 0 && senetData.Longitude && senetData.Longitude != 0"><th>Longitude</th><td>{{ senetData.Longitude }}</td></tr>
            </table>

            <hr class="info-sep mt-3 mb-3" />

            <span class="notable ms-2"><b>All Senet Data Fields</b></span>
            <table class="kvp-table">
              <tr v-for="(key, value) in senetData" :value="key" :key="value" >
                <th>{{ value }}</th><td>{{ key }}</td>
              </tr>
            </table>
          </div>
        </template>

    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'

export default {
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      dev: this.deviceEUI,
      senetData: null,
      actionResponse: null,
      applicationKey: this.appKey,
      inputMode: this.isInputMode,
      nz: 'public'
    }
  },
  props: ['deviceEUI', 'appKey', 'isInputMode', 'networkZone'],
  components: { Modal },
  methods: {
    resetSenetObj () {
      this.senetData = null
    },
    registerSenetDevice () {
      if (!this.dev) {
        return
      }
      this.isLoading = true
      let params = ''
      if (this.applicationKey) {
        params = '?appKey=' + this.applicationKey
      }

      FleetDataService.registerSenetDevice(this.dev, this.nz, params)
        .then(() => {
          this.senetData = null
          this.getData()
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to Register Device ' + this.dev + ' in Senet'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    performZoneTransfer (devEui, oldZone, newZone) {
      if (!devEui || !oldZone || !newZone) {
        return
      }
      this.isLoading = true
      FleetDataService.transferSenetDevice(devEui, oldZone, newZone)
        .then(() => {
          this.senetData = null
          this.getData()
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to Transfer Device ' + this.dev + ' in Senet'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    activateDevice () {
      if (!this.dev) {
        return
      }
      this.isLoading = true
      this.senetData = null
      FleetDataService.activateSenetDevice(this.dev, this.nz)
        .then(() => {
          this.getData()
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to Activate Device ' + this.dev
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    deactivateDevice () {
      if (!this.dev) {
        return
      }
      this.isLoading = true
      this.senetData = null
      FleetDataService.deactivateSenetDevice(this.dev, this.nz)
        .then(() => {
          this.getData()
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to Deactivate Device ' + this.dev
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateDevice () {
      if (!this.dev) {
        return
      }
      this.isLoading = true
      this.senetData = null
      FleetDataService.updateSenetDevice(this.dev, this.nz)
        .then(response => {
          this.getData()
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to Update Device ' + this.dev
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getData () {
      if (!this.dev) {
        return
      }
      this.isLoading = true
      this.applicationKey = this.appKey
      this.senetData = null
      FleetDataService.getSenetDevice(this.dev, this.nz)
        .then(response => {
          this.senetData = response.data
        })
        .catch(e => {
          console.log(e)
          let message = 'Failed to load Senet Data'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    show () {
      this.isLoading = false
      this.senetData = null
      this.isBlocking = true
      if (this.networkZone) {
        this.nz = this.networkZone
      } else {
        this.nz = 'public'
      }
    },
    close () {
      this.isLoading = false
      this.senetData = null
      this.isBlocking = false
    }
  }
}
</script>
