<template>
  <div :class="classes">
    {{ text }}
  </div>
</template>
<script>
export default {
  props: ['text', 'textClass'],
  computed: {
    classes () {
      let out = 'submission-form-section-text'
      if (this.textClass) {
        return `${out} ${this.textClass}`
      }
      return out
    }
  }
}
</script>
