<template>
  <div>
    <DataTable
      class="dt-info-pane-sm"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      noDataMessage="No Alerts"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'

export default {
  name: 'alerts-by-n-list',
  extends: BaseComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Alert ID', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Status', sort: 2 },
        { text: 'Category', sort: 3 }
      ],
      entityTypeName: this.entityType,
      loading: false,
      alerts: []
    }
  },
  props: ['entityType', 'site'],
  computed: {
    listTitle () {
      return 'Alerts for this ' + this.siteClassification.charAt(0).toUpperCase() + this.siteClassification.slice(1)
    },
    siteClassification () {
      if (this.site && this.site.siteClassification) {
        return this.site.siteClassification
      }
      return this.entityTypeName
    },
    rows () {
      const out = []
      if (this.alerts && this.alerts.length > 0) {
        for (const alert of this.alerts) {
          out.push([
            { value: this.getLastWord(alert.id), route: 'alert-details', params: { id: alert.id } },
            { value: this.getSinceSecs(alert.lastModified), sortValue: (Date.now() / 1000) - alert.lastModified },
            { value: alert.status, class: { notable: alert.status === 'active' } },
            { value: alert.category }
          ])
        }
      }
      return out
    }
  },
  methods: {
    retrieveAlerts (id) {
      this.loading = true
      NotificationsDataService.getRecentAlerts(this.entityTypeName.toLowerCase(), id)
        .then(response => {
          this.alerts = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refreshList () {
      this.retrieveAlerts(this.$route.params.id)
    }
  },
  mounted () {
    Reloadables.registerReloadable('alerts-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('alerts-list')
  },
  components: { DataTable }
}
</script>
