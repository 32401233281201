<template>
    <option value="generic">Generic</option>
    <option value="sand">Sand</option>
    <option value="loamySand">Loamy Sand</option>
    <option value="sandyLoam">Sandy Loam</option>
    <option value="loam">Loam</option>
    <option value="siltLoam">Silt Loam</option>
    <option value="silt">Silt</option>
    <option value="sandyClayLoam">Sandy Clay Loam</option>
    <option value="clayLoam">Clay Loam</option>
    <option value="siltyClayLoam">Silty Clay Loam</option>
    <option value="sandyClay">Sandy Clay</option>
    <option value="siltyClay">Silty Clay</option>
    <option value="clay">Clay</option>
</template>
