<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        Add Customer Domain
      </template>

      <template v-slot:body>
        <div>
          <p class="px-2 pb-3 add-border-bottom font-reduce-2 subtle">
            Add customer-specific domains (e.g., mywinery.com).
            Do not add public email domains like gmail or yahoo.
          </p>
        </div>

        <form ref="customerForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-bottom:-12px">

          <TextInputRow label="Domain"
            v-model="domain"
            labelClass="label-required width-95"
            inputStyle="text-transform: lowercase; width:250px;"
            placeholder="example.com"
            maxlength="48"
            dataType="domain"
          />

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateCustomer()" :disabled="isLoading || !domain">Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/modals/Modal.vue'

import TextInputRow from '@/components/forms/TextInputRow.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'customer-add',
  props: ['customer'],
  data () {
    return {
      isBlocking: false,
      domain: '',
      isLoading: false
    }
  },
  methods: {
    show () {
      this.domain = ''
      if (this.customer) {
        this.isBlocking = true
      }
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateCustomer () {
      const domain = this.domain.trim().toLowerCase()
      const formData = {}
      if (!this.customer.domains) {
        formData.domains = domain
      } else {
        const domains = this.customer.domains.split(',')
        if (domains.includes(domain)) {
          HelpContent.setFlashMessage(`${domain} is already configured for ${this.customer.id}`, true)
          return
        }
        domains.push(domain)
        formData.domains = domains.join(',')
      }
      this.isLoading = true
      FleetDataService.updateCustomer(this.customer.id, formData)
        .then(() => {
          HelpContent.setTimedFlashMessage(`${domain} successfully added to ${this.customer.id}`)
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Customer Domains Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow }
}
</script>
