<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="sm">
      <template v-slot:header>
        {{ headerText }}
      </template>

      <template v-slot:body>
        <form ref="productForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">

          <TextInputRow label="Product"
            v-model="formData.name"
            labelClass="label-required width-135"
            :required="true"
            placeholder="Wine, Salad Greens, etc."
            maxlength="18"
            dataType="words"
            inputStyle="width: 215px;"
          />

          <TextInputRow label="Description"
            v-model="formData.description"
            placeholder="Creative Product Description"
            maxlength="28"
            dataType="description"
            labelClass="width-135"
            inputStyle="width: 215px;"
          />

          <div class="row form-group">
            <label class="col-form-label form-left-header width-135">Crops / Varietals</label>
            <div class="col-auto">
              <div v-for="(crop, i) in formData.crops" :key="i" class="d-flex mb-2">
                <input type="text" class="form-input me-1" placeholder="Merlot, Arugula, etc." v-model="formData.crops[i]" :id="'crops_'+crop" style="width: 180px" />
                <span class="ms-2" style="font-weight:bold">
                  <a class="normal padded" @click="remCrop(i)">&#x2715;</a>
                </span>
              </div>
              <div class="mt-2">
                <a class="normal padded" @click="newCrop()">Add a Crop / Varietal</a>
              </div>
            </div>
          </div>

        </form>
      </template>
      <template v-slot:footer>
        <div class="col-auto buttons-right">
          <button class="btn" @click="updateProduct()" :disabled="isLoading || !formData.name" v-if="!isCreate">Submit</button>
          <button class="btn" @click="createProduct()" :disabled="isLoading || !formData.name" v-else>Submit</button>
          <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

import Modal from '@/components/modals/Modal.vue'

import TextInputRow from '@/components/forms/TextInputRow.vue'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'product-add-update',
  props: ['product'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      isLoading: false,
      mode: ''
    }
  },
  computed: {
    headerText () {
      if (this.product) {
        return 'Edit Product'
      }
      return 'Add New Product'
    }
  },
  methods: {
    newCrop () {
      this.formData.crops.push('')
    },
    remCrop (idx) {
      this.formData.crops.splice(idx, 1)
    },
    reset () {
      this.mode = ''
      if (this.product) {
        this.formData = { ...this.product }
        if (this.product.crops) {
          this.formData.crops = [...this.product.crops]
        } else {
          this.formData.crops = []
        }
      } else {
        this.isCreate = true
        this.formData = {
          crops: []
        }
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    },
    updateProduct () {
      this.isLoading = true
      FleetDataService.updateProduct(this.product.name, this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Product Updated')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Product Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          this.formData.name = name
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createProduct () {
      this.isLoading = true
      FleetDataService.createProduct(this.formData)
        .then(() => {
          HelpContent.setTimedFlashMessage('Product Created')
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Product Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Product Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow }
}
</script>
