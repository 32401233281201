<template>
  <InfoPane
    class="alert-info-pane"
    title="Alert Info"
    v-if="alert"
  >
    <template v-slot:info>
      <ul v-if="alert" class="info-pane-list alert-info-pane">
        <li class="info-pane-list-item font-reduce-1"><b>ID:</b> {{ alert.id }}</li>
        <li class="info-pane-list-item">
          <label class="info-item-label">Status:</label>
          <span :class="{'notable': alert.status === 'active'}">
            {{ alert.status }}
          </span>
          <span v-if="alert.locked" class="notable bold ms-2"> (locked)</span>
        </li>
        <li class="info-pane-list-item node-notes">
          {{ alert.detail }}
        </li>
        <li class="info-pane-list-item">
          <label class="info-item-label">Start:</label>
          {{ getFormattedDateSecs(alert.start) }}
        </li>
        <li class="info-pane-list-item" v-if="alert.status === 'resolved' && alert.end">
          <label class="info-item-label">End:</label>
          {{ getFormattedDateSecs(alert.end) }}
        </li>
        <!-- <li class="info-pane-list-item">
          <label class="info-item-label">Updated:</label>
          {{ getFormattedDateSecs(alert.lastModified) }}
        </li> -->
        <li class="info-pane-list-item" v-if="alert.category">
          <label class="info-item-label">Category:</label>
          {{ alert.category }}
        </li>
        <li class="info-pane-list-item" v-if="alert.lat && alert.long">
          <label class="info-item-label">GPS:</label>
          <span class="pe-1 me-2">
            {{ alert.lat }}, {{ alert.long }}
          </span>
          <a class="normal padded" :target="'___GPS__ALERT' + alert.id"
            :href="'https://www.google.com/maps/place/' + alert.lat + ',' + alert.long">
            <fa icon="up-right-from-square" class="dt-icon" />
          </a>
        </li>
        <li class="info-pane-list-item" v-if="alert.threshold">
          <label class="info-item-label">Threshold:</label>
          <router-link class="padded"
            :to="{  name: 'threshold-details', params: { id: alert.threshold } }">
            {{ thresholdDisplayName }}
          </router-link>
        </li>
        <li class="info-pane-list-item node-notes" v-if="alert.site">
          <div class="d-flex">
            <label class="info-item-label">Sites:</label>
            <div>
              <span v-for="site in alert.sites" :key="site">
                <span v-if="alert.sites.length > 1">- </span>
                <router-link
                  :to="{  name: 'site-details', params: { id: site } }"
                  class="padded me-2"
                  >
                  {{ siteDisplayName(site) }}
                </router-link>
                <br />
              </span>
            </div>
          </div>
        </li>
        <li class="info-pane-list-item" v-if="alert.customer">
          <label class="info-item-label">Customer:</label>
          <router-link
            class="padded"
            :to="{  name: 'customer-details', params: { id: alert.customer } }">
            {{ customerDisplayName }}
          </router-link>
        </li>
        <li class="info-pane-list-item" v-if="alert.source">
          <label class="info-item-label">Source:</label>
          {{ alert.source }}
          <span v-if="alert.origin && alert.origin !== 'Agrology'">
            ({{ alert.origin }})
          </span>
        </li>
        <li class="info-pane-list-item" v-if="alert.metric">
          <label class="info-item-label">Metric:</label>
          {{ alert.metric }}
        </li>
        <li class="info-pane-list-item" v-if="alert.value">
          <div class="d-flex">
            <label class="info-item-label">Value:</label>
            <div style="margin-left: 45px">
              <span class="node-notes">{{ alert.value }}</span>
            </div>
          </div>
        </li>
        <li class="info-pane-list-item d-flex justify-content-end">
          <button :disabled="isLoading" v-if="alert.locked" class="btn" @click.prevent="unlockAlert()">Unlock</button>
          <button :disabled="isLoading" v-if="!alert.locked && alert.status === 'resolved'" class="btn ms-3" @click.prevent="lockAlert()">Lock</button>
          <button :disabled="isLoading" v-if="alert.status !== 'resolved'" class="btn btn-red ms-3" @click.prevent="resolveAlert()">Resolve</button>
        </li>
      </ul>
    </template>
  </InfoPane>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import NavigationService from '@/services/NavigationService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  extends: BaseComponent,
  components: { InfoPane },
  data () {
    return {
      alert: null,
      threshold: null
    }
  },
  computed: {
    thresholdDisplayName () {
      if (!this.alert || !this.alert.customer) {
        return ''
      }
      if (!this.threshold || !this.threshold.description) {
        return this.alert.threshold
      }
      return this.threshold.description
    },
    customerDisplayName () {
      if (!this.alert || !this.alert.customer) {
        return ''
      }
      return FleetDataService.getCustomerDisplayName(this.alert.customer)
    }
  },
  methods: {
    siteDisplayName (input) {
      return FleetDataService.getSiteDisplayName(input)
    },
    getThreshold (id) {
      this.isLoading = true
      NotificationsDataService.getThreshold(id)
        .then(response => {
          this.threshold = response.data
        })
        .catch(e => {
          let message = 'Unable to load Threshold'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getAlert (id) {
      this.isLoading = true
      NotificationsDataService.getAlert(id)
        .then(response => {
          this.alert = response.data
          this.getThreshold(this.alert.threshold)
        })
        .catch(e => {
          let message = 'Unable to load Alert'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadInfo () {
      this.getAlert(this.$route.params.id)
    },
    resolveAlert () {
      this.isLoading = true
      NotificationsDataService.resolveAlert(this.alert.id)
        .then(() => {
          HelpContent.setTimedFlashMessage('Alert set to Resolved')
          Reloadables.requestReload()
        })
        .catch(e => {
          let message = 'Unable to resolve Alert'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    lockAlert () {
      this.isLoading = true
      NotificationsDataService.lockAlert(this.alert.id)
        .then(() => {
          HelpContent.setTimedFlashMessage('Alert is Locked')
          Reloadables.requestReload()
        })
        .catch(e => {
          let message = 'Unable to lock Alert'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    unlockAlert () {
      this.isLoading = true
      NotificationsDataService.unlockAlert(this.alert.id)
        .then(() => {
          HelpContent.setTimedFlashMessage('Alert is Unlocked')
          Reloadables.requestReload()
        })
        .catch(e => {
          let message = 'Unable to unlock Alert'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('alert-info', this.loadInfo)
    this.loadInfo()
    this.$state.currentSpace = 'notifications'
    NavigationService.setNavLevel4('Notifications', 'alerts',
      this.getLastWord(this.$route.params.id, '-'), '', '#/notifications', '#/alerts')
  },
  unmounted () {
    Reloadables.unregisterReloadable('alert-info')
  }
}
</script>
