<template>
  <div>
    <DataTable
      class="dt-manufacturers"
      title="All Manufacturers"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      routerLink="manufacturer-add"
      actionIcon="plus"
      itemType="Manufacturer"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'

import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'

export default {
  name: 'manufacturers-list',
  extends: BaseFleetComponent,
  data () {
    return {
      cols: [
        { text: 'ID', style: 'width:140px !important;' },
        { text: 'Description', style: 'width:130px !important;' },
        { text: 'Notes' }
      ],
      loading: false
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.activeManufacturers && this.activeManufacturers.length > 0) {
        for (const manufacturer of this.activeManufacturers) {
          out.push([
            { value: manufacturer.id, route: 'manufacturer-details', params: { id: manufacturer.id }, class: this.manufacturerLinkColor(manufacturer, '') },
            { value: manufacturer.description },
            { value: manufacturer.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    manufacturerLinkColor (manufacturer, baseClass) {
      let classes = baseClass
      if (manufacturer.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.loading = true
      FleetDataService.getAllManufacturers()
        .then(response => {
          this.manufacturers = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('manufacturers-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('manufacturers-list')
  },
  components: { DataTable }
}
</script>
