<template>
  <div :class="groupClasses">
    <label :class="labelClasses" :for="id">{{ label }}</label>
    <div class="col-auto form-right-input">
      <select
        :id="id"
        :value="modelValue"
        :required="isRequired"
        :name="id"
        :style="selectStyle"
        :readonly="readonly"
        :disabled="disabled"
        :class="selectClasses"
        @input="$emit('update:modelValue', $event.target.value)"
        @change="change"
      >
        <slot name="options" />
        <option value="" v-if="noSelectionLabel">{{ noSelectionLabel }}</option>
        <option v-for="o in options" :key="o[cOptionKey]" :value="o[cValueKey]">{{ o[cDisplayKey] }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'selectStyle', 'selectClass', 'optionKey', 'valueKey', 'displayKey',
    'required', 'requireds', 'readonly', 'disabled', 'change', 'class', 'fieldName', 'options', 'noSelectionLabel'],
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    cOptionKey () {
      if (this.optionKey) {
        return this.optionKey
      }
      return 'id'
    },
    cValueKey () {
      if (this.valueKey) {
        return this.valueKey
      }
      return 'id'
    },
    cDisplayKey () {
      if (this.displayKey) {
        return this.displayKey
      }
      return 'displayName'
    },
    isRequired () {
      if (this.required) {
        return true
      }
      if (!this.fieldName) {
        return false
      }
      if (this.requireds !== undefined && this.requireds.length > 0 && this.requireds.includes(this.fieldName)) {
        return true
      }
      return false
    },
    groupClasses () {
      if (this.class) {
        return 'd-flex input-row text-input-row align-items-center ' + this.class
      }
      return 'd-flex input-row text-input-row align-items-center'
    },
    selectClasses () {
      let out = 'form-select select-input'
      if (this.selectClass) {
        if (typeof this.selectClass === 'object') {
          for (const k in this.selectClass) {
            if (this.selectClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.selectClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'input-row-header'
      if (this.isRequired) {
        out += ' label-required'
      }
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
