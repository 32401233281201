<template>
    <option value="00">12a</option>
    <option value="01">1a</option>
    <option value="02">2a</option>
    <option value="03">3a</option>
    <option value="04">4a</option>
    <option value="05">5a</option>
    <option value="06">6a</option>
    <option value="07">7a</option>
    <option value="08">8a</option>
    <option value="09">9a</option>
    <option value="10">10a</option>
    <option value="11">11a</option>
    <option value="12">12p</option>
    <option value="13">1p</option>
    <option value="14">2p</option>
    <option value="15">3p</option>
    <option value="16">4p</option>
    <option value="17">5p</option>
    <option value="18">6p</option>
    <option value="19">7p</option>
    <option value="20">8p</option>
    <option value="21">9p</option>
    <option value="22">10p</option>
    <option value="23">11p</option>
</template>
