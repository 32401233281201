<template>
  <TabBar activeTabName="misc" />
  <div class="row justify-content-center px-0 mx-0">
    <ManufacturerInfo />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ManufacturerInfo from '@/components/fleet/ManufacturerInfo.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    ManufacturerInfo,
    TabBar
  }
})
export default class ManufacturerDetails extends Vue {}
</script>
