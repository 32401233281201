import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container justify-content-center big-menu-list" }
const _hoisted_2 = {
  class: "list-group info-pane",
  style: {"border":"none"}
}
const _hoisted_3 = { class: "list-group-item" }
const _hoisted_4 = { class: "list-group-item" }
const _hoisted_5 = { class: "list-group-item d-none d-md-block" }
const _hoisted_6 = { class: "list-group-item d-block d-md-none subtle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("h4", null, "System Administration", -1)),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: {  name: 'admin-app-user-access'},
          class: "component-selector-link hoverable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa, {
              icon: "globe-americas",
              class: "map-icon me-2"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("b", null, "Manage App & API Users", -1))
          ]),
          _: 1
        }),
        _cache[1] || (_cache[1] = _createElementVNode("p", { class: "mt-2" }, "Access for Customers & External Users", -1))
      ]),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: {  name: 'admin-staff-access'},
          class: "component-selector-link hoverable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa, {
              icon: "shield-alt",
              class: "map-icon me-2"
            }),
            _cache[2] || (_cache[2] = _createElementVNode("b", null, "Manage Staff Member Access", -1))
          ]),
          _: 1
        }),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mt-2" }, "Staff Access to the Hub & Other Tools", -1))
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: {  name: 'admin-staff-roles'},
          class: "component-selector-link hoverable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa, {
              icon: "building-lock",
              class: "map-icon me-2"
            }),
            _cache[4] || (_cache[4] = _createElementVNode("b", null, "Configure Staff Roles & Privileges", -1))
          ]),
          _: 1
        }),
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mt-2" }, "Manage Least-Privileged Access Patterns", -1))
      ]),
      _createElementVNode("li", _hoisted_6, [
        _createVNode(_component_fa, {
          icon: "building-lock",
          class: "map-icon me-2"
        }),
        _cache[6] || (_cache[6] = _createElementVNode("b", null, "Configure Staff Roles & Privileges", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mt-2" }, "Not available on Mobile", -1))
      ])
    ])
  ]))
}