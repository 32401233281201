<template>
  <div class="container justify-content-center px-0 mx-0">
    <StaffAccessManagement />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'
import StaffAccessManagement from '@/components/admin/StaffAccessManagement.vue'

@Options({
  components: {
    StaffAccessManagement
  }
})
export default class StaffAccess extends Vue {
  mounted () {
    NavigationService.setNav('Admin', 'Staff Access', '', '', '#/admin', '', '', '')
  }
}
</script>
