<template>
  <div class="row justify-content-center mx-0 p-0">
    <div class="col-auto p-0 mb-3 pb-2">
      <ScriptInfoPane :script="script" :loading="isLoading" />
    </div>
    <div class="d-none d-md-block col-auto mx-3 p-0" />
    <div class="col-auto p-0">
      <ScriptVersionsList :script="script" :versions="versions" :loading="isLoading" :versionID="versionID"/>
      <ScriptRunsList :script="script" :runs="runs" :loading="isLoading" :reloadFunc="reloadRuns" :runID="runID" />
    </div>
  </div>
</template>
<script>
import ScriptInfoPane from '@/components/modeling/ScriptInfoPane.vue'
import ScriptVersionsList from '@/components/modeling/ScriptVersionsList.vue'
import ScriptRunsList from '@/components/modeling/ScriptRunsList.vue'
import NavigationService from '@/services/NavigationService'
import ModelingDataService from '@/services/ModelingDataService'
import HelpContent from '@/services/HelpContent'
import Reloadables from '@/services/reloadables'

export default {
  components: { ScriptInfoPane, ScriptRunsList, ScriptVersionsList },
  data () {
    return {
      id: '',
      script: null,
      versions: null,
      runs: null,
      isLoading: false,
      runID: this.$route.params.runID,
      versionID: this.$route.params.versionID
    }
  },
  emits: ['register-reloadable'],
  methods: {
    loadScriptInfo () {
      this.isLoading = true
      let loading1 = true
      let loading2 = true
      let loading3 = true
      ModelingDataService.retrieveScript(this.id)
        .then(result => {
          this.script = result.data
        })
        .catch(e => {
          let message = 'Unable to load Script Info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          loading1 = false
          this.isLoading = loading1 && loading2 && loading3
        })
      ModelingDataService.listScriptVersions(this.id)
        .then(result => {
          this.versions = result.data
        })
        .catch(e => {
          let message = 'Unable to load Script Versions'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          loading2 = false
          this.isLoading = loading1 && loading2 && loading3
        })
      const jobName = 'test_' + this.id
      ModelingDataService.listJobRuns(jobName)
        .then(result => {
          this.runs = result.data
        })
        .catch(e => {
          let message = 'Unable to load Job Runs'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          loading3 = false
          this.isLoading = loading1 && loading2 && loading3
        })
    },
    reloadRuns () {
      const jobName = 'test_' + this.id
      this.isLoading = true
      ModelingDataService.listJobRuns(jobName)
        .then(result => {
          this.runs = result.data
        })
        .catch(e => {
          let message = 'Unable to load Job Runs'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    this.id = this.$route.params.id
    NavigationService.setNavLevel3('Modeling', 'scripts', this.id, '#/modeling', '', '')
    this.loadScriptInfo()
    Reloadables.registerReloadable('script-info', this.loadScriptInfo)
  },
  unmounted () {
    Reloadables.unregisterReloadable('script-info')
  }
}
</script>
