<template>
    <transition name="modal">
      <Modal ref="baseModal" v-if="isBlocking && (isCreate || role)" @close="close()" sz="md">
        <template v-slot:header>
          {{ headerText }}
        </template>

        <template v-slot:body>
          <form ref="roleForm" @submit.prevent="handleSubmit" autocomplete="off" style="margin-top:-8px; margin-bottom:-12px">

            <div class="my-4">
              <TextInputRow label="Display Name"
                v-model="formData.label"
                labelClass="label-required"
                :required="true"
                placeholder="Support Agent"
                maxlength="36"
                dataType="description"
              />

              <TextInputRow label="Description"
                v-model="formData.description"
                labelClass="label-required"
                :required="true"
                placeholder="Can view Customer accounts"
                maxlength="48"
                dataType="description"
                inputStyle="width: 400px;"
              />
            </div>
          </form>
        </template>
        <template v-slot:footer>
          <div class="col-auto buttons-right">
            <button class="btn" @click="updateRole()" :disabled="(isLoading || !formData.label || !formData.description)" v-if="!isCreate">Update Role</button>
            <button class="btn" @click="createRole()" :disabled="(isLoading || !formData.label || !formData.description)" v-else>Create Role</button>
            <a class="btn btn-blue" style="margin-left: 20px" @click.prevent="close()">Cancel</a>
          </div>
        </template>
      </Modal>
    </transition>
  </template>
<script>
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import AdminDataService from '@/services/admin/AdminDataService'

import Modal from '@/components/modals/Modal.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'

export default {
  name: 'role-add-update',
  props: ['role', 'successFunc'],
  data () {
    return {
      isBlocking: false,
      formData: {},
      isCreate: false,
      isLoading: false,
      oldRoute: null
    }
  },
  computed: {
    headerText () {
      if (this.role) {
        return 'Edit Staff Role'
      }
      return 'Add New Staff Role'
    }
  },
  methods: {
    reset () {
      this.mode = ''
      if (this.role) {
        this.formData = { ...this.role }
      } else {
        this.isCreate = true
        this.formData = {}
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
      let path = this.$route.href
      if (this.isCreate) {
        return
      }
      this.$nextTick(() => {
        const st = path.indexOf('/staff-roles')
        if (st > 0) {
          path = path.substring(0, st + 12)
        }
        this.oldRoute = path
        history.pushState(
          {},
          null,
          path + '/' + this.role.id
        )
      })
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      history.pushState(
        {},
        null,
        this.oldRoute
      )
    },
    updateRole () {
      this.isLoading = true
      const data = {
        label: this.formData.label,
        description: this.formData.description
      }
      AdminDataService.updateStaffRole(this.role.id, data)
        .then(() => {
          HelpContent.setTimedFlashMessage('Role Updated')
          Reloadables.requestReload()
          if (this.successFunc) {
            this.successFunc()
          }
          this.close()
        })
        .catch(e => {
          let message = 'Role Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    createRole () {
      this.isLoading = true
      const data = {
        label: this.formData.label,
        description: this.formData.description
      }
      AdminDataService.createStaffRole(data)
        .then((response) => {
          HelpContent.setTimedFlashMessage('Role Created')

          if (response.data && response.data.id) {
            this.$router.push({ name: 'admin-staff-roles-id', params: { roleID: response.data.id } })
            return
          }
          Reloadables.requestReload()
          this.close()
        })
        .catch(e => {
          let message = 'Role Creation Failed'
          if (e.response && e.response.data && e.response.data.message) {
            if (e.response.data.message === 'ConditionalCheckFailedException: The conditional request failed') {
              message += ': Role Already Exists'
            } else {
              message += `: ${e.response.data.message}`
            }
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  components: { Modal, TextInputRow }
}
</script>
