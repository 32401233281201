<template>
  <div class="container justify-content-center big-menu-list">

    <h4>System Administration</h4>
    <ul class="list-group info-pane" style="border:none">

      <li class="list-group-item">
        <router-link :to="{  name: 'admin-app-user-access'}" class="component-selector-link hoverable">
          <fa icon="globe-americas" class="map-icon me-2" />
          <b>Manage App &amp; API Users</b>
        </router-link>
        <p class="mt-2">Access for Customers &amp; External Users</p>
      </li>

      <li class="list-group-item">
        <router-link :to="{  name: 'admin-staff-access'}" class="component-selector-link hoverable">
          <fa icon="shield-alt" class="map-icon me-2" />
          <b>Manage Staff Member Access</b>
        </router-link>
        <p class="mt-2">Staff Access to the Hub &amp; Other Tools</p>
      </li>

      <li class="list-group-item d-none d-md-block">
        <router-link :to="{  name: 'admin-staff-roles'}" class="component-selector-link hoverable">
          <fa icon="building-lock" class="map-icon me-2" />
          <b>Configure Staff Roles &amp; Privileges</b>
        </router-link>
        <p class="mt-2">Manage Least-Privileged Access Patterns</p>
      </li>

      <li class="list-group-item d-block d-md-none subtle">
        <fa icon="building-lock" class="map-icon me-2" />
        <b>Configure Staff Roles &amp; Privileges</b>
        <p class="mt-2">Not available on Mobile</p>
      </li>

    </ul>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'

export default class AdminHome extends Vue {
  mounted () {
    NavigationService.setNav('Admin', '', '', '', '', '', '', '')
  }
}
</script>
