<template>
  <div class="container justify-content-center px-0 mx-0">
    <StaffRoleManagement />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'
import StaffRoleManagement from '@/components/admin/StaffRoleManagement.vue'

@Options({
  components: {
    StaffRoleManagement
  }
})
export default class StaffRoles extends Vue {
  mounted () {
    NavigationService.setNav('Admin', 'Staff Roles', '', '', '#/admin', '', '', '')
  }
}
</script>
