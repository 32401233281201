<template>
  <div class="row form-group">
    <label :class="labelClasses" for="address1">Address 1</label>
    <div class="col-auto form-right-input">
      <input
        type="text"
        id="address1"
        :value="modelValue.address1"
        name="address1"
        :style="inputStyle"
        placeholder="1209 DeSoto Ave."
        maxlength="64"
        :class="inputClasses"
        @input="updateAddress1($event.target.value)"
      />
    </div>
  </div>

  <div class="row form-group">
    <label :class="labelClasses" for="address2">Address 2</label>
    <div class="col-auto form-right-input">
      <input
        type="text"
        id="address2"
        :value="modelValue.address2"
        name="address2"
        :style="inputStyle"
        placeholder="Suite C"
        maxlength="64"
        :class="inputClasses"
        @input="updateAddress2($event.target.value)"
      />
    </div>
  </div>

  <div class="row form-group">
    <label :class="labelClasses" for="city">City</label>
    <div class="col-auto form-right-input">
      <input
        type="text"
        id="city"
        :value="modelValue.city"
        name="city"
        :style="inputStyle"
        placeholder="Kannapolis"
        maxlength="32"
        :class="inputClasses"
        @input="updateCity($event.target.value)"
      />
    </div>
  </div>

  <div class="row form-group select-row">
    <label :class="labelClasses" for="state">State</label>
    <div class="col-auto form-right-input">
      <select
        id="state"
        :value="modelValue.state"
        name="state"
        class="form-select form-select-narrow"
        @input="updateState($event.target.value)"
      >
        <option value="">--</option>
        <StateOptions />
      </select>
    </div>
    <label :class="labelClasses" style="width:auto !important; padding-left: 4px;" for="postalCode">Zip</label>
    <div class="col-auto">
      <input
        type="text"
        id="postalCode"
        :value="modelValue.postalCode"
        name="postalCode"
        style="width: 104px;"
        placeholder="28027-4737"
        maxlength="10"
        :class="inputClasses"
        @keypress="checkZipInput"
        @input="updatePostal($event.target.value)"
      />
    </div>
  </div>

  <div class="row form-group">
    <label :class="labelClasses" for="country">Country</label>
    <div class="col-auto form-right-input">
      <select
        id="country"
        :value="modelValue.country"
        name="country"
        class="form-select form-select-narrow"
        @input="updateCountry($event.target.value)"
      >
        <option value="">--</option>
        <CountryOptions />
      </select>
    </div>
  </div>

</template>
<script>
import StateOptions from '@/components/selectOptions/StateOptions.vue'
import CountryOptions from '@/components/selectOptions/CountryOptions.vue'

export default {
  components: { StateOptions, CountryOptions },
  props: ['id', 'modelValue', 'labelClass', 'inputStyle', 'inputClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled'],
  emits: ['update:modelValue'],
  data () {
    return {
      newFormData: {}
    }
  },
  methods: {
    checkZipInput (e) {
      if (!e.key) {
        return
      }
      if (!'0123456789-'.includes(e.key)) {
        e.preventDefault()
      }
    },
    updateAddress1 (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.address1 = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updateAddress2 (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.address2 = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updateCity (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.city = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updateState (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.state = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updatePostal (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.postalCode = value
      this.$emit('update:modelValue', this.newFormData)
    },
    updateCountry (value) {
      this.newFormData = { ...this.modelValue }
      this.newFormData.country = value
      this.$emit('update:modelValue', this.newFormData)
    }
  },
  computed: {
    selectClasses () {
      let out = 'form-select'
      if (this.selectClass) {
        if (typeof this.selectClass === 'object') {
          for (const k in this.selectClass) {
            if (this.selectClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.selectClass}`
        }
      }
      return out
    },
    inputClasses () {
      let out = 'form-input text-input'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
