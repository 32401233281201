<template>
  <div v-if="job || script">
    <div class="ms-2 me-3">

      <h5 v-if="showTitle && job">Start an Ad-Hoc Run</h5>
      <h5 v-else-if="showTitle && script">Start a Test Run</h5>

      <TextLabelRow label="Job" :value="job.name" valueClass="notable" v-if="job" />
      <TextLabelRow label="Script" :value="script.name" valueClass="notable" v-if="script" />
      <TextLabelRow label="Script" :value="job.scriptName" valueClass="notable" v-else-if="job" />
      <TextInputRow label="Concurrency" v-model="concurrency" dataType="integer" valueClass="notable" v-if="job" />

      <SelectRow label="Version" v-model="selectedVersion" v-if="!version">
        <template v-slot:options>
          <option value=""> -- Select a Version --</option>
          <option v-for="(v, i) in runnableScriptVersion" :key="i" :value="v.version">{{ v.version }}</option>
        </template>
      </SelectRow>

      <TextAreaRow v-model="args" label="Args" />

    </div>
  </div>
</template>
<script>
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import ModelingDataService from '@/services/ModelingDataService'
import HelpContent from '@/services/HelpContent'

export default {
  data () {
    return {
      loadedVersion: null,
      versionIndex: -1,
      scriptVersions: [],
      args: '',
      concurrency: 0,
      selectedVersion: ''
    }
  },
  props: ['job', 'script', 'version', 'showTitle', 'updateFunc', 'calledFunc'],
  components: { TextLabelRow, TextInputRow, TextAreaRow, SelectRow },
  watch: {
    canStartRun: {
      handler () {
        if (this.updateFunc) {
          this.updateFunc(this.canStartRun)
        }
      }
    }
  },
  computed: {
    runnableScriptVersion () {
      const out = []
      for (const v of this.scriptVersions) {
        if (!v.scriptURL) {
          continue
        }
        if (this.job && !v.published) {
          continue
        }
        out.push(v)
      }
      out.sort()
      out.reverse()
      return out
    },
    canStartRun () {
      let b = false
      if ((this.job || this.script) && this.selectedVersion) {
        b = true
      }
      return b
    },
    jobName () {
      if (this.job) {
        return this.job.name
      }
      if (this.script) {
        return 'test_' + this.script.name
      }
      return null
    },
    scriptName () {
      if (this.script) {
        return this.script.name
      }
      if (this.job) {
        return this.job.scriptName
      }
      return null
    }
  },
  methods: {
    start () {
      let isError = false
      let message = ''
      const runConfig = {
        jobName: this.jobName,
        scriptName: this.scriptName,
        scriptVersion: this.selectedVersion,
        args: this.args.trim()
      }
      if (this.concurrency) {
        runConfig.concurrency = parseInt(this.concurrency)
      }
      ModelingDataService.startNewAdHocRun(runConfig)
        .catch(e => {
          message = 'Unable to schedule the Run'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          isError = true
        })
        .finally(() => {
          if (this.calledFunc) {
            this.calledFunc(message, isError)
          }
        })
    },
    refreshVersions () {
      if ((!this.job && !this.script) || this.version) {
        return
      }
      if (this.job) {
        this.args = this.job.args
        this.selectedVersion = this.job.selectedVersion
        this.concurrency = this.job.concurrency
      }
      ModelingDataService.listScriptVersions(this.scriptName)
        .then(response => {
          this.scriptVersions = response.data
        })
        .catch(e => {
          let message = 'Unable to fetch Script Versions'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
    }
  }
}
</script>
