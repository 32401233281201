<template>
  <div class="container justify-content-center px-0 mx-0">
    <AppUserManagement />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavigationService from '@/services/NavigationService'
import AppUserManagement from '@/components/admin/AppUserManagement.vue'

@Options({
  components: {
    AppUserManagement
  }
})
export default class AppUserAccess extends Vue {
  mounted () {
    NavigationService.setNav('Admin', 'App User Access', '', '', '#/admin', '', '', '')
  }
}
</script>
