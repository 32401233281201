<template>
  <div class="row justify-content-center mx-0 p-0">
    <div class="col-auto p-0">
      <InfoPane
        class="sub-info-pane mb-4"
        title="Apple Push Subscription Info"
        v-if="sub"
      >
        <template v-slot:info>
          <ul v-if="sub" class="info-pane-list sub-info-pane">
            <li class="info-pane-list-item">
              <label class="info-item-label width-65 me-1">Token:</label>
              {{ truncatedToken }}
              <a @click="copyTokenToClipboard">
                <fa icon="copy" class="button-icon" style="height:16px" />
              </a>
            </li>
            <li class="info-pane-list-item">
              <label class="info-item-label width-65 me-1">Bundle:</label>
              {{ sub.appID }}
            </li>
            <li class="info-pane-list-item">
              <label class="info-item-label width-65 me-1">Email:</label>
              {{ sub.user }}
            </li>
            <li class="info-pane-list-item">
              <label class="info-item-label width-65 me-1">Site:</label>
              <span class="font-reduce-1">{{ siteDisplayName(sub.site) }}</span>
            </li>
            <li class="info-pane-list-item">
              <label class="info-item-label width-65 me-1">Device:</label>
              {{ sub.deviceType }}
            </li>
            <li class="info-pane-list-item d-flex align-items-center" v-if="sub.lastModified">
              <span class="subtle-info status-last-modified">
                {{ getSinceSecs(sub.lastModified) }}
              </span>
              <span class="subtle-info font-reduce-2" v-if="sub.lastModifiedBy"> by {{ sub.lastModifiedBy }}</span>
            </li>

            <li class="info-pane-list-item d-flex justify-content-end">
              <button :disabled="isLoading" class="btn btn-red" @click.prevent="deleteSubscription()">Delete Subscription</button>
            </li>
          </ul>
        </template>
      </InfoPane>
    </div>
    <div class="d-none d-md-block col mx-3 p-0" style="width:10px;max-width:10px;" />
    <div class="col-auto p-0">
      <InfoPane
        class="sub-test-message-pane"
        title="Send Test Message to this Device"
        v-if="sub"
      >
        <template v-slot:info>
          <ul v-if="sub" class="info-pane-list sub-info-pane">
            <li class="info-pane-list-item d-flex py-3">
              <label class="info-item-label label-required mt-1 me-2">Template:</label>
              <select class="form-select" v-model="testNotificationTemplate" style="width: 100%">
                <option value="">-- Select Template --</option>
                <option v-for="tmpl in pushTemplates" :value="tmpl.id" :key="tmpl.id">
                  {{ tmpl.description }}
                </option>
              </select>
            </li>
            <li class="info-pane-list-item d-flex justify-content-end">
              <button :disabled="isLoading" class="btn btn-red" @click.prevent="sendNotification()">Send Test Notification</button>
            </li>
          </ul>
        </template>
      </InfoPane>
    </div>
  </div>

</template>
<script>
import NotificationsDataService from '@/services/notifications/NotificationsDataService'
import FleetDataService from '@/services/fleet/FleetDataService'
import BaseSubscriptionComponent from '@/components/notifications/BaseSubscriptionComponent.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  extends: BaseSubscriptionComponent,
  components: { InfoPane },
  data () {
    return {
      sub: null,
      testNotificationTemplate: ''
    }
  },
  computed: {
    truncatedToken () {
      if (!this.sub || !this.sub.address) {
        return ''
      }
      return '...' + this.getLast10(this.sub.address)
    }
  },
  methods: {
    siteDisplayName (input) {
      return FleetDataService.getSiteDisplayName(input)
    },
    copyTokenToClipboard () {
      if (this.sub && this.sub.address) {
        navigator.clipboard.writeText(this.sub.address)
        HelpContent.setTimedFlashMessage('Full Token copied to clipboard.')
      }
    },
    getApnSubscription (deviceToken, bundleID, site) {
      this.isLoading = true
      NotificationsDataService.getApnSubscriptionByDeviceToken(deviceToken, bundleID, site)
        .then(response => {
          this.sub = response.data
          this.sub.id = this.sub.address + '.' + this.sub.appID
        })
        .catch(e => {
          let message = 'Failed to fetch Apple Subscription Info'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    sendNotification () {
      if (!this.sub || !this.testNotificationTemplate) {
        return
      }
      var data = {
        deviceToken: this.sub.address,
        bundleID: this.sub.appID,
        template: this.testNotificationTemplate
      }
      if (confirm('This will send a real notification to a real device. All usage is logged. Are you sure?')) {
        NotificationsDataService.sendTestNotification(data)
          .then(() => {
            HelpContent.setTimedFlashMessage('Notification was sent successfully.')
          })
          .catch(e => {
            let message = 'Failed to send Notification'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    deleteSubscription () {
      if (!this.sub) {
        return
      }
      if (confirm(`The User will stop receiving notifications for ${this.sub.site} but can re-subscribe by launching the the App. Are you sure?`)) {
        this.isLoading = true
        NotificationsDataService.deleteApnSubscriptionByDeviceToken(this.sub.address,
          this.sub.appID, this.sub.site)
          .then(() => {
            HelpContent.setTimedFlashMessage(`Subscription for ${this.sub.user} to ${this.sub.site} has been deleted.`)
            this.$router.push('/subscriptions')
          })
          .catch(e => {
            let message = 'Failed to delete Apple Subscription'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    loadInfo () {
      this.getApnSubscription(this.$route.params.deviceToken, this.$route.params.bundleID, this.$route.params.site)
    }
  },
  mounted () {
    this.loadInfo()
    this.setNavSub(this.getLast10(this.$route.params.deviceToken))
    Reloadables.registerReloadable('sub-info', this.loadInfo)
    this.retrieveAllTemplates()
  },
  unmounted () {
    Reloadables.unregisterReloadable('sub-info')
  }
}
</script>
