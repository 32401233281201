<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" :sz="modalSz" :isScrollable="modalSz === 'sm'">
      <template v-slot:header>
        Select a Threshold:
      </template>

      <template v-slot:body>
        <ul v-if="threshIds.length > 0" class="py-3">
          <li :key="id" v-for="id in threshIds" class="my-3 ms-5">
            <div class="nav-link" @click="() => { showThreshold(id) }">{{ this.getLastWord(id) }}</div>
          </li>
        </ul>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Modal from '@/components/modals/Modal.vue'
import BaseComponent from '@/components/BaseComponent.vue'

export default {
  name: 'ads-alert-thresholds-modal',
  extends: BaseComponent,
  props: ['ids', 'closer', 'showThresholdFunc'],
  data () {
    return {
      modalSz: 'sm',
      isBlocking: false
    }
  },
  computed: {
    threshIds () {
      if (this.ids) {
        return this.ids.split(',')
      }
      return []
    }
  },
  methods: {
    showThreshold (id) {
      if (this.showThresholdFunc) {
        this.showThresholdFunc(id)
      }
      this.close()
    },
    show () {
      this.isBlocking = true
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
      if (this.closer) {
        this.closer()
      }
    },
  },
  components: {
    Modal,
  }
}
</script>
