<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close()" sz="smx2">
      <template v-slot:header>
        Nodes With Layout Errors
      </template>

      <template v-slot:body>
        <div v-if="mode === 'table'" class="status-warning ms-2 mb-3">
          Note: Recent changes may take up to 5 minutes to be reflected in this list.
        </div>
        <div style="min-height: 300px; max-height: 580px; overflow-y: scroll">
          <DataTable
            :cols="cols"
            :rows="rows"
            :loading="isLoading"
            noDataMessage="No Nodes with Layout Errors Found"
            :initialSort="0"
            v-if="mode === 'table'"
            class="ms-3 mb-3 me-2"
          />
          <div v-else-if="mode ==='detail' && detailNode" class="mt-2 ps-3">
            <div>
              <a class="normal" @click="mode = 'table'">&lt;&lt; Back</a>
            </div>
            <div class="m-3 pane-shaded p-3">
              <p>
                <span class="bold me-2">Customer:</span>
                <router-link :to="{ name: 'customer-details', params: {id: detailNode.customer }}">{{ detailNode.customerDisplayName }}</router-link>
              </p>
              <p>
                <span class="bold me-2">Site:</span>
                <router-link :to="{ name: 'site-details', params: {id: detailNode.site }}">{{ detailNode.siteDisplayName }}</router-link>
              </p>
              <p>
                <span class="bold me-2">Node:</span>
                <router-link :to="{ name: 'site-details-node', params: {id: detailNode.site, nodeID: detailNode.id }}">{{ detailNode.displayName }}</router-link>
              </p>
              <p><b>Layout:</b> {{ detailNode.layout.displayName }}</p>
              <hr />
              <table>
                <tbody>
                <tr v-for="(slot, i) in detailNode.slotData" :key="i">
                  <td class="px-2" v-if="!slot[1]">❌</td>
                  <td class="px-2 status-ok" v-else>✓</td>
                  <td class="pe-3">{{ slot[0] }}</td>
                  <td class="pe-2 warning" colspan="2" v-if="!slot[1] && !slot[4]">{{ slot[2] }}</td>
                  <td class="pe-2 warning" v-else-if="!slot[1]">{{ slot[2] }}</td>
                  <td class="pe-2" v-else>{{ slot[2] }}</td>
                  <td v-if="slot[3]">{{ slot[3] }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script>
import { getSlotData, deviceDataFromIndexItem } from '@/components/fleet/nodeLayout.ts'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/modals/Modal.vue'
import DataTable from '@/components/tables/DataTable.vue'

export default {
  name: 'modal-node-layout-errors',
  components: { Modal, DataTable },
  data () {
    return {
      isBlocking: false,
      isLoading: false,
      layouts: {},
      mode: 'table',
      detailNode: undefined
    }
  },
  computed: {
    cols () {
      return [
        { text: 'Customer', sort: 0 },
        { text: 'Site', sort: 1 },
        { text: 'Node', sort: 2 },
        { text: 'Details', sort: 3 }
      ]
    },
    rows () {
      const out = []
      if (!this.nodesWithErrors || this.nodesWithErrors.length === 0) {
        return out
      }
      for (const node of this.nodesWithErrors) {
        out.push([
          { value: node.customerDisplayName },
          { value: node.siteDisplayName },
          { value: node.displayName },
          { icon: 'magnifying-glass', click: this.showDetail, i: node }
        ])
      }
      return out
    },
    nodesWithErrors () {
      const out = []
      for (const node of this.activeNodes) {
        if (!node.layout || !(node.layout in this.layoutLookup)) {
          continue
        }
        if (node.noEquipMon === true) {
          continue
        }
        if (!(node.id in this.devicesByNode)) {
          continue
        }
        const devs = this.devicesByNode[node.id]
        if (!devs || devs.length === 0) {
          continue
        }
        const layout = this.layoutLookup[node.layout]
        const sd = getSlotData(this.layoutLookup[node.layout], devs)

        let hasErrors = false
        for (const row of sd) {
          if (!row || row.length != 5) {
            continue
          }
          if (row[1] === false) {
            hasErrors = true
            break
          }
        }
        if (!hasErrors) {
          continue
        }
        out.push({
          id: node.id,
          displayName: node.title,
          site: node.site,
          siteDisplayName: node.suffix,
          devs: devs,
          layout: layout,
          customer: node.customer,
          customerDisplayName: node.customerName,
          slotData: sd,
        })
      }
      return out
    },
    devicesByNode () {
      const out = {}
      if (!FleetDataService.manifest.data || FleetDataService.manifest.data.length === 0) {
        return out
      }
      for (const key in FleetDataService.manifest.data) {
        if (!key.startsWith('device.')) {
          continue
        }
        const device = FleetDataService.manifest.data[key]
        if (device.status === 'inactive') {
          continue
        }
        if (!device.node ||
          !(device.node in this.activeNodesLookup)) {
          continue
        }

        let devs = []
        if (!(device.node in out)) {
          out[device.node] = devs
        } else {
          devs = out[device.node]
        }
        devs.push(deviceDataFromIndexItem(device))
      }
      return out
    },
    activeNodesLookup () {
      const out = {}
      if (this.activeNodes.length === 0) {
        return out
      }
      for (const node of this.activeNodes) {
        out[node.id] = node
      }
      return out
    },
    activeNodes () {
      const out = []
      if (!FleetDataService.manifest.data || FleetDataService.manifest.data.length === 0) {
        return out
      }
      for (const key in FleetDataService.manifest.data) {
        if (!key.startsWith('node.')) {
          continue
        }
        const node = FleetDataService.manifest.data[key]
        if (node.status !== 'active') {
          continue
        }
        node.id = key.replace('node.', '')
        out.push(node)
      }
      return out
    },
    layoutLookup () {
      const out = {}
      if (this.layouts && this.layouts.length > 0) {
        for (const layout of this.layouts) {
          out[layout.id] = layout
        }
      }
      return out
    },
  },
  methods: {
    showDetail (node) {
      this.detailNode = node
      this.mode = 'detail'
    },
    getLayouts () {
      this.isLoading = true
      FleetDataService.listNodeLayouts()
      .then((resp) => {
        this.layouts = resp.data
      })
      .catch(e => {
        let message = 'Failed to retrieve the Layout Manifest'
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        console.log('ERROR', e)
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    reset () {
      this.mode = 'table'
      this.isLoading = false
      this.getLayouts()
    },
    show () {
      this.isBlocking = true
      this.reset()
    },
    close () {
      this.$refs.baseModal.close()
      this.isBlocking = false
    }
  }
}
</script>
