<script>
import BaseNotificationComponent from '@/components/notifications/BaseNotificationComponent.vue'
import NotificationsDataService from '@/services/notifications/NotificationsDataService'

export default {
  name: 'base-template-component',
  extends: BaseNotificationComponent,
  data () {
    return {
      attachmentOptions: {
        dailyGtReports: 'Daily Grower\'s Reports',
        monthlyGtReports: 'Monthly Grower\'s Reports'
      },
      appSounds: []
    }
  },
  methods: {
    getAppSounds () {
      if (this.appSounds.length > 0) {
        return
      }
      this.appSounds.push({
        id: 'default',
        filename: '',
        label: 'System Default'
      })
      NotificationsDataService.getAppSounds()
        .then((resp) => {
          if (!resp.data) {
            return
          }
          for (const snd of resp.data) {
            this.appSounds.push(snd)
          }
        })
    }
  }
}
</script>
