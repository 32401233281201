<template>
    <div>
      <ModalAddUpdateFlashingAppliance ref="flashAppAdd" />
      <ModalAddUpdateFlashingAppliance ref="flashAppEdit" :flashingAppliance="flashingAppliance" />
      <DataTable
        class="dt-flashapps"
        title="All Flashing Appliances"
        :cols="cols"
        :rows="rows"
        :loading="isLoading"
        :actionFunc="showAddModal"
        actionIcon="plus"
        itemType="Appliance"
      >
      </DataTable>
    </div>
  </template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import ModalAddUpdateFlashingAppliance from '@/components/fleet/ModalAddUpdateFlashingAppliance.vue'

import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'

export default {
  name: 'flashingAppliances-list',
  extends: BaseFleetComponent,
  data () {
    return {
      flashingAppliances: null,
      cols: [
        { text: 'Ser. #' },
        { text: 'Description' },
        { icon: 'clock' },
        { text: 'Reported FW' },
        { text: 'Target FW' },
        { text: 'Model' },
        { text: 'Notes' }
      ],
      flashingAppliance: null,
      isLoading: false
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.flashingAppliances && this.flashingAppliances.length > 0) {
        for (const flashApp of this.flashingAppliances) {
          out.push([
            { value: flashApp.serial, click: this.showEditModal, i: flashApp },
            { value: flashApp.description },
            { value: this.getSinceSecs(flashApp.lastContact) },
            { value: flashApp.swVersionCurrent },
            { value: flashApp.swVersionTarget },
            { value: flashApp.model },
            { value: flashApp.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    flashAppLinkColor (flashApp, baseClass) {
      let classes = baseClass
      if (flashApp.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    refreshList () {
      this.isLoading = true
      FleetDataService.getFlashingAppliances()
        .then(response => {
          this.flashingAppliances = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showAddModal () {
      this.$refs.flashAppAdd.show()
    },
    showEditModal (flashApp) {
      this.flashingAppliance = { ...flashApp }
      this.$nextTick(() => {
        this.$refs.flashAppEdit.show()
      })
    }
  },
  mounted () {
    Reloadables.registerReloadable('flashapps-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('flashapps-list')
  },
  components: { DataTable, ModalAddUpdateFlashingAppliance }
}
</script>
