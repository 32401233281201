<template>
  <TabBar activeTabName="sites" />
  <div class="row justify-content-center px-0 mx-0">
    <div class="col-auto px-0 mx-0">
      <SiteInfo v-if="site" :site="site" :customer="customer"  @reloadSite="loadSite" />
      <SiteLocation v-if="site" :site="site" :nodes="nodes" :nodeColors="nodeColors" @reloadSite="loadSite" :gateways="gateways" :showNodeEditorFunc="showNodeEditor"  />
      <SiteIrrigation v-if="site" :site="site" @reloadSite="loadSite" class="mb-2 pb-2" />
    </div>
    <div class="col-auto d-xl"></div>
    <div class="col-auto px-0 mx-0">
      <GatewaysBySiteList v-if="site" :siteData="site" />
      <DevicesBySiteList  v-if="site" :siteData="site" class="mt-3 pt-1" :nodes="nodes" :deviceList="devices"/>
      <NodesBySiteList
        ref="nodesBySiteList"
        :nodeColors="nodeColors"
        :site="site"
        :nodes="nodes"
        @reloadSite="reloadSite"
        :loading="loading"
        :nodeID="nodeID"
        :deviceList="devices"
      />
      <AlertsByN entityType="Site" v-if="site" :site="site" class="mt-3" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SiteInfo from '@/components/fleet/SiteInfo.vue'
import AlertsByN from '@/components/fleet/AlertsByN.vue'
import DevicesBySiteList from '@/components/fleet/DevicesBySiteList.vue'
import GatewaysBySiteList from '@/components/fleet/GatewaysBySiteList.vue'
import NodesBySiteList from '@/components/fleet/NodesBySiteList.vue'
import TabBar from '@/components/fleet/TabBar.vue'
import SiteLocation from '@/components/fleet/SiteLocation.vue'
import SiteIrrigation from '@/components/fleet/SiteIrrigation.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'
import NavigationService from '@/services/NavigationService'

@Options({
  components: {
    SiteInfo,
    AlertsByN,
    NodesBySiteList,
    DevicesBySiteList,
    GatewaysBySiteList,
    TabBar,
    SiteLocation,
    SiteIrrigation
  },
  data () {
    return {
      site: null,
      nodes: null,
      gateways: null,
      customer: null,
      devices: null,
      loading: false,
      nodeID: this.$route.params.nodeID
    }
  },
  methods: {
    showNodeEditor (id: string) {
      this.$refs.nodesBySiteList.showNodeEditorById(id)
    },
    loadSite () {
      this.reloadSite(true)
    },
    setNavSite (custLabel, custID, siteLabel) {
      this.$state.currentSpace = 'fleet'
      NavigationService.setNavLevel3('Fleet', custLabel, siteLabel, '#/fleet', '#/customers/' + custID, '')
    },
    reloadSite (isInitialLoad = false) {
      if (isInitialLoad) {
        this.site = null
      }
      this.loading = true
      let loading1 = true
      let loading2 = true
      let loading3 = true
      let loading4 = true
      let loading5 = true
      FleetDataService.getSite(this.$route.params.id)
        .then(response => {
          this.site = response
          if (this.site.customer) {
            FleetDataService.getCustomer(this.site.customer)
              .then(response => {
                this.customer = response.data
                this.setNavSite(response.data.displayName, this.customer.id, this.site.displayName)
                loading1 = false
                this.loading = loading1 || loading2 || loading3 || loading4 || loading5
              })
          } else {
            loading1 = false
            this.loading = loading1 || loading2 || loading3 || loading4 || loading5
          }
        })
      FleetDataService.getSiteNodes(this.$route.params.id)
        .then(response => {
          this.nodes = response
          loading2 = false
          this.loading = loading1 || loading2 || loading3 || loading4 || loading5
        })
      FleetDataService.getGatewaysBySite(this.$route.params.id)
        .then(response => {
          this.gateways = response.data
          loading3 = false
          this.loading = loading1 || loading2 || loading3 || loading4 || loading5
        })
      FleetDataService.getDevicesBySite(this.$route.params.id)
        .then(response => {
          this.devices = response.data
          loading5 = false
          this.loading = loading1 || loading2 || loading3 || loading4 || loading5
        })
    }
  },
  computed: {
    nodeColors () {
      const out = {}
      const colors = ['#009900', '#ddaa00', '#439cfa', '#dd3399', '#0000cc', '#cc6633', '#00aa88', '#dd5577', '#A47551', '#888888', '#cc00cc', '#dddd00', '#cc0000', '#000000', '#ee9966', '#33aa33', '#00ccff', '#ff6666', '#777777', '#00ff00']
      let i = 0
      for (const key in this.nodes) {
        out[key] = i >= colors.length ? '#cccccc' : colors[i]
        i += 1
      }
      return out
    }
  },
  mounted () {
    Reloadables.registerReloadable('site-full-details', this.reloadSite)
    this.$nextTick(() => {
      this.loadSite()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('site-full-details')
  }
})
export default class SiteDetails extends Vue {}
</script>
