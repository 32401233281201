<template>
  <header>
    <NavBar />
    <Flash />
  </header>

  <div v-if="isSignedIn" class="container" id="app-area">
    <router-view :key="$route.fullPath"/>
  </div>

  <footer>
    <Footer />
  </footer>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Flash from '@/components/Flash.vue'
import { loadTheme } from '@/app/themes'

export default {
  name: 'App',
  components: { NavBar, Footer, Flash },
  mounted () {
    document.documentElement.setAttribute('data-theme', loadTheme())
  },
  computed: {
    isSignedIn () {
      return this.$state.isSignedIn
    },
    userInfo () {
      return this.$state.email
    }
  }
}
</script>
