<template>
  <div>
    <ModalConfirmDeleteUser ref="confirmDeleteUserModal" :email="emailUser" :reloader="getUserAccess" v-if="emailUser" />
    <h4>
      <fa icon="earth-americas" class="map-icon me-2" />
      Manage App &amp; API Users
    </h4>

    <hr class="mt-3 mb-1" />

    <div class="row mb-3">

      <div class="col-auto mt-3">
        <span class="me-2">Search By:</span>
        <div class="btn-group" role="group" aria-label="User Search Filter" style="max-height:27px !important;">
          <input type="radio" class="btn-check" value="customer" name="scope"
            id="scope-customer" v-model="scope" autocomplete="off" />
          <label class="btn btn-outline-primary" for="scope-customer" @click="emailUser = ''; addUserEmail = ''; customer = ''">Customer</label>
          <input type="radio" class="btn-check" value="email" name="scope"
            id="scope-email" v-model="scope" autocomplete="off" />
          <label class="btn btn-outline-primary" for="scope-email" @click="emailUser = ''; addUserEmail = ''; customer=''">User</label>
        </div>
      </div>

      <div v-if="scope === 'customer'" class="col ms-2 mt-3">
        <div>
          <select
            v-model="customer"
            class="form-select"
            style="width:200px;max-height:28px !important; padding-top:2px;"
            @change="loadCustomerAccess()"
          >
            <option value="">-- Select Customer --</option>
            <option v-for="customer in activeCustomers" :key="customer.id" :value="customer.id">{{ customer.displayName }}</option>
          </select>
        </div>
      </div>

      <div v-else-if="scope === 'email'" class="col ms-2 mt-3">
        <input
          v-model="emailSearch"
          class="form-input ps-1"
          style="height:27px !important; width:200px;"
          placeholder="user@example.com"
          @keypress="keyPressed"
        />
        <button class="btn ms-2" style="max-height:27px !important;padding-top:2px;margin-top:-3px;" @click="loadUserAccess()">Go</button>
      </div>

    </div>
    <hr class="mt-3 mb-3" />

    <h4 v-if="scope === 'customer' && customer">{{ customerDisplayName }}</h4>
    <div class="d-flex justify-content-between" style="max-width: 549px; margin-bottom: 5px;" v-else-if="scope === 'email' && emailUser">
      <h4>{{ emailUser }}</h4>
      <button class="btn btn-red" @click="showDeleteUserModal">
        Delete User
      </button>
    </div>

    <div class="row m-0 p-0 mb-3">
        <div class="col p-0">
          <DataTable
            v-if="scope === 'customer' && customer"
            class="user-access-cust-cust mb-4"
            title="Customer-wide Access"
            :cols="cwCols"
            :rows="cwRows"
            :loading="isLoading"
            noDataMessage="No users have been granted access"
            :initialSort="0"
          />

          <DataTable
            v-else-if="scope === 'email' && emailUser"
            class="user-access-cust-cust mb-4"
            title="Customer-wide Access"
            :cols="ecwCols"
            :rows="ecwRows"
            :loading="isLoading"
            noDataMessage="User has no Customer-wide access"
            :initialSort="0"
          />

        </div>
        <div class="col-auto d-xl"></div>
        <div class="col p-0">
          <DataTable
            v-if="scope === 'customer' && customer"
            class="user-access-cust-cust mb-4"
            title="Site-specific Access"
            :cols="spCols"
            :rows="spRows"
            :loading="isLoading"
            noDataMessage="No Site-specific access for this Customer"
            :initialSort="0"
          />

          <DataTable
            v-else-if="scope === 'email' && emailUser"
            class="user-access-cust-cust mb-4"
            title="Site-specific Access"
            :cols="espCols"
            :rows="espRows"
            :loading="isLoading"
            noDataMessage="User has no Site-specific access"
            :initialSort="0"
          />

          <InfoPane v-if="customer || emailUser"
          title="Grant Access for User"
          >
            <template v-slot:info>
              <div class="m-3">

                <label class="form-label me-3" style="width:70px;text-align:right;">Scope</label>
                <div class="btn-group mb-2" role="group" aria-label="User Access Scope" style="max-height:27px !important;">
                  <input type="radio" class="btn-check" value="c" name="addScope"
                    id="addScope-c" v-model="addScope" autocomplete="off" :disabled="emailUser !== ''" />
                  <label class="btn btn-outline-primary" for="addScope-c" :disabled="emailUser !== ''" >Customer-wide</label>
                  <input type="radio" class="btn-check" value="s" name="addScope"
                    id="addScope-s" v-model="addScope" autocomplete="off" />
                  <label class="btn btn-outline-primary" for="addScope-s">Site-specific</label>
                </div>

                <div class="mt-3" v-if="emailUser">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Email</label>
                  {{ emailUser }}
                </div>
               <div class="mt-3" v-else>
                  <label class="form-label me-3" style="width:70px;text-align:right;">Customer</label>
                  {{ customerDisplayName }}
                </div>

                <div class="mt-3 mb-3" v-if="scope ==  'email'">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Customer</label>
                  <select
                    v-model="customerAdd"
                    class="form-select"
                    style="width:240px;max-height:28px !important; padding-top:2px;display:inline;"
                    @change="customerChanged()"
                  >
                    <option value="">-- Select Customer --</option>
                    <option v-for="customer in activeCustomers" :key="customer.id" :value="customer.id">{{ customer.displayName }}</option>
                  </select>
                </div>

                <div v-if="addScope === 's' && (customer || customerAdd)">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Site</label>
                  <select
                    v-model="siteAdd"
                    class="form-select mb-2"
                    style="width:240px;max-height:28px !important; padding-top:2px;display:inline;"
                  >
                    <option value="">-- Select Site --</option>
                    <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.displayName }}</option>
                  </select>
                </div>

                <div class="mt-3" v-if="scope !== 'email' && addScope === 's'">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Email</label>
                  <input type="text" class="form-input" style="width:240px;" v-model="addUserEmail" />
                </div>

                <div v-if="addScope === 'c' && (customer || customerAdd)">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Email</label>
                  <input type="text" class="form-input" style="width:75px;" v-model="addUserEmailHandle" />
                  @
                  <select
                    v-model="addUserEmailDomain"
                    class="form-select mb-2"
                    style="width:140px;max-height:28px !important; padding-top:2px;display:inline;"
                  >
                    <option value="">-- Domain --</option>
                    <option v-for="domain in domains" :key="domain" :value="domain">{{ domain }}</option>
                  </select>
                </div>

                <div class="mt-3">
                  <label class="form-label me-3" style="width:70px;text-align:right;">Role</label>
                  <select
                    v-model="roleAdd"
                    class="form-select"
                    style="height:28px !important;padding-top:2px;width:240px;display:inline;"
                  >
                    <option value="">-- Select Role --</option>
                    <RoleOptions />
                  </select>
                </div>
                <hr />
                <div style="text-align:right" class="mt-3">
                  <button class="btn" @click="addCustomerAccess()" :disabled="!canAddUser">Add Customer Access</button>
                </div>
              </div>
            </template>
          </InfoPane>

          <InfoPane v-if="emailUser" title="User API Key Access" class="mt-4">
            <template v-slot:info>
              <div class="m-3">

                <div class="warning width-330 wrap mb-4" v-if="revokingApiKeyAccess">
                  Revoking API Key Access will immediately remove the User's API Key from the system.
                  If the Key is still being used, revoking it will disrupt their integrations with Agrology's API.
                </div>

                <BasicButton caption="Confirm Revocation" v-if="userHasApiKeyAccess && revokingApiKeyAccess" :disabled="isLoading" color="red" class="me-2" @click="revokeUserApiKeyAccess" />
                <BasicButton caption="Cancel Revocation" v-if="userHasApiKeyAccess && revokingApiKeyAccess" :disabled="isLoading" color="blue" @click="revokingApiKeyAccess = false" />

                <BasicButton caption="Grant API Key Access" v-if="!userHasApiKeyAccess" :disabled="isLoading" @click="grantUserApiKeyAccess" />
                <BasicButton caption="Revoke API Key Access" v-else-if="!revokingApiKeyAccess" :disabled="isLoading" @click="revokingApiKeyAccess = true" />

              </div>
            </template>
          </InfoPane>

        </div>
    </div>

  </div>
</template>
<script>
import DataTable from '@/components/tables/DataTable.vue'
import RoleOptions from '@/components/selectOptions/RoleOptions'
import InfoPane from '@/components/tables/InfoPane.vue'
import BasicButton from '@/components/controls/BasicButton.vue'

import ModalConfirmDeleteUser from '@/components/admin/ModalConfirmDeleteUser.vue'

import FleetDataService from '@/services/fleet/FleetDataService'
import AdminDataService from '@/services/admin/AdminDataService'
import HelpContent from '@/services/HelpContent'

export default {
  components: { DataTable, InfoPane, RoleOptions, ModalConfirmDeleteUser, BasicButton },
  data () {
    return {
      scope: 'customer',
      customer: '',
      customerAdd: '',
      siteAdd: '',
      roleAdd: '',
      emailSearch: '',
      emailUser: '',
      addUserEmail: '',
      addUserEmailHandle: '',
      addUserEmailDomain: '',
      addScope: 'c',
      customers: [],
      customerWideACL: [],
      siteSpecificACL: [],
      eCustomerWideACL: [],
      eSiteSpecificACL: [],
      sites: [],
      isLoadingCustomer: false,
      isLoadingCustomerList: false,
      isLoadingSite: false,
      isLoadingUserApikey: false,
      userHasApiKeyAccess: false,
      revokingApiKeyAccess: false
    }
  },
  mounted () {
    this.getCustomers()
    if (this.$route.params.email) {
      this.scope = 'email'
      this.addScope = 's'
      this.emailSearch = this.$route.params.email
      this.emailUser = this.emailSearch
      this.getUserAccess()
    } else if (this.$route.params.customer) {
      this.scope = 'customer'
      this.customer = this.$route.params.customer
      this.getCustomerAccess()
    }
  },
  computed: {
    isLoading () {
      return this.isLoadingCustomer || this.isLoadingSite||
        this.isLoadingCustomerList || this.isLoadingUserApikey
    },
    canAddUser () {
      let c = this.customer
      if (!this.customer) {
        c = this.customerAdd
      }
      return c && this.roleAdd && this.constructedEmail
    },
    constructedEmail () {
      if (this.addScope === 's') {
        if (this.emailUser) {
          return this.emailUser
        }
        return this.addUserEmail
      }
      if (!this.addUserEmailHandle || !this.addUserEmailDomain) {
        return ''
      }
      return `${this.addUserEmailHandle}@${this.addUserEmailDomain}`
    },
    domains () {
      if (!this.customer && !this.customerAdd) {
        return []
      }
      let c = this.customer
      if (!this.customer) {
        c = this.customerAdd
      }
      for (const customer of this.customers) {
        if (customer.id === c) {
          var d = []
          if (customer.domains) {
            d = customer.domains.split(',')
          }
          d.push('agrology.ag')
          return d
        }
      }
      return []
    },
    roleOptions () {
      return [
        { text: 'viewer', value: 'viewer' },
        { text: 'editor', value: 'editor' },
        { text: 'admin', value: 'admin' }
      ]
    },
    customerDisplayName () {
      if (!this.customer) {
        return ''
      }
      for (const c of this.activeCustomers) {
        if (c.id === this.customer && c.displayName) {
          return c.displayName
        }
      }
      return this.customer
    },
    activeCustomers () {
      const active = []
      for (const customer of this.customers) {
        if (customer.status === 'inactive') {
          continue
        }
        active.push(customer)
      }
      return active
    },
    cwCols () {
      return [
        { text: 'User', sort: 0 },
        { text: 'Role', style: 'width: 95px !important;', sort: 1 },
        { text: '', style: 'width:40px !important;' }
      ]
    },
    spCols () {
      return [
        { text: 'User', sort: 0 },
        { text: 'Site', sort: 1 },
        { text: 'Role', sort: 2, style: 'width: 95px !important;' },
        { text: '', style: 'width:40px !important;' }
      ]
    },
    cwRows () {
      const out = []
      if (this.customerWideACL && this.customerWideACL.length > 0) {
        for (const entry of this.customerWideACL) {
          out.push(
            [
              { value: entry.email, route: 'admin-app-user-access-email', params: { email: entry.email } },
              { value: entry.role, selectOptions: this.roleOptions, click: this.updateCustomerRole, i: entry },
              { icon: 'trash-can', click: this.removeCustomerAccess, i: entry, class: 'dt-centered' }
            ]
          )
        }
      }
      return out
    },
    spRows () {
      const out = []
      if (this.siteSpecificACL && this.siteSpecificACL.length > 0) {
        for (const entry of this.siteSpecificACL) {
          out.push(
            [
              { value: entry.email, route: 'admin-app-user-access-email', params: { email: entry.email } },
              { value: this.siteDisplayName(entry.site) },
              { value: entry.role, selectOptions: this.roleOptions, click: this.updateSiteRole, i: entry },
              { icon: 'trash-can', click: this.removeSiteAccess, i: entry, class: 'dt-centered' }
            ]
          )
        }
      }
      return out
    },
    ecwCols () {
      return [
        { text: 'Customer', sort: 0 },
        { text: 'Role', sort: 1, style: 'width: 95px !important;' },
        { text: '', style: 'width:40px !important;' }
      ]
    },
    espCols () {
      return [
        { text: 'Customer', sort: 0 },
        { text: 'Site', sort: 1 },
        { text: 'Role', sort: 2, style: 'width: 95px !important;' },
        { text: '', style: 'width:40px !important;' }
      ]
    },
    ecwRows () {
      const out = []
      if (this.eCustomerWideACL && this.eCustomerWideACL.length > 0) {
        for (const entry of this.eCustomerWideACL) {
          out.push(
            [
              { value: this.custDisplayName(entry.customer), route: 'admin-app-user-access-customer', params: { customer: entry.customer } },
              { value: entry.role, selectOptions: this.roleOptions, click: this.updateCustomerRole, i: entry },
              { icon: 'trash-can', click: this.removeCustomerAccess, i: entry, class: 'dt-centered' }
            ]
          )
        }
      }
      return out
    },
    espRows () {
      const out = []
      if (this.eSiteSpecificACL && this.eSiteSpecificACL.length > 0) {
        for (const entry of this.eSiteSpecificACL) {
          out.push(
            [
              { value: this.custDisplayName(entry.customer), route: 'admin-app-user-access-customer', params: { customer: entry.customer } },
              { value: this.siteDisplayName(entry.site) },
              { value: entry.role, selectOptions: this.roleOptions, click: this.updateSiteRole, i: entry },
              { icon: 'trash-can', click: this.removeSiteAccess, i: entry, class: 'dt-centered' }
            ]
          )
        }
      }
      return out
    }
  },
  methods: {
    showDeleteUserModal () {
      this.$refs.confirmDeleteUserModal.show()
    },
    custDisplayName (input) {
      if (!this.activeCustomers || !input) {
        return input
      }
      for (const c of this.activeCustomers) {
        if (c.id === input) {
          return c.displayName
        }
      }
      return input
    },
    siteDisplayName (input) {
      return FleetDataService.getSiteDisplayName(input)
    },
    updateCustomerRole (entry, role) {
      if (entry && entry.email && entry.customer && role) {
        this.isLoadingCustomer = true
        AdminDataService.upsertUserCustomer(entry.customer, { email: entry.email, role: role })
          .then(() => {
            HelpContent.setTimedFlashMessage(`Customer Access Updated: ${entry.email} / ${entry.customer} / ${role}`)
            if (this.scope === 'customer') {
              this.getCustomerWideAccess()
            } else {
              this.getUserCustomerWideAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to Update Customer Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingCustomer = false
          })
      }
    },
    updateSiteRole (entry, role) {
      if (entry && entry.email && entry.site && role) {
        this.isLoadingSite = true
        AdminDataService.upsertUserSite(entry.site, { email: entry.email, role: role })
          .then(() => {
            HelpContent.setTimedFlashMessage(`Site Access Updated: ${entry.email} / ${this.siteDisplayName(entry.site)} / ${role}`)
            if (this.scope === 'customer') {
              this.getSiteSpecificAccess()
            } else {
              this.getUserSiteSpecificAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to Update Site Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingSite = false
          })
      }
    },
    removeCustomerAccess (entry) {
      if (entry && entry.email && entry.customer) {
        this.isLoadingCustomer = true
        AdminDataService.deleteCustomerFromUser(entry.email, entry.customer)
          .then(() => {
            HelpContent.setTimedFlashMessage(`Customer Access Removed: ${entry.email} / ${entry.customer}`)
            if (this.scope === 'customer') {
              this.getCustomerWideAccess()
            } else {
              this.getUserCustomerWideAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to Remove Customer Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingCustomer = false
          })
      }
    },
    removeSiteAccess (entry) {
      if (entry && entry.email && entry.site) {
        this.isLoadingSite = true
        AdminDataService.deleteSiteFromUser(entry.email, entry.site)
          .then(() => {
            HelpContent.setTimedFlashMessage(`Site Access Removed: ${entry.email} / ${this.siteDisplayName(entry.site)}`)
            if (this.scope === 'customer') {
              this.getSiteSpecificAccess()
            } else {
              this.getUserSiteSpecificAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to Remove Site Access'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingSite = false
          })
      }
    },
    getCustomerAccess () {
      this.getCustomerWideAccess()
      this.getSiteSpecificAccess()
    },
    getCustomerWideAccess () {
      this.isLoadingCustomer = true
      AdminDataService.getCustomerUsers(this.customer)
        .then(response => {
          this.customerWideACL = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Customer Access List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingCustomer = false
        })
    },
    getSiteSpecificAccess () {
      this.isLoadingSite = true
      AdminDataService.getCustomerSiteSpecificUsers(this.customer)
        .then(response => {
          this.siteSpecificACL = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Site Access List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingSite = false
        })
    },
    keyPressed (e) {
      if (e.keyCode === 13) {
        this.loadUserAccess()
      }
    },
    loadCustomerAccess () {
      this.$router.push({ name: 'admin-app-user-access-customer', params: { customer: this.customer } })
    },
    loadUserAccess () {
      this.emailSearch = (this.emailSearch || '').trim()
      this.emailUser = this.emailSearch
      this.$router.push({ name: 'admin-app-user-access-email', params: { email: this.emailUser } })
    },
    getUserAccess () {
      this.emailSearch = (this.emailSearch || '').trim().toLowerCase()
      this.emailUser = this.emailSearch
      this.getUserCustomerWideAccess()
      this.getUserSiteSpecificAccess()
      this.getUserApiKeyAccess()
    },
    getUserApiKeyAccess () {
      this.userHasApiKeyAccess = false
      if (!this.emailUser) {
        return
      }
      this.isLoadingUserApikey = true
      AdminDataService.getUserApiKeyAccess(this.emailUser)
      .then(response => {
        this.userHasApiKeyAccess = response.data && response.data.email &&
          response.data.email === this.emailUser
      })
      .catch(e => {
        let message = 'Failed to retrieve User API Key Access'
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoadingUserApikey = false
      })
    },
    revokeUserApiKeyAccess () {
      if (!this.emailUser) {
        return
      }
      this.isLoadingUserApikey = true
      AdminDataService.revokeUserApiKeyAccess(this.emailUser)
      .then(() => {
        this.userHasApiKeyAccess = false
        this.revokingApiKeyAccess = false
        HelpContent.setTimedFlashMessage('API Key Access revoked for ' + this.emailUser)
      })
      .catch(e => {
        let message = 'Failed to revoke User API Key Access'
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoadingUserApikey = false
      })
    },
    grantUserApiKeyAccess () {
      this.userHasApiKeyAccess = false
      if (!this.emailUser) {
        return
      }
      this.isLoadingUserApikey = true
      AdminDataService.grantUserApiKeyAccess(this.emailUser)
      .then(() => {
        this.userHasApiKeyAccess = true
        HelpContent.setTimedFlashMessage('API Key Access granted for ' + this.emailUser)
      })
      .catch(e => {
        let message = 'Failed to grant User API Key Access'
        if (e.response && e.response.data && e.response.data.message) {
          message += `: ${e.response.data.message}`
        }
        HelpContent.setFlashMessage(message, true)
      })
      .finally(() => {
        this.isLoadingUserApikey = false
      })
    },
    getUserCustomerWideAccess () {
      this.isLoadingCustomer = true
      AdminDataService.getUserCustomers(this.emailUser)
        .then(response => {
          this.eCustomerWideACL = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Customer Access List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingCustomer = false
        })
    },
    getUserSiteSpecificAccess () {
      this.isLoadingSite = true
      AdminDataService.getUserSites(this.emailUser)
        .then(response => {
          this.eSiteSpecificACL = response.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Site Access List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingSite = false
        })
    },
    getCustomers () {
      this.isLoadingCustomerList = true
      FleetDataService.listCustomers()
        .then(response => {
          this.customers = response.data
          this.customerChanged()
        })
        .catch(e => {
          let message = 'Failed to load Customer list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingCustomerList = false
        })
    },
    customerChanged () {
      this.getSites()
    },
    getSites () {
      if (!this.customer && !this.customerAdd) {
        return
      }
      let c = this.customer
      if (!this.customer) {
        c = this.customerAdd
      }
      this.isLoadingCustomerList = true
      FleetDataService.getSitesByCustomer(c)
        .then(response => {
          this.sites = response.data
        })
        .catch(e => {
          let message = 'Failed to load Sites list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoadingCustomerList = false
        })
    },
    addCustomerAccess () {
      if (!this.customer && !this.customerAdd) {
        return
      }
      let c = this.customer
      if (!this.customer) {
        c = this.customerAdd
      }
      if (!c || !this.roleAdd || !this.constructedEmail) {
        return
      }

      if (this.addScope === 's' && this.siteAdd) {
        this.isLoadingCustomerList = true
        AdminDataService.upsertUserSite(this.siteAdd, { email: this.constructedEmail, role: this.roleAdd })
          .then(() => {
            HelpContent.setTimedFlashMessage('User Access Added')
            if (this.scope === 'customer') {
              this.getSiteSpecificAccess()
              this.getCustomerWideAccess()
            } else {
              this.getUserSiteSpecificAccess()
              this.getUserCustomerWideAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to add user access1'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingCustomerList = false
          })
      } else {
        this.isLoadingCustomer = true
        AdminDataService.upsertUserCustomer(c, { email: this.constructedEmail, role: this.roleAdd })
          .then(() => {
            HelpContent.setTimedFlashMessage('User Access Added')
            if (this.scope === 'customer') {
              this.getSiteSpecificAccess()
              this.getCustomerAccess()
            } else {
              this.getUserSiteSpecificAccess()
              this.getUserCustomerAccess()
            }
          })
          .catch(e => {
            let message = 'Failed to add user access2'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoadingCustomer = false
          })
      }
    }
  }
}
</script>
