<template>
  <div>
    <Modal ref="createModal" />
    <DataTable
      class="dt-modeling-jobs"
      title="Modeling Jobs"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :actionFunc="showCreateJob"
      actionIcon="plus"
      itemType="Job"
      noDataMessage="No Jobs have been created yet"
      :initialSort="0"
    >
    <template v-slot:filter-pillbox>
    </template>
    </DataTable>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import ModelingDataService from '@/services/ModelingDataService'
import Reloadables from '@/services/reloadables'
import Modal from '@/components/modeling/ModalAddUpdateJob.vue'
import HelpContent from '@/services/HelpContent'

export default {
  components: { DataTable, Modal },
  extends: BaseComponent,
  data () {
    return {
      isLoading: false,
      jobsData: [],
      cols: [
        { text: 'Name', sort: 0 },
        { text: 'Script', class: 'd-none d-sm-table-cell', sort: 1 },
        { text: 'Status', sort: 2 },
        { icon: 'clock', sort: 3 },
        { text: 'Last State', sort: 4 }
      ]
    }
  },
  name: 'modeling-jobs-list',
  computed: {
    rows () {
      const out = []
      if (this.jobsData && this.jobsData.length > 0) {
        for (const i in this.jobsData) {
          out.push([
            { value: this.jobsData[i].name, route: 'modeling-jobinfo', params: { id: this.jobsData[i].name } },
            { value: this.jobsData[i].scriptName, class: 'd-none d-sm-table-cell' },
            { value: this.jobsData[i].status, class: 'inactive' },
            { value: this.getSinceSecs(this.jobsData[i].lastRunTS), sortValue: (Date.now() / 1000) - this.jobsData[i].lastRunTS },
            {
              value: this.jobsData[i].lastRunStatus,
              class: {
                notable: this.jobsData[i].lastRunStatus === 'running' || this.jobsData[i].lastRunStatus === 'starting' || this.jobsData[i].lastRunStatus === 'scheduled',
                subtle: this.jobsData[i].lastRunStatus === 'completed' || this.jobsData[i].lastRunStatus === 'canceled',
                'status-danger': this.jobsData[i].lastRunStatus === 'failed'
              }
            }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showCreateJob () {
      this.$refs.createModal.show()
    },
    loadJobs () {
      this.isLoading = true
      ModelingDataService.listAllJobs()
        .then(result => {
          this.jobsData = result.data
        })
        .catch(e => {
          let message = 'Failed to list Jobs'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('jobs-list', this.loadJobs)
    this.loadJobs()
  },
  unmounted () {
    Reloadables.unregisterReloadable('jobs-list')
  }
}
</script>
