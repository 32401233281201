<template>
  <div class="row form-group">
    <label :class="labelClasses" :for="id">{{ label }}</label>
    <div class="col-auto form-right-input">
      <input
        type="number"
        :id="id"
        :value="modelValue"
        :required="required"
        :name="id"
        :style="inputStyle"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled"
        :class="inputClasses"
        @keypress="checkInput"
        @input="input"
        pattern="[0-9.]*"
      />
      <span class="ms-2 subtle" v-if="suffix">{{ suffix }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'inputStyle', 'inputClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled', 'dataType', 'suffix'],
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.value
    }
  },
  methods: {
    checkInput (e) {
      if (!e.key) {
        return
      }
      if (this.dataTypeCharacters) {
        let v = e.key
        if (this.dataType === 'slug' || this.dataType === 'email') {
          v = v.toLowerCase()
        } else if (this.dataType === 'hex') {
          v = v.toUpperCase()
        }
        if (!this.dataTypeCharacters.includes(v)) {
          e.preventDefault()
        }
      }
    },
    input (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
  computed: {
    dataTypeCharacters () {
      if (!this.dataType) {
        return ''
      }
      if (this.dataType === 'slug') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-'
      }
      if (this.dataType === 'description') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 -\'.()/@#$%&*_+'
      }
      if (this.dataType === 'word') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-\'.'
      }
      if (this.dataType === 'words') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-\'. '
      }
      if (this.dataType === 'version') {
        return 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789._-'
      }
      if (this.dataType === 'phone') {
        return '0123456789-'
      }
      if (this.dataType === 'integer') {
        return '0123456789'
      }
      if (this.dataType === 'decimal') {
        return '0123456789.'
      }
      if (this.dataType === 'domain') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-.'
      }
      if (this.dataType === 'hexupper') {
        return '0123456789ABCDEF'
      }
      if (this.dataType === 'hexlower') {
        return '0123456789abcdef'
      }
      if (this.dataType === 'hex') {
        return '0123456789abcdefABCDEF'
      }
      if (this.dataType === 'email') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-._@'
      }
      if (this.dataType === 'semver') {
        return '0123456789.'
      }
      return ''
    },
    inputClasses () {
      let out = 'form-input text-input'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
