<template>
  <button :class="buttonClass" @click="buttonClicked" :disabled="disabled">
  <fa :icon="icon" class="button-icon-wrapped" style="margin-top:-2px;margin-right: 3px;" v-if="icon" />
  <span>
    {{ caption }}
  </span>
  </button>
</template>
<script>
export default {
  name: 'basic-button',
  props: ['caption', 'action', 'disabled', 'icon', 'color'],
  computed: {
    buttonClass () {
      const base = "btn"
      if (this.color) {
        return `${base} ${base}-${this.color}`
      }
      return base
    }
  },
  methods: {
    buttonClicked () {
      if (this.action) {
        this.action()
      }
    }
  }
}
</script>
