<template>
  <div class="mt-3 pt-2">
    <ModalEditSiteLocation ref="editSiteLocation" :site="site" @reloadSite="reloadSite" />
    <InfoPane
      class="site-location-pane"
      title="Geography &amp; Location"
      v-if="site"
      :actionFunc="showEditLocation"
      actionIcon="pen-to-square"
      itemType="Geography"
    >
      <template v-slot:info>
        <ul v-if="site" class="info-pane-list">
          <li class="info-pane-list-item">
            <label class="list-heading-label me-2">Time Zone:</label>
            <span v-if="site.tz">
              {{ formatTz(site.tz) }}
            </span>
            <span v-else class="label-need-info">
              <fa icon="exclamation" class="map-icon status-danger" />
              Time Zone Not Set
            </span>
          </li>
          <li class="info-pane-list-item" v-if="site.accessInfo">
            <div class="d-flex flex-wrap">
              <label class="list-heading-label me-2">
                <fa icon="key" class="icon-regular gold" />
                Access Info:
              </label>
              <div v-for="line in accessInfoLines" :key="line">
                {{ line }}
              </div>
            </div>
          </li>
          <li class="info-pane-list-item">
            <label class="list-heading-label me-2">Site GPS:</label>
            <span v-if="coords" class="font-reduce-1">
              {{ coords }}
            </span>
            <span v-else class="label-need-info">
              <fa icon="triangle-exclamation" class="map-icon status-danger me-1" style="margin-top:-2px;" />
              GPS Information Needed
            </span>
          </li>
          <li class="info-pane-list-item" v-if="site.sizeAcres">
            <label class="list-heading-label me-2">Acreage:</label>
            <span v-if="coords" class="font-reduce-1">
              {{ site.sizeAcres }}
            </span>
          </li>
          <li class="info-pane-list-item" v-if="site.state">
            <label class="list-heading-label" style="width:110px;text-align:right">Site Address:</label>
            <label style="width:225px;text-align:right;" v-if="mapURL">
              <a class="padded" v-if="mapURL" v-bind:href="mapURL" target="site-loc-map">
                Open in Maps
                <fa icon="up-right-from-square" class="icon-text-link" />
              </a>
            </label>
            <div class="col address-box me-1">
              <div v-if="site.state">
                <div class="node-info-row" v-if="site.address1">{{ site.address1 }}</div>
                <div class="node-info-row" v-if="site.address2">{{ site.address2 }}</div>
                <div class="node-info-row">
                  <span class="address-city" v-if="site.city">{{ site.city }}</span>
                  <span class="address-state" v-if="site.state">{{ site.state }}</span>
                  <span class="address-zip" v-if="site.postalCode">{{ site.postalCode }}</span>
                  <span class="address-country" v-if="site.country">{{ site.country }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <SiteMap v-if="center"
          :markers="markers"
          :polygons="polygons"
        />
      </template>
    </InfoPane>
  </div>
</template>
<script>
import { NewMarkers, NewMarker, NewMapMarkerContent } from '@/components/map/MapControl.ts'
import SiteMap from '@/components/fleet/SiteMap/SiteMap.vue'
import BaseComponent from '@/components/BaseComponent'
import ModalEditSiteLocation from '@/components/fleet/ModalEditSiteLocation.vue'
import InfoPane from '@/components/tables/InfoPane.vue'

export default {
  name: 'SiteLocation',
  props: ['site', 'gateways', 'nodes', 'nodeColors', 'showNodeEditorFunc'],
  emits: ['reloadSite'],
  extends: BaseComponent,
  components: {
    InfoPane,
    SiteMap,
    ModalEditSiteLocation
  },
  computed: {
    accessInfoLines () {
      if (this.site && this.site.accessInfo) {
        return this.site.accessInfo.split('\n')
      }
      return null
    },
    coords () {
      if (this.site && this.site.lat && this.site.long) {
        const lat = this.site.lat.toString().length > 13 ? this.site.lat.toString().substr(0, 13) : this.site.lat
        const lng = this.site.long.toString().length > 15 ? this.site.long.toString().substr(0, 15) : this.site.long
        return lat + ',' + lng
      }
      return ''
    },
    mapURL () {
      if (this.site.state) {
        let addr = ''
        if (this.site.address1) {
          addr += this.site.address1
        }
        if (this.site.address2) {
          addr += ' ' + this.site.address2
        }
        if (this.site.city) {
          addr += ' ' + this.site.city
        }
        if (this.site.state) {
          addr += ' ' + this.site.state
        }
        if (this.site.postalCode) {
          addr += ' ' + this.site.postalCode
        }
        addr = addr.replaceAll('  ', ' ')
        return 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(addr)
      }
      return ''
    },
    polygons () {
      if (this.site && this.site.polygon) {
        const polygons = this.site.polygon.split('|')
        const paths = []
        for (const i in polygons) {
          const parts = polygons[i].split(';')
          const path = []
          for (const i in parts) {
            const subParts = parts[i].split(',')
            if (subParts.length === 2) {
              subParts[0] = parseFloat(subParts[0])
              subParts[1] = parseFloat(subParts[1])
              path.push(subParts)
            }
          }
          paths.push(path)
        }
        return paths
      }
      return []
    },
    addressLink () {
      var addr = ''
      var sep = ''
      var x = 0
      if (this.site) {
        if (this.site.address1 !== '') {
          x = this.site.address1.codePointAt(0)
          if (x >= 48 && x <= 57) {
            addr = this.site.address1
            sep = ' '
          }
        } else if (this.site.address2 !== '') {
          x = this.site.address2.codePointAt(0)
          if (x >= 48 && x <= 57) {
            addr = this.site.address2
            sep = ' '
          }
        }
        if (this.site.city !== '') {
          addr += sep + this.site.city
        }
        if (this.site.state !== '') {
          addr += sep + this.site.state
        }
        if (this.site.postalCode !== '') {
          addr += ',' + this.site.postalCode
        }
        if (this.site.country !== '') {
          addr += sep + this.site.country
        }
      }
      return encodeURI('https://maps.google.com/?q=' + addr)
    },
    center () {
      if (this.site && this.site.lat && this.site.long) {
        return [this.site.long, this.site.lat]
      }
      return null
    },
    markers () {
      const out = NewMarkers()
      if (this.center && this.center.length === 2) {
        out.push({ coords: [this.site.long, this.site.lat], icon: 'location-dot', color: '#722f59' })
      }
      if (this.nodes) {
        for (const k in this.nodes) {
          const n = this.nodes[k]
          if (n.lat !== undefined && n.lat !== 0 && n.long !== undefined && n.long !== 0) {
            const marker = NewMarker()
            marker.coords = [n.long, n.lat]
            marker.color = `${this.nodeColors[k] || '#cccccc'}dd`
            const content = NewMapMarkerContent()
            content.title = `Node`
            content.titleLinkText = n.displayName
            content.titleClickFunc = () => { console.log('>>>>>'), this.showNodeEditorFunc(n.id) }
            marker.content = content
            out.push(marker)
          }
        }
      }
      if (this.gateways) {
        for (const k in this.gateways) {
          const gw = this.gateways[k]
          if (gw.lat !== undefined && gw.lat !== 0 && gw.long !== undefined && gw.long !== 0) {
            const marker = NewMarker()
            marker.coords = [gw.long, gw.lat]
            marker.color = '#000000'
            marker.icon = 'tower-broadcast'
            const content = NewMapMarkerContent()
            content.title = `Gateway`
            content.titleLinkText = gw.id
            content.titleRoute = 'gateway-details'
            content.titleRouteParams = { 'id': gw.id }
            marker.content = content
            out.push(marker)
          }
        }
      }
      return out
    }
  },
  methods: {
    reloadSite () {
      this.$emit('reloadSite')
    },
    showEditLocation () {
      this.$refs.editSiteLocation.show()
    },
    commaDelimiter (i, len) {
      return i < len - 1 ? ', ' : ''
    }
  }
}

</script>
