import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center mx-0 px-0 pt-3" }
const _hoisted_2 = { class: "info-pane-list" }
const _hoisted_3 = { class: "info-pane-list-item height-50 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InfoPane = _resolveComponent("InfoPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InfoPane, {
      paneClass: "fleet-misc-info-pane",
      class: "fleet-misc-info-pane mb-4"
    }, {
      info: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("li", { class: "info-pane-list-item" }, [
            _createElementVNode("h5", { class: "mt-2 ps-2" }, "Additional Notification Items")
          ], -1)),
          _createElementVNode("li", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: {  name: 'exclusion-zones' },
              class: "info-pane-item-link hoverable"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Manage GPS Exclusion Zones ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}