<template>
  <div class="mt-3 pt-2">
    <ModalEditIrrigation ref="editIrrigation" :site="site" @reloadSite="reloadSite" />
    <InfoPane
      class="site-irrigation-pane"
      title="Irrigation Information"
      v-if="site"
      :actionFunc="showEditIrrigation"
      actionIcon="pen-to-square"
      itemType="Irrigation"
    >
      <template v-slot:info>
        <div class="p-2">
          <pre class="normal">{{ irrigationSummary }}</pre>
        </div>
      </template>
    </InfoPane>
  </div>
</template>
<script>
import BaseComponent from '@/components/BaseComponent'
import InfoPane from '@/components/tables/InfoPane.vue'
import ModalEditIrrigation from '@/components/fleet/ModalEditIrrigation.vue'

export default {
  name: 'SiteIrrigation',
  props: ['site'],
  emits: ['reloadSite'],
  extends: BaseComponent,
  components: {
    InfoPane,
    ModalEditIrrigation
  },
  computed: {
    irrigationSummary () {
      if (this.site.irrigationSummary) {
        return this.site.irrigationSummary.trim()
      }
      return 'No Irrigation data provided'
    }
  },
  methods: {
    reloadSite () {
      this.$emit('reloadSite')
    },
    showEditIrrigation () {
      this.$refs.editIrrigation.show()
    }
  }
}

</script>
