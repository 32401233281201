<template>
    <div class="d-flex">
      <input @keypress="keyPressed" ref="newTagBubbleInput" type="text" class="form-input ms-1 tag-bubble-input" :placeholder="compositePlaceholder" v-model="inputVal" />
      <div @click="okClicked" class="tag-bubble-input-action tag-bubble-input-ok font-reduce-2">
        &#x2713;
      </div>
      <div @click="cancelClicked" class="tag-bubble-input-action tag-bubble-input-cancel font-reduce-3">
        &#x2715;
      </div>
    </div>
</template>
<script>
export default {
  name: 'tag-bubble-input',
  props: ['placeholder', 'closer', 'adder', 'groupName'],
  data () {
    return {
      inputVal: ''
    }
  },
  computed: {
    compositePlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return 'New Item'
    }
  },
  methods: {
    keyPressed (e) {
      if (e.keyCode === 13) {
        this.okClicked()
      }
    },
    okClicked () {
      if (this.adder) {
        this.adder(this.groupName, this.inputVal)
      }
      this.cancelClicked()
    },
    cancelClicked () {
      if (this.closer) {
        this.closer()
      }
    },
    focus () {
      this.$nextTick(() => {
        this.$refs.newTagBubbleInput.focus()
        this.$refs.newTagBubbleInput.select()
      })
    }
  },
  mounted () {
    this.focus()
  }
}
</script>
