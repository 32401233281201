<template>
  <TabBar activeTabName="customers" />
  <div class="row justify-content-center px-0 mx-0">
    <CustomerInfo />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CustomerInfo from '@/components/fleet/CustomerInfo.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    CustomerInfo,
    TabBar
  }
})
export default class CustomerDetails extends Vue {}
</script>
