<template>
  <div v-if="majorError">
    <h5 class="app-failure">{{ majorError }}</h5>
  </div>
  <InfoPane
    class="dt-info-pane-sm"
    title="Manufacturer Info"
    v-else-if="manufacturer"
    :actionFunc="toggleEditMode"
    actionIcon="pen-to-square"
    itemType="Manufacturer"
  >
    <template v-slot:info>
      <ul v-if="!inEditMode && manufacturer" class="info-pane-list">

        <li class="info-pane-list-item d-flex align-items-center" v-if="manufacturer.status === 'inactive'">
          <div class="col-auto node-inactive-header">
            INACTIVE
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center">
          <div class="col">
            <span class="list-heading-label">
              ID:
            </span>
            {{ manufacturer.id }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="manufacturer.description">
          <div class="col">
            <span class="list-heading-label">
              Display Name:
            </span>
             {{ manufacturer.description }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="manufacturer.notes">
          <div class="col node-notes node-general-notes">
            {{ manufacturer.notes }}
          </div>
        </li>

        <li class="info-pane-list-item d-flex align-items-center" v-if="manufacturer.models">
          <div class="col node-general-header">
            Models:
          </div>
          <div class="col-auto">
            {{ manufacturer.models }}
          </div>
        </li>

        <li class="info-pane-list-item" v-if="manufacturer.lastModified">
          <span class="subtle-info status-last-modified">
            {{ getSinceSecs(manufacturer.lastModified) }}
          </span>
          <span class="subtle-info font-reduce-2" v-if="manufacturer.lastModifiedBy"> by {{ manufacturer.lastModifiedBy }}</span>
        </li>

      </ul>

    <!-- END DISPLAY MODE, START EDIT FORM BELOW -->
      <form @submit.prevent="handleSubmit" class="node-form no-border" autocomplete="off" v-else>
        <div class="row form-group">
          <label class="col-form-label form-left-header" for="status">Status</label>
          <div class="col-auto form-right-input">
            <select class="form-select" v-model="manufacturer.status" style="width: 100px" required>
              <GeneralStatusOptions />
            </select>
          </div>
        </div>

        <div class="row form-group" style="display:none;">
          <label class="col-form-label form-left-header" for="cascade"></label>
          <div class="col-auto form-right-input">
            <input type="checkbox" class="form-check-input" id="cascade"/>
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="description">Description</label>
          <div class="col-auto form-right-input">
            <input
              type="text"
              id="description"
              v-model="manufacturer.description"
              name="description"
              style="width:240px;"
              placeholder="Proper Name, Website, etc."
              maxlength="48"
            />
          </div>
        </div>

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="notes">Notes</label>
          <div class="col-auto form-right-input">
            <textarea
              id="notes"
              v-model="manufacturer.notes"
              name="notes"
              class="notes-box"
              placeholder="Enter any info about this Manufacturer that Agrology may find useful in the future."
            />
          </div>
        </div>

        <hr />

        <div class="row form-group">
          <label class="col-form-label form-left-header" for="models">Models</label>
          <div class="col-auto form-right-input">
            <textarea
              id="models"
              v-model="manufacturer.models"
              name="models"
              style="width:240px;height:80px"
              placeholder="comma,separated,model,list"
              maxlength="64"
            />
          </div>
        </div>

        <div class="row-auto mx-0 px-0 form-button-row">
          <div class="col-auto buttons-right">
            <button class="btn" :disabled="isLoading">Update</button>
            <button class="btn btn-blue" @click.prevent="handleCancel()">Cancel</button>
          </div>
        </div>

      </form>

    </template>
  </InfoPane>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import GeneralStatusOptions from '@/components/selectOptions/GeneralStatusOptions.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import HelpContent from '@/services/HelpContent'

export default {
  name: 'manufacturer-info',
  extends: BaseFleetComponent,
  data () {
    return {
      manufacturerBeforeEdit: {}
    }
  },
  methods: {
    toggleEditMode () {
      this.inEditMode = !this.inEditMode
    },
    getManufacturer (id) {
      FleetDataService.getManufacturer(id)
        .then(response => {
          this.manufacturer = response.data
          this.manufacturerBeforeEdit = Object.assign({}, this.manufacturer)
          this.$state.creationName = this.manufacturer.id
        })
        .catch(e => {
          this.majorError = ''
          this.handleError(e, 'Manufacturer')
        })
    },
    handleSubmit () {
      this.isLoading = true
      FleetDataService.updateManufacturer(this.manufacturer.id, this.manufacturer)
        .then(() => {
          HelpContent.setTimedFlashMessage('Manufacturer Updated')
          this.setNavManufacturer(this.$route.params.id)
          this.getManufacturer(this.manufacturer.id)
          this.inEditMode = false
        })
        .catch(e => {
          let message = 'Manufacturer Update Failed'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleCancel () {
      Object.assign(this.manufacturer, this.manufacturerBeforeEdit)
      this.inEditMode = false
    }
  },
  mounted () {
    this.getManufacturer(this.$route.params.id)
    this.setNavManufacturer(this.$route.params.id)
  },
  components: { InfoPane, GeneralStatusOptions }
}
</script>

<style>
  .btn-blue {
    margin-left: 20px;
  }
</style>
