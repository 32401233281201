<template>
  <div class="p-3 pe-2">
    <p>The Uplink Relocator Tool will move device data from one Site, Node
      and/or Position to another. While extremely useful, this tool can be
      <b>extremely</b> dangerous if an input error is made.</p>
    <p>Requests usually process in under a minute, but may take longer for
      larger time ranges.</p>
    <p>The <b>Recent Requests</b> Table automatically reloads if a Relocation
    Request is in-progress, so you can track its progress in real-time.</p>
    <p>The <b>New Request</b> Tab provides a form that can be submitted to
    request a new Uplink Relocation.</p>
    <p>If a device's Site is already correct, but the Node or Position are not,
      you can check the "Quick Update" box to update the live data in-place
      without ingesting each Uplink again.</p>
  </div>
</template>
<script>
export default {
  name: 'relocator-about-section'
}
</script>
