<template>
  <span class="spinner me-3" v-if="showReloader">
    <a @click="reload()">
      <fa icon="rotate" class="button-icon refresh-button" v-bind:class="{ 'refreshing': isLoading, 'refresh-error': isError, 'refresh-auto': interval > 0 }" />
    </a>
  </span>
</template>

<script>
import Reloadables from '@/services/reloadables'

export default {
  data () {
    return {
      isLoading: this.spinOnCreation,
      isError: false,
      startTime: 0,
      lastReload: 0,
      zt: 0,
      cancelMonitor: false,
      interval: 0
    }
  },
  props: ['spinOnCreation'],
  computed: {
    showReloader () {
      return Reloadables.state.showReloader
    }
  },
  methods: {
    reload () {
      this.lastReload = Date.now()
      if (!Reloadables.manifest || Object.keys(Reloadables.manifest).length === 0) {
        return
      }
      this.startSpinning()
      for (const k in Reloadables.manifest) {
        Reloadables.manifest[k]()
      }
      this.stopSpinning()
    },
    monitorReload () {
      let interval = 0

      if (localStorage.getItem('prefs.refreshInterval')) {
        interval = localStorage.getItem('prefs.refreshInterval')
        if (!interval) {
          interval = 0
        }
      }
      this.interval = interval
      if (interval > 0) {
        if (this.lastReload === 0 && this.zt === 0) {
          this.zt = Date.now()
        } else if ((this.lastReload === 0 && (Date.now() - this.zt) >= interval) ||
          (this.lastReload > 0 && Date.now() - this.lastReload >= interval)) {
          this.reload()
        }
        if (this.cancelMonitor) {
          return
        }
      }
      setTimeout(this.monitorReload, 1000)
    },
    stopSpinningASAP () {
      this.isLoading = false
    },
    startSpinning () {
      this.isLoading = true
      this.isError = false
      this.startTime = Date.now()
    },
    stopSpinning () {
      var timeout = 500 - ((Date.now() - this.startTime) % 500)
      if (timeout > 12) {
        timeout -= 12
      }
      setTimeout(this.stopSpinningASAP, timeout)
    },
    setError () {
      this.isError = true
    }
  },
  mounted () {
    Reloadables.registerReloader(this.reload)
    this.monitorReload()
  },
  unmounted () {
    Reloadables.unregisterReloader()
    this.cancelMonitor = true
  }
}
</script>
