<template>
  <TabBar activeTabName="networks" />
  <div class="row justify-content-center px-0 mx-0">
    <NetworkServerAddForm />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NetworkServerAddForm from '@/components/fleet/NetworkServerAddForm.vue'
import TabBar from '@/components/fleet/TabBar.vue'

@Options({
  components: {
    NetworkServerAddForm,
    TabBar
  }
})
export default class AddNetworkServer extends Vue {}
</script>
